import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function ForeignWorkersContractRegistration() {
  const intl = useIntl();

  const foreignWorkersContractRegistrationData = [
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_2
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Instructions_Description_3_BulletPoint_3
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract_Description_2
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration_Contract_Description_3
        ),
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(
        titles.Title_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration
      )}
      data={foreignWorkersContractRegistrationData}
    />
  );
}

export default ForeignWorkersContractRegistration;
