import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Slider from "@material-ui/core/Slider";
import Alert from "@material-ui/lab/Alert";
// @material-ui/icons components
import { getSelectOptionsElementByCode } from "utils/formSelect";
//core components
import CardSubprogramApplicationTable from "../Cards/CardSubprogramApplicationTable";
import componentStyles from "assets/theme/views/admin/applications.js";
//custom components
import DropzoneField from "components/FormFields/DropzoneField";
import FormSelect from "components/FormFields/FormSelect";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import { getApplicationDocumentAction } from "redux/actions/applicationsAction";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import GenericButton from "components/Buttons/GenericButton";
import { getDatasetStateByName } from "../../../../../utils/datasets";
import {
  getDataSetByNameAction,
  getShapeSubsitesAction,
  getShapeProgramsAction,
  getSubprogramByProgramIdAction,
} from "redux/actions/datasetsAction";
import { titles, labels, errorMessages, messages } from "resources/resources";

//consts
import { UNITS, DATASET, SHAPE_PROGRAMS_TARGET, BENEFIT, FORM_FIELDS_PREFIX } from "utils/const";

const useStyles = makeStyles(componentStyles);
function ValueLabelComponent(props) {
  const classesTooltip = { ...useStyles() };
  const { children, open, value } = props;

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={(value ? value : 0) + " " + UNITS.MONETARY}
      classes={{ tooltip: classesTooltip.sliderTooltip }}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

function Programs(props) {
  const classes = { ...useStyles() };
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();
  const [kitsTotalValue, setKitsTotalValue] = useState(0);
  const [bankPreferenceList, setBankPreferenceList] = useState([{ code: null, label: "Sem preferência" }]);

  useEffect(() => {
    if (
      props.applicationsState.applicationDetailsGeneralData !== undefined &&
      props.applicationsState.applicationDetailsGeneralData !== null &&
      props.applicationsState.applicationDetailsGeneralData?.applicationNumber === props.applicationNumber &&
      props.isEditPage &&
      !props.isLoaded
    ) {
      setFormValuesOnMount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applicationsState.applicationDetailsGeneralData]);

  const setFormValuesOnMount = () => {
    if (props.applicationsState?.applicationDetailsGeneralData?.programId !== undefined)
      props.getSubprogramByProgramId(
        DATASET.SUBPROGRAMS,
        props.applicationsState.applicationDetailsGeneralData.programId,
        false
      );

    var provinceList = props.datasetsState[DATASET.PROVINCES] ? props.datasetsState[DATASET.PROVINCES] : [];

    var provinceId =
      props.applicationsState?.applicationDetailsGeneralData !== undefined
        ? props.applicationsState?.applicationDetailsGeneralData.provinceId
        : null;

    props.setValue(
      props.pageState.provinceFieldName,
      getSelectOptionsElementByCode(provinceList, Number.parseInt(provinceId))
    );

    var programsList = getDatasetStateByName(props, DATASET.PROGRAMS);

    var programId =
      props.applicationsState?.applicationDetailsGeneralData !== undefined
        ? props.applicationsState?.applicationDetailsGeneralData.programId
        : null;

    props.setValue(
      props.pageState.programsFieldName,
      getSelectOptionsElementByCode(programsList, Number.parseInt(programId))
    );

    var awaitingTraining = getDatasetStateByName(props, DATASET.USER_APPLICATIONS_AWAITING_TRAINING);
    var parentApplication =
      props.applicationsState?.applicationDetailsGeneralData !== undefined
        ? props.applicationsState?.applicationDetailsGeneralData.parentApplicationId
        : null;

    props.setValue(
      props.pageState.parentApplciationFieldName,
      getSelectOptionsElementByCode(awaitingTraining, Number.parseInt(parentApplication))
    );

    var internshipAreaList = getDatasetStateByName(props, "ProfessionalCategory");

    var internshipAreaId =
      props.applicationsState?.applicationDetailsGeneralData !== undefined
        ? props.applicationsState?.applicationDetailsGeneralData.internshipAreaId
        : null;

    props.setValue(
      props.pageState.internshipFieldName,
      getSelectOptionsElementByCode(internshipAreaList, Number.parseInt(internshipAreaId))
    );

    var intendedCourseList = getDatasetStateByName(props, DATASET.SHAPE_PROGRAMS);

    var intendedCourse =
      props.applicationsState?.applicationDetailsGeneralData !== undefined
        ? props.applicationsState?.applicationDetailsGeneralData.intendedCourseCode
        : null;

    if (intendedCourse != null) {
      props.setValue(
        props.pageState.shapeProgramsFieldName,
        getSelectOptionsElementByCode(intendedCourseList, intendedCourse.toString())
      );
    }

    var trainingCenterList = getDatasetStateByName(props, DATASET.SHAPE_SUBSITES);

    var trainingCenter =
      props.applicationsState?.applicationDetailsGeneralData !== undefined
        ? props.applicationsState?.applicationDetailsGeneralData.trainingCenterId
        : null;

    if (trainingCenter != null) {
      props.setValue(
        props.pageState.shapeSubsiteFieldName,
        getSelectOptionsElementByCode(trainingCenterList, trainingCenter.toString())
      );
    }
    var generatedWorkPosts =
      props.applicationsState?.applicationDetailsGeneralData !== undefined
        ? props.applicationsState?.applicationDetailsGeneralData.generatedWorkPosts
        : null;

    props.setValue(props.pageState.generatedWorkPostsName, generatedWorkPosts);

    var professionalKitNumber =
      props.applicationsState?.applicationDetailsGeneralData !== undefined
        ? props.applicationsState?.applicationDetailsGeneralData.professionalKitsNumber
        : null;

    props.setValue(props.pageState.professionalKitBenefitTypeName, professionalKitNumber);

    var microcreditBenefit =
      props.applicationsState?.applicationDetailsGeneralData !== undefined
        ? props.applicationsState?.applicationDetailsGeneralData.microcreditAmount
        : null;

    props.setValue(props.pageState.microcreditBenefitTypeName, microcreditBenefit);

    if (props.datasetsState && props.datasetsState[DATASET.BANK] && bankPreferenceList.length > 1) {
      props.setValue(
        props.pageState.bankPreferenceFieldName,
        getSelectOptionsElementByCode(
          bankPreferenceList,
          props.applicationsState.applicationDetailsGeneralData?.bankPreferenceId
        )
      );
    }

    props.setIsLoaded(true);
  };

  function handleEdit() {
    props.setEditable(true);
  }

  useEffect(() => {
    unregisterSubprogramBenefits();

    const kits = props.getValues(props.pageState.professionalKitBenefitTypeName);
    const unitPrice = props.pageState.info?.kitUnitPrice;
    setKitsTotalValue((kits ? kits : 0) * (unitPrice ? unitPrice : 0));

    if (
      (props.pageState.info?.benefitKeyValue === BENEFIT.MICROCREDIT ||
        props.pageState.info?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
        props.pageState.info?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) &&
      !props.datasetsState[DATASET.BANK]
    )
      props.getDataSetByName(DATASET.BANK);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageState.info]);

  useEffect(() => {
    if (props.datasetsState && props.datasetsState[DATASET.BANK] && bankPreferenceList.length === 1) {
      setBankPreferenceList([...bankPreferenceList, ...props.datasetsState[DATASET.BANK]]);

      if (!props.getValues(props.pageState.bankPreferenceFieldName))
        props.setValue(
          props.pageState.bankPreferenceFieldName,
          getSelectOptionsElementByCode(
            [...bankPreferenceList, ...props.datasetsState[DATASET.BANK]],
            props.applicationsState.applicationDetailsGeneralData?.bankPreferenceId
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.BANK]]);

  const loadShapeSubsites = (dataSetName, offer) => {
    props.getShapeSubsites(offer, props.getValues(props.pageState.provinceFieldName).code, dataSetName);
  };
  const handleKitsChange = (kits) => {
    if (!kits) setKitsTotalValue(0);
    else setKitsTotalValue(kits * props.pageState.info.kitUnitPrice);
  };
  const loadShapeDataOnProvinceChange = (province) => {
    if (
      props.pageState.info?.benefitKeyValue === BENEFIT.AVANCO ||
      props.pageState.info?.benefitKeyValue === BENEFIT.CAPACITA ||
      props.pageState.info?.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET
    ) {
      let target = "";
      if (props.pageState.info.benefitKeyValue === BENEFIT.AVANCO) target = SHAPE_PROGRAMS_TARGET.AVANCO;
      if (props.pageState.info.benefitKeyValue === BENEFIT.CAPACITA) target = SHAPE_PROGRAMS_TARGET.CAPACITA;
      if (props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET)
        target = SHAPE_PROGRAMS_TARGET.PROFESSIONAL_WALLET;

      props.setValue(props.pageState.shapeProgramsFieldName, undefined);
      props.setValue(props.pageState.shapeSubsiteFieldName, undefined);
      props.getShapePrograms(target, province?.code, DATASET.SHAPE_PROGRAMS);
    }
  };
  const unregisterSubprogramBenefits = () => {
    if (!props.pageState.info) return;
    //unregisterbenefits
    if (
      props.pageState.info.benefitKeyValue !== BENEFIT.PROFESSIONAL_KIT_MICROCREDIT &&
      props.pageState.info.benefitKeyValue !== BENEFIT.MICROCREDIT &&
      props.pageState.info.benefitKeyValue !== BENEFIT.PROFESSIONAL_KIT
    ) {
      props.unregister(props.pageState.generatedWorkPostsName);
      props.unregister(props.pageState.microcreditBenefitTypeName);
      props.unregister(props.pageState.bankPreferenceFieldName);
    }
    if (
      props.pageState.info.benefitKeyValue !== BENEFIT.PROFESSIONAL_KIT &&
      props.pageState.info.benefitKeyValue !== BENEFIT.PROFESSIONAL_KIT_MICROCREDIT
    )
      props.unregister(props.pageState.professionalKitBenefitTypeName);
    if (
      !(
        props.pageState.info.benefitKeyValue === BENEFIT.AVANCO ||
        props.pageState.info.benefitKeyValue === BENEFIT.CAPACITA ||
        props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET
      )
    ) {
      props.unregister(props.pageState.shapeProgramsFieldName);
      props.unregister(props.pageState.shapeSubsiteFieldName);
    }
    if (props.pageState.info.benefitKeyValue !== BENEFIT.PROFESSIONAL_INTERNSHIP)
      props.unregister(props.pageState.internshipFieldName);
  };

  const getSubprogramDocumentsMessage = (documents) => {
    return (
      <>
        <Grid item xs={12} md={12}>
          <Typography className={classes.alertMessage}>
            Para completar a candidatura terá que submeter os seguintes documentos:
          </Typography>
          <ul className={classes.alertMessage}>
            {documents?.map((doc, key) => {
              return <li key={key}>{doc.name}</li>;
            })}
          </ul>
        </Grid>
      </>
    );
  };

  const getDocumentFieldSize = (numDocs) => {
    if (12 % numDocs === 0) return 12 / numDocs;
    return 4;
  };

  return (
    <>
      <GenericBackdrop open={props.applicationsState.applicationDetailsGeneralData_loading} />
      <Card classes={{ root: props.classes.cardRoot }}>
        <CardHeader
          classes={{
            action: props.classes.cardHeaderAction,
            title: props.classes.cardHeader,
          }}
          title={intl.formatMessage(titles.Title_Admin_Applications_MainForm_ProgramSubprograms)}
          action={
            props.isEditPage ? (
              <GenericButton 
                typeSubmit={false}
                color="tertiary"
                size="small"
                onClick={handleEdit}
              >
                Editar
              </GenericButton>
            ) : (
              ""
            )
          }
        ></CardHeader>
        <CardContent classes={{ root: props.classes.cardContent }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_Province)}</FormLabel>
                <FormSelect
                  control={props.control}
                  fieldName={props.pageState.provinceFieldName}
                  selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
                  getValues={props.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  initialLabel={intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_Province_Placeholder)}
                  errors={props.errors}
                  classes={props.classes}
                  onOptionChange={loadShapeDataOnProvinceChange}
                  disabled={
                    !props.isEditable &&
                    props.getValues(props.pageState.provinceFieldName)?.code ===
                      props.applicationsState?.applicationDetailsGeneralData?.provinceId
                  }
                />
                {props.errors[props.pageState.provinceFieldName] !== undefined && (
                  <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
                    {props.errors[props.pageState.provinceFieldName].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_ParentApplication)}
                </FormLabel>
                <FormSelect
                  control={props.control}
                  fieldName={props.pageState.parentApplciationFieldName}
                  selectOptions={getDatasetStateByName(props, DATASET.USER_APPLICATIONS_AWAITING_TRAINING)}
                  getValues={props.getValues}
                  setValue={props.setValue}
                  required={false}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  errors={props.errors}
                  classes={props.classes}
                  disabled={
                    !props.isEditable &&
                    (props.getValues(props.pageState.parentApplciationFieldName)?.code ===
                      props.applicationsState?.applicationDetailsGeneralData?.parentApplicationId ||
                      props.applicationsState?.applicationDetailsGeneralData?.parentApplicationId === null)
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>
                  {/* Qual o programa a que pretende candidatar-se? */}
                  {intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_ProgramSubprogram)}
                </FormLabel>
                <CardSubprogramApplicationTable
                  appData={props.applicationsState?.applicationDetailsGeneralData}
                  subProgramList={getDatasetStateByName(props, DATASET.SUBPROGRAMS)}
                  setValue={props.setValue}
                  isEditable={!props.isEditable}
                  isEditPage={props.isEditPage}
                  config={props}
                />
              </FormGroup>
            </Grid>
            {props.pageState.info !== undefined && (
              <>
                <Grid item xs={12} md={12} className={props.classes.subprogramDescriptionGrid}>
                  <FormGroup>
                    <Box className={props.classes.subprogramDescriptionContainerBox}>
                      <Box className={props.classes.subprogramDescriptionBox}>{props.pageState.info.description}</Box>
                    </Box>
                  </FormGroup>
                </Grid>
                {props.pageState.info?.userMustCompleteProfileData && (
                  <Grid container className={props.classes.subprogramDescriptionGrid}>
                    <Grid item xs={12} lg={12} md={12}>
                      <Alert
                        severity="warning"
                        classes={{
                          message: classes.paddingTop2px,
                        }}
                      >
                        <AlertTitle>Aviso</AlertTitle>
                        {intl.formatMessage(messages.Message_Admin_Applications_Create_Must_Complete_ProfileMessage)}
                      </Alert>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} className={classes.textAlignEnd}>
                      <GenericButton 
                        typeSubmit={false}
                        color="tertiary"
                        onClick={() => {
                          history.push("/admin/personal-profile")
                        }}
                        style={{marginTop: "20px", marginBottom: "20px"}}
                      >
                        Concluir Perfil
                      </GenericButton>
                    </Grid>
                  </Grid>
                )}
                {props.pageState.info?.vacanciesIsFull && !props.pageState.info?.userMustCompleteProfileData && (
                  <Grid item xs={12} md={12} className={props.classes.subprogramDescriptionGrid}>
                    <Alert
                      severity="info"
                      variant="filled"
                      classes={{
                        icon: classes.alertIcon,
                        filledInfo: classes.alertFilledWarning,
                      }}
                    >
                      {"Este subprograma não tem vagas disponíveis"}
                    </Alert>
                  </Grid>
                )}
                {!props.pageState.info?.vacanciesIsFull && !props.pageState.info?.userMustCompleteProfileData && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormLabel>Tipo de benefício</FormLabel>
                        <OutlinedInput
                          data-testid="application-programs-benefitName"
                          value={props.pageState.info.benefitName}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      {(props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT ||
                        props.pageState.info.benefitKeyValue === BENEFIT.MICROCREDIT ||
                        props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT) && (
                        <FormGroup>
                          <FormLabel>
                            {intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_GeneratedWorkPosts)}
                          </FormLabel>
                          <Controller
                            name={props.pageState.generatedWorkPostsName}
                            control={props.control}
                            component={Box}
                            defaultValue=""
                            rules={{
                              required: {
                                value: true,
                                message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                              },
                              validate: {
                                minValue: (value) => {
                                  return value >= 0 || intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                                },
                              },
                            }}
                            render={({ field: { onChange, name, value } }) => (
                              <OutlinedInput
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                fullWidth
                                disabled={
                                  !props.isEditable &&
                                  props.getValues(name).toString() ===
                                    props.applicationsState?.applicationDetailsGeneralData?.generatedWorkPosts?.toString()
                                }
                                autoComplete="off"
                                type="number"
                                classes={{
                                  input: classes.monetaryFields,
                                  notchedOutline: clsx({
                                    [props.classes.borderWarning]: props.errors[name] !== undefined,
                                  }),
                                }}
                              />
                            )}
                          />
                          {props.errors[props.pageState.generatedWorkPostsName] !== undefined && (
                            <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
                              {props.errors[props.pageState.generatedWorkPostsName].message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                      )}
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>
                    {(props.pageState.info.benefitKeyValue === BENEFIT.MICROCREDIT ||
                      props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
                      props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
                      <Grid item xs={12} sm={6} md={4}>
                        <FormGroup>
                          <FormLabel>
                            {intl.formatMessage(labels.Label_Admin_Applications_Programs_Field_BankPreference)}
                          </FormLabel>
                          <FormSelect
                            control={props.control}
                            fieldName={props.pageState.bankPreferenceFieldName}
                            selectOptions={bankPreferenceList}
                            getValues={props.getValues}
                            required={true}
                            requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                            initialLabel={intl.formatMessage(
                              labels.Label_Admin_Applications_Programs_Field_BankPreference_Placeholder
                            )}
                            errors={props.errors}
                            classes={props.classes}
                            disabled={
                              !props.isEditable &&
                              props.getValues(props.pageState.bankPreferenceFieldName)?.code ===
                                props.applicationsState?.applicationDetailsGeneralData?.bankPreferenceId
                            }
                          />
                          {props.errors[props.pageState.bankPreferenceFieldName] !== undefined && (
                            <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
                              {props.errors[props.pageState.bankPreferenceFieldName].message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                      </Grid>
                    )}

                    {(props.pageState.info.benefitKeyValue === BENEFIT.MICROCREDIT ||
                      props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
                      <Grid item xs={12} sm={6} md={8} style={{ paddingLeft: "30px" }}>
                        <FormGroup>
                          <FormLabel>Valor pretendido</FormLabel>
                          <Box marginTop="2rem"></Box>
                          <Controller
                            name={props.pageState.microcreditBenefitTypeName}
                            control={props.control}
                            component={Box}
                            defaultValue=""
                            rules={{
                              required: {
                                value: true,
                                message: intl.formatMessage(errorMessages.ErrorMessage_FieldValidation),
                              },
                            }}
                            render={({ field: { onChange, name, value } }) => (
                              <Slider
                                classes={{ root: classes.sliderRoot }}
                                name={name}
                                disabled={
                                  !props.isEditable &&
                                  props.getValues(name) ===
                                    props.applicationsState?.applicationDetailsGeneralData?.microcreditAmount
                                }
                                min={props.pageState.info.microcreditMinValue}
                                max={props.pageState.info.microcreditMaxValue}
                                step={1}
                                value={value ? value : props.pageState.info.microcreditMinValue}
                                ValueLabelComponent={ValueLabelComponent}
                                aria-label="custom thumb label"
                                valueLabelDisplay="on"
                                onChange={(_, value) => {
                                  onChange(value);
                                  props.setMicrocreditValueInfo(value);
                                }}
                              />
                            )}
                          />
                          <Grid container component={Box} marginTop="1rem">
                            <Grid item xs={6}>
                              <Box
                                component="span"
                                fontSize=".75rem"
                                fontWeight="500"
                                color={theme.palette.white.main}
                                borderRadius="10px"
                                padding=".4em .8em .3em .85em"
                                className={classes.sliderBadge}
                              >
                                {props.pageState.info.microcreditMinValue + " " + UNITS.MONETARY}
                              </Box>
                            </Grid>
                            <Grid item xs={6} component={Box} textAlign="right">
                              <Box
                                component="span"
                                fontSize=".75rem"
                                fontWeight="500"
                                color={theme.palette.white.main}
                                borderRadius="10px"
                                padding=".4em .8em .3em .85em"
                                className={classes.sliderBadge}
                              >
                                {props.pageState.info.microcreditMaxValue + " " + UNITS.MONETARY}
                              </Box>
                            </Grid>
                          </Grid>
                          {props.errors[props.pageState.microcreditBenefitTypeName] !== undefined && (
                            <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
                              {props.errors[props.pageState.microcreditBenefitTypeName].message}
                            </FormHelperText>
                          )}
                        </FormGroup>
                      </Grid>
                    )}
                    {(props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
                      props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
                      <>
                        <Grid item xs={12} md={3}>
                          <FormGroup>
                            <FormLabel>Número de kits</FormLabel>
                            <Controller
                              name={props.pageState.professionalKitBenefitTypeName}
                              control={props.control}
                              component={Box}
                              defaultValue=""
                              disabled={!props.isEditable}
                              rules={{
                                required: {
                                  value: true,
                                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                                },
                                validate: {
                                  minValue: (value) => {
                                    return value >= 0 || intl.formatMessage(errorMessages.ErrorMessage_Invalid_Value);
                                  },
                                  maxValue: (value) => {
                                    return (
                                      value <= props.pageState.info.numMaxKits ||
                                      intl.formatMessage(errorMessages.ErrorMessage_Invalid_ProfessionalKit_Value, {
                                        numMaxKits: props.pageState.info.numMaxKits,
                                      })
                                    );
                                  },
                                },
                              }}
                              render={({ field: { onChange, name, value } }) => (
                                <OutlinedInput
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    handleKitsChange(e.target.value);
                                  }}
                                  fullWidth
                                  disabled={
                                    !props.isEditable &&
                                    props.getValues(name) ===
                                      props.applicationsState?.applicationDetailsGeneralData?.professionalKitsNumber
                                  }
                                  autoComplete="off"
                                  type="number"
                                  classes={{
                                    input: classes.monetaryFields,
                                    notchedOutline: clsx({
                                      [props.classes.borderWarning]: props.errors[name] !== undefined,
                                    }),
                                  }}
                                />
                              )}
                            />
                            {props.errors[props.pageState.professionalKitBenefitTypeName] !== undefined && (
                              <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
                                {props.errors[props.pageState.professionalKitBenefitTypeName].message}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormGroup>
                            <FormLabel>Valor</FormLabel>
                            <OutlinedInput
                              value={kitsTotalValue}
                              fullWidth
                              autoComplete="off"
                              type="text"
                              disabled
                              classes={{ input: props.classes.monetaryFields }}
                              endAdornment={
                                <InputAdornment disableTypography={true} position="end">
                                  {UNITS.MONETARY}
                                </InputAdornment>
                              }
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={false} md={6}></Grid>
                      </>
                    )}
                    {props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP && (
                      <>
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Área onde pretende fazer o estágio</FormLabel>
                            <FormSelect
                              control={props.control}
                              fieldName={props.pageState.internshipFieldName}
                              selectOptions={getDatasetStateByName(props, DATASET.PROFESSIONAL_CATEGORY)}
                              getValues={props.getValues}
                              setValue={props.setValue}
                              required={true}
                              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                              errors={props.errors}
                              classes={props.classes}
                              disabled={
                                !props.isEditable &&
                                props.getValues(props.pageState.internshipFieldName)?.code ===
                                  props.applicationsState?.applicationDetailsGeneralData?.internshipAreaId
                              }
                            />
                            {props.errors[props.pageState.internshipFieldName] !== undefined && (
                              <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
                                {props.errors[props.pageState.internshipFieldName].message}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={false} md={6}></Grid>
                      </>
                    )}
                    {(props.pageState.info.benefitKeyValue === BENEFIT.CAPACITA ||
                      props.pageState.info.benefitKeyValue === BENEFIT.AVANCO ||
                      props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET) && (
                      <>
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>
                              {props.pageState.info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET
                                ? "Escolha da carteira pretendida"
                                : "Curso pretendido"}
                            </FormLabel>
                            <FormSelect
                              control={props.control}
                              fieldName={props.pageState.shapeProgramsFieldName}
                              selectOptions={getDatasetStateByName(props, DATASET.SHAPE_PROGRAMS)}
                              loadChildrenDataSet={loadShapeSubsites}
                              childrenDataSet={DATASET.SHAPE_SUBSITES}
                              childrenSelect={props.pageState.shapeSubsiteFieldName}
                              getValues={props.getValues}
                              setValue={props.setValue}
                              required={true}
                              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                              errors={props.errors}
                              classes={props.classes}
                              disabled={
                                !props.isEditable &&
                                props.getValues(props.pageState.shapeProgramsFieldName)?.code ===
                                  props.applicationsState?.applicationDetailsGeneralData?.intendedCourseCode
                              }
                            />
                            {props.errors[props.pageState.shapeProgramsFieldName] !== undefined && (
                              <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
                                {props.errors[props.pageState.shapeProgramsFieldName].message}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormGroup>
                            <FormLabel>Centro de formação</FormLabel>
                            <FormSelect
                              control={props.control}
                              fieldName={props.pageState.shapeSubsiteFieldName}
                              selectOptions={getDatasetStateByName(props, DATASET.SHAPE_SUBSITES)}
                              getValues={props.getValues}
                              required={true}
                              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                              errors={props.errors}
                              classes={props.classes}
                              setValue={props.setValue}
                              disabled={
                                !props.isEditable &&
                                props.getValues(props.pageState.shapeSubsiteFieldName)?.code ===
                                  props.applicationsState?.applicationDetailsGeneralData?.trainingCenterId
                              }
                            />
                            {props.errors[props.pageState.shapeSubsiteFieldName] !== undefined && (
                              <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
                                {props.errors[props.pageState.shapeSubsiteFieldName].message}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </Grid>
                      </>
                    )}
                    {props.applicationData?.documents?.length > 0 && props.isEditPage && (
                      <Grid item xs={12} md={12}>
                        <FormGroup>
                          <FormLabel>Documentos Submetidos</FormLabel>
                          <Grid container component={Box} marginTop="1rem" className={classes.documentBadgeContainer}>
                            {props.applicationData?.documents.map((file, i) => (
                              <Grid
                                item
                                xs={12}
                                className={classes.paddingRight0px}
                                key={i}
                                md={getDocumentFieldSize(props.pageState.info.subProgramDocument.length)}
                              >
                                <FormGroup>
                                  <FormLabel className={classes.boxNameCategory}>{file.documentTypeName}</FormLabel>
                                  {props.isEditable && (
                                    <DropzoneField
                                      name={FORM_FIELDS_PREFIX.DOCUMENT + file.documentTypeId.toString()}
                                      multiple={false}
                                      label={intl.formatMessage(labels.Label_Generic_Upload_Document)}
                                      register={props.register}
                                      errors={props.errors}
                                      required={false}
                                      requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                                      setValue={props.setValue}
                                      getValues={props.getValues}
                                      trigger={props.trigger}
                                      dropzoneClass={classes.dropzone}
                                    />
                                  )}
                                  <SubmitedDocumentsSection files={[file]} getDocument={props.getApplicationDocument} />
                                </FormGroup>
                              </Grid>
                            ))}
                          </Grid>
                        </FormGroup>
                      </Grid>
                    )}
                    {props.pageState.info.subProgramDocument?.length > 0 && !props.isEditPage && (
                      <Grid container className={props.classes.docsContainer}>
                        {getSubprogramDocumentsMessage(props.pageState.info.subProgramDocument)}
                        {props.pageState.info.subProgramDocument?.map((sd, key) => (
                          <Grid
                            item
                            xs={12}
                            md={getDocumentFieldSize(props.pageState.info.subProgramDocument.length)}
                            className={classes.paddingRight0px}
                            key={key}
                          >
                            <FormGroup>
                              <DropzoneField
                                name={FORM_FIELDS_PREFIX.DOCUMENT + sd.id.toString()}
                                multiple={false}
                                label={intl.formatMessage(labels.Label_Generic_Upload_Document)}
                                register={props.register}
                                errors={props.errors}
                                required={true}
                                requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                                setValue={props.setValue}
                                getValues={props.getValues}
                                trigger={props.trigger}
                                dropzoneClass={classes.dropzone}
                              />
                            </FormGroup>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  getShapePrograms: (target, province, name) => dispatch(getShapeProgramsAction(target, province, name)),
  getShapeSubsites: (offer, province, name) => dispatch(getShapeSubsitesAction(offer, province, name)),
  getApplicationDocument: (docId, docName) => dispatch(getApplicationDocumentAction(docId, docName)),
  getSubprogramByProgramId: (name, program, onlyActive) =>
    dispatch(getSubprogramByProgramIdAction(name, program, onlyActive)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Programs);
