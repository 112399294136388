import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import componentStyles from "assets/theme/views/admin/institute-holders.js";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

const useStyles = makeStyles(componentStyles);

function DisplayDataInstituteHolders(props) {
  const classes = useStyles();

  return (
    <div key={props.dataKey}>
      <div className={classes.boxMargin}>
        <span className={classes.title}>{props.data?.title}</span>
      </div>
      {props.data?.content?.map((content, contentKey) => {
        if (content.type) {
          if (content.type === DISPLAY_DATA_CONTENT_TYPE.IMAGE) {
            return (
              <div key={contentKey}>
                <span className={classes.tableTitle}>{content.title}</span>
                <div>
                  <img
                    src={content.path}
                    className={classes.images}
                    alt={content.title}
                  />
                </div>
              </div>
            );
          } else if (content.type === DISPLAY_DATA_CONTENT_TYPE.SUBTITLE) {
            return (
              <div key={contentKey}>
                <span className={classes.subtitle}>{content.subtitle}</span>
              </div>
            );
          } else if (
            content.type.includes(DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT)
          ) {
            return (
              <List dense={true} key={contentKey}>
                <ListItem
                  className={classes.listItem}
                  style={
                    content.type ===
                    DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT
                      ? { marginTop: "0px" }
                      : {}
                  }
                >
                  <div
                    style={{
                      marginLeft:
                        content.type ===
                        DISPLAY_DATA_CONTENT_TYPE.SUB_BULLET_POINT
                          ? "20px"
                          : content.type ===
                            DISPLAY_DATA_CONTENT_TYPE.SUB_SUB_BULLET_POINT
                          ? "40px"
                          : "0px",
                    }}
                  >
                    <span className={classes.content}>
                      <FiberManualRecordIcon /> {content.description}
                    </span>
                  </div>
                </ListItem>
              </List>
            );
          } else {
            return <div></div>;
          }
        } else {
          return (
            <div key={contentKey}>
              <span className={classes.content}>{content}</span>
            </div>
          );
        }
      })}
    </div>
  );
}


export default DisplayDataInstituteHolders;
