import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import LinkMaterialUi from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";

import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStyles from "assets/theme/views/admin/generic-card.js";
import componentStylesLandingPage from "assets/theme/components/landingPage/landing-page.js";
import { labels } from "resources/resources";
import { getInformationTypeChipColor } from "utils/getChipColors";
import { SOCIAL_MEDIA_TYPES_KEYVALUES } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStyles = makeStyles(componentStyles);
const useStylesLandingPage = makeStyles(componentStylesLandingPage);

function StructuringProjectCard(props) {
  const classes = {
    ...useStylesGeneric(),
    ...useStyles(),
    ...useStylesLandingPage(),
  };
  const intl = useIntl();

  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

  useEffect(() => {
    if (props.authState?.loggedIn !== undefined && props.authState.loggedIn)
      setUserIsAuthenticated(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectLink = (link) => {
    let correctLink = "";

    if (link.includes("http")) {
      correctLink = link;
    } else {
      correctLink = "http://" + link;
    }

    window.open(correctLink, "_blank");
  };

  return (
    <>
      <Card
        className={classes.cardRoot + " " + classes.structuringProjectCardRoot}
      >
        <img
          src={
            props.item.photo !== null
              ? props.item.photo
              : "/images/group-afro-americans-working-together.png"
          }
          className={classes.cardImgTop}
          alt={props.item.title}
        />

        <List disablePadding>
          <ListItem
            className={
              classes.listItemRoot +
              " " +
              classes.structuringProjectCardTitleDiv
            }
          >
            <div className={classes.doubleLineEllipsis}>{props.item.title}</div>
          </ListItem>
        </List>

        <CardContent>
          <Chip
            data-testid={"structuring-project-card-post-type-chip"}
            classes={{
              root: classes.informationTypeChipRoot,
            }}
            style={{
              backgroundColor: getInformationTypeChipColor(
                props.item?.informationDisseminationTypeKeyValue
              ),
              borderColor: getInformationTypeChipColor(
                props.item?.informationDisseminationTypeKeyValue
              ),
            }}
            label={props.item?.informationDisseminationTypeDescription}
            variant="outlined"
          />
          <ReactQuill
            readOnly={true}
            className={
              classes.descriptionSize +
              " " +
              classes.quillOverflowHidden +
              " " +
              classes.structuringProjectCardDescription
            }
            theme="bubble"
            value={props.item.description}
          />

          <Grid container>
            <Grid item xs={12} style={{ height: "40px" }}>
              {props.item.socialMediaList?.map((socialMedia, key) => (
                <Fragment key={key}>
                  {socialMedia.sociaMediaTypeKeyValue ===
                    SOCIAL_MEDIA_TYPES_KEYVALUES.FACEBOOK && (
                    <FacebookIcon
                      data-testid="structuting-project-card-facebook-button-id"
                      className={
                        classes.iconButtonOutlined +
                        " " +
                        classes.socialMediaIcon
                      }
                      onClick={() => redirectLink(socialMedia.url)}
                    />
                  )}
                  {socialMedia.sociaMediaTypeKeyValue ===
                    SOCIAL_MEDIA_TYPES_KEYVALUES.TWITTER && (
                    <TwitterIcon
                      data-testid="structuting-project-card-twitter-button-id"
                      className={
                        classes.iconButtonOutlined +
                        " " +
                        classes.socialMediaIcon
                      }
                      onClick={() => redirectLink(socialMedia.url)}
                    />
                  )}
                  {socialMedia.sociaMediaTypeKeyValue ===
                    SOCIAL_MEDIA_TYPES_KEYVALUES.YOUTUBE && (
                    <YouTubeIcon
                      data-testid="structuting-project-card-youtube-button-id"
                      className={
                        classes.iconButtonOutlined +
                        " " +
                        classes.socialMediaIcon
                      }
                      onClick={() => redirectLink(socialMedia.url)}
                    />
                  )}
                  {socialMedia.sociaMediaTypeKeyValue ===
                    SOCIAL_MEDIA_TYPES_KEYVALUES.INSTAGRAM && (
                    <InstagramIcon
                      data-testid="structuting-project-card-instagram-button-id"
                      className={
                        classes.iconButtonOutlined +
                        " " +
                        classes.socialMediaIcon
                      }
                      onClick={() => redirectLink(socialMedia.url)}
                    />
                  )}
                </Fragment>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.structuringProjectCardKnowMore}
            >
              <LinkMaterialUi
                component={Link}
                variant="h5"
                to={
                  userIsAuthenticated
                    ? `/admin/informationDissemination-details/${props.item.alternateKey}`
                    : `/auth/informationDissemination-details/${props.item.alternateKey}`
                }
                className={classes.containedGhostButton}
              >
                {intl.formatMessage(labels.Label_Know_More)}
              </LinkMaterialUi>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(StructuringProjectCard);
