import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  avatar: {
    width: "230px",
    height: "230px",
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      width: "180px",
      height: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "200px",
    },
  },
  nameInitialsAvatar: {
    width: "230px",
    height: "230px",
    marginTop: "30px",
    fontSize: "120px",
    [theme.breakpoints.down("md")]: {
      width: "180px",
      height: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "200px",
    },
  },
  updatePhotoButton: {
    padding: "12px 20px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    width: "11rem",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.darkBlue,
    border: "1px solid " + theme.palette.primary.darkBlue,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
      borderColor: theme.palette.primary.greyBlue,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.darkBlue,
      color: theme.palette.white.main,
      borderColor: theme.palette.primary.darkBlue,
    },
    "&:focus": {
      color: theme.palette.primary.darkBlue,
      border: "2px solid " + theme.palette.secondary.skyBlue,
    },
  },
  imageMaxSize: {
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "13px",
    color: theme.palette.primary.main,
  },
  toggleGroupGrid: {
    textAlign: "end",
    marginBottom: "20px",
  },
  cardHeaderAvatar: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "17px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  cardHeaderTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[700],
  },
  cardHeaderIcon: {
    width: "20px",
    height: "auto",
  },
  modalHeader: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: theme.palette.gray[700],
    marginBottom: "65px",
  },
  modalApproveLoan: {
    maxWidth: "530px",
    height: "571px",
    position: "absolute",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginBottom: "31.35%",
  },
  timelineDot: {
    color: theme.palette.primary.greyBlue,
  },
  timelineIcons: {
    color: theme.palette.white.main,
    width: "25px",
    height: "auto",
    marginTop: "5px",
  },
  timelineIconsBox: {
    background: theme.palette.gray[700],
    borderRadius: "35px",
    width: "37px",
    height: "37px",
    alignItems: "center",
    textAlign: "center",
  },
  navigateBefore: {
    color: theme.palette.primary.main,
    width: "30px",
    height: "auto",
    marginTop: "5px",
  },
  navigateBeforeBox: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "34px",
    width: "43px",
    height: "43px",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&:hover, &:focus": {
      boxShadow: "0px 0px 10px orange !important",
    },
  },
  titleGrid: {
    display: "flex",
  },
  ellipse: {
    marginLeft: "120px",
    marginTop: "-2px",
    width: "38px",
    height: "38px",
  },
  vector: {
    marginTop: "0px",
    marginLeft: "-23px",
    width: "7px",
    height: "14px",
  },
  pageTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginLeft: "22px",
  },
  alignRight: {
    textAlign: "right",
  },
  mb0: {
    marginBottom: "0!important",
  },
  containerPadding: {
    marginBottom: "37px",
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
  },
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },
  monetaryFields: {
    textAlign: "end",
  },
  saveButton: {
    background:
      "linear-gradient(90deg, " + theme.palette.info.main + " 0%, " + theme.palette.primary.mediumBlue + " 100%)",
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.white.main,
    width: "50%",
  },
  saveProfileButton: {
    background:
      "linear-gradient(90deg, " + theme.palette.info.main + " 0%, " + theme.palette.primary.mediumBlue + " 100%)",
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.white.main,
  },
  boxButtonSaveProfile: {
    textAlign: "right",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginBottom: "1rem",
    },
  },
  cancelButton: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    width: "50%",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  cdTypeAddButton: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    textTransform: "uppercase",
    padding: "6px 11px 6px 18px",
    marginBottom: "6px",
    marginTop: "6px",
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  cdTypeAddButtonLabel: {
    justifyContent: "normal",
  },
  cdButtons: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  cdLabels: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  paddingRight0px: {
    paddingRight: "0px",
  },
  cancelButtonFormGroup: {
    alignItems: "flex-start",
  },
  containerButtons: {
    marginLeft: "20px",
    marginRight: "20px",
  },
  submitButton: {
    color: theme.palette.white.main,
    background:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    border: "0px",
    boxShadow: "0px 0px 32px rgba(136, 152, 170, 0.15)",
  },
  detailLabels: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  modalSentRecover: {
    maxWidth: "718px",
    width: "718px",
    height: "571px",
    position: "absolute",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginBottom: "31.35%",
  },
  detailFormLabels: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  helpIconBox: {
    textAlign: "center",
    color: theme.palette.info.lightgreen,
  },
  helpIconRoot: {
    width: "100px",
    height: "auto",
  },
  modalUpdateLoan: {
    width: "530px",
    height: "600px",
    position: "absolute",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginBottom: "31.35%",
  },
  userProfileTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    textTransform: "uppercase",
    lineHeight: "40px",
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  borderRadiusSemiCurved: {
    borderRadius: "10px 0px 0px 10px",
  },
  userProfileUpdatePasswordButton: {
    height: "45px",
    padding: "6px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "0px 4px 4px 0px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    textTransform: "uppercase",
  },
  editButton: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    width: "50%",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  changeButtonsGroup: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "unset",
      display: "flex",
      justifyContent: "center",
      marginTop: "1rem",
    },
  },
  changeButton: {
    width: "300px",
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    heigth: "43px",
    backgroundColor: theme.palette.white.light,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px 0px 0px 4px",
    textTransform: "none",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    /* identical to box height */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",

    /* Text Color/Dark Blue */

    color: theme.palette.primary.greyBlue,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.greyBlue + " !important",
      /* Color Border/Secondary */

      border: "1px solid " + theme.palette.white.light,
      boxSizing: "border-box",
      borderRadius: "4px",

      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      /* identical to box height */

      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.02em",
      textTransform: "none",

      /* Text Color/Dark Blue */

      color: theme.palette.white.main,
    },
  },
  fileFilter: {
    width: "200px",
    marginLeft: "197px",

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "11px",
  },
  changeAvatarButton: {
    marginLeft: "200px",
    marginTop: "10px",
    height: "28px",
    width: "155px",
    color:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    background:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "30px",
    textAlign: "center",
    borderRadius: "4px",
    //display:"flex",
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    "&:hover": {
      backgroundColor: theme.palette.primary.mediumBlue,
    },
  },
  saveChangesButton: {
    marginLeft: "525px",
    marginTop: "",
    marginBottom: "201px",
    height: "43px",
    width: "226px",
    color:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    background:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    display: "flex",
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    "&:hover": {
      backgroundColor: theme.palette.primary.mediumBlue,
    },
  },
  saveChangesButtonPassword: {
    marginLeft: "895px",
    marginTop: "",
    marginBottom: "201px",
    height: "43px",
    width: "226px",
    color:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    background:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    display: "flex",
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    "&:hover": {
      backgroundColor: theme.palette.primary.mediumBlue,
    },
  },
  changePasswordButton: {
    marginLeft: "20px",
    marginTop: "23px",
    height: "45px",
    width: "206px",
    color:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    background:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    display: "flex",
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    "&:hover": {
      backgroundColor: theme.palette.primary.mediumBlue,
    },
  },
  modalRegister: {
    maxWidth: "718px",
    width: "718px",
    height: "571px",
    position: "absolute",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginBottom: "31.35%",
  },

  modalResultClose: {
    marginLeft: "90%",
  },
  okButton: {
    color:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    background:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    boxShadow: "0px 0px 32px rgba(136, 152, 170, 0.15)",
    height: "43px",
    width: "201px",
    borderRadius: "4px",
    marginLeft: "200px",
    marginTop: "50px",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
  },
  root: {
    marginLeft: "100px",
  },

  successMessage: {
    marginTop: "20px",
    marginLeft: "80px",
    width: "450px",

    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "47px",
    color: theme.palette.primary.mediumBlue,
    textAlign: "center",
  },
  successText: {
    width: "499px",

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    color: theme.palette.primary.mediumBlue,
    marginTop: "20px",
    marginLeft: "-20px",
  },
  formControlLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
    marginLeft: "17px",
    marginTop: "25px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-20px",
    },
  },
  formControlLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: theme.palette.gray[600],
  },
  alertMessage: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
  },
  dropzone: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "1px",
    borderRadius: "1px",
    height: "62px!important",
    borderColor: theme.palette.gray[600],
    borderStyle: "dashed",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray[500],
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  changeTabsGroupUserProfile: {
    float: "right",
    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      float: "unset",
      display: "flex",
      justifyContent: "center",
      marginTop: "1rem",
    },
  },
  changeTabsUserProfile: {
    width: "340px!important",
    [theme.breakpoints.down("md")]: {
      width: "280px!important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "240px!important",
    },
  },
  cardGrid: {
    margin: "0px 25px",
  },
});

export default componentStyles;
