import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";

import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import InformationSearchCard from "views/admin/InformationSearch/Card/InformationSearchCard";
import { getFilteredInformationAction } from "redux/actions/informationDisseminationAction";
import CardsListBackdrop from "components/Backdrops/CardsListBackdrop";
import {
  PAGINATION,
  INFORMATION_TYPES,
  RESOURCES_TYPES,
  INFORMATION_DISSEMINATION_TYPE_KEYVALUE,
} from "utils/const";
import { titles, labels } from "resources/resources";
import { removeAccents } from "utils/string_utils";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function InformationSearch(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  const [page, setPage] = useState(1);
  const [uiSearchResultList, setUiSearchResultList] = useState([]);
  const [allSearchResultList, setAllSearchResultList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const resourcesDataList = [
    //INEFOP Menu
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_InstitutionalInformation_Overview
      ),
      title: intl.formatMessage(
        titles.Title_Menu_InstitutionalInformation_Overview
      ),
      path: "/institution-overview",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_InstitutionalInformation_History
      ),
      title: intl.formatMessage(
        titles.Title_Menu_InstitutionalInformation_History
      ),
      path: "/institution-history",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_InstitutionalInformation_MissionVisionAndValues
      ),
      title: intl.formatMessage(
        titles.Title_Menu_InstitutionalInformation_MissionVisionAndValues
      ),
      path: "/institution-mission-vision-values",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_InstitutionalInformation_OrganicStatus
      ),
      title: intl.formatMessage(
        titles.Title_Menu_InstitutionalInformation_OrganicStatus
      ),
      path: "/institution-organic-status",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_InstitutionalInformation_OrganizationChart
      ),
      title: intl.formatMessage(
        titles.Title_Menu_InstitutionalInformation_OrganizationChart
      ),
      path: "/institution-organization-chart",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_InstitutionalInformation_DirectorBiography
      ),
      title: intl.formatMessage(
        titles.Title_Menu_InstitutionalInformation_DirectorBiography
      ),
      path: "/director-biography",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_InstitutionalInformation_InstituteChiefs
      ),
      title: intl.formatMessage(
        titles.Title_Menu_InstitutionalInformation_InstituteChiefs
      ),
      path: "/institute-holders",
    },

    //Information Menu
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_Information_EmploymentServices_EmploymentCenters
      ),
      title: intl.formatMessage(
        titles.Title_Menu_Information_EmploymentServices_EmploymentCenters
      ),
      path: "/information-employment-centers",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_Information_EmploymentServices_Employment_Centers_Network
      ),
      title: intl.formatMessage(
        titles.Title_Menu_Information_EmploymentServices_Employment_Centers_Network
      ),
      path: "/information-employment-centers-network",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration
      ),
      title: intl.formatMessage(
        titles.Title_Menu_Information_EmploymentServices_ForeignWorkersContractRegistration
      ),
      path: "/information-foreign-workers-contract-registration",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_Information_EntrepreneurshipServices
      ),
      title: intl.formatMessage(
        titles.Title_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCenters
      ),
      path: "/information-entrepreneurship-centers",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCentersNetwork
      ),
      title: intl.formatMessage(
        titles.Title_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCentersNetwork
      ),
      path: "/information-entrepreneurship-centers-network",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_Information_ProfessionalTraining_ProfessionalWallet
      ),
      title: intl.formatMessage(
        titles.Title_Menu_Information_ProfessionalTraining_ProfessionalWallet
      ),
      path: "/information-professional-wallet",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_Information_ProfessionalTraining_FormationOffer
      ),
      title: intl.formatMessage(
        titles.Title_Menu_Information_ProfessionalTraining_FormationOffer
      ),
      path: "/information-formation-offer",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_Information_ProfessionalTraining_TrainingCentersLicensing
      ),
      title: intl.formatMessage(
        titles.Title_Menu_Information_ProfessionalTraining_TrainingCentersLicensing
      ),
      path: "/information-training-centers-licensing",
    },
    {
      prefix: intl.formatMessage(
        labels.Label_Search_Menu_Information_CompanyServices_RatifyAgreement
      ),
      title: intl.formatMessage(
        titles.Title_Menu_Information_CompanyServices_RatifyAgreement
      ),
      path: "/ratify-agreement",
    },

    //Statistics
    {
      prefix: intl.formatMessage(labels.Label_Search_Menu_Statistics),
      title: intl.formatMessage(titles.Title_Menu_Statistics),
      path: "/statistics",
    },
  ];

  useEffect(() => {
    if (props.match?.params?.searchText) {
      getAllUiInformation(props.match?.params?.searchText);

      const filter = {
        searchText: props.match?.params?.searchText,
        isPublic:
          props.authState.loggedIn !== undefined && props.authState.loggedIn
            ? null
            : true,
        published: true,
        pageIndex: page,
        pageSize: PAGINATION.PAGE_SIZE_DISSEMINATION,
      };

      props.getFilterInformation(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, props.match?.params?.searchText]);

  useEffect(() => {
    const nrRecordsAPI = props.informationDisseminationState?.total_records;
    const nrRecordsUI = Object.keys(uiSearchResultList).length;

    const searchFromAPI =
      props.informationDisseminationState?.result?.data?.map((prop) => {
        return {
          title: prop.title,
          description: prop.description.replace(/(<([^>]+)>)/gi, ""),
          informationDisseminationTypeDescription:
            prop.informationDisseminationTypeDescription ===
            INFORMATION_TYPES.INFORMATION
              ? INFORMATION_TYPES.NEWS
              : prop.informationDisseminationTypeDescription,
          informationDisseminationTypeKeyValue:
            prop.informationDisseminationTypeKeyValue,
          searchResultPath:
            (props.authState?.loggedIn ? "/admin" : "/auth") +
            "/informationDissemination-details/" +
            prop.alternateKey,
          publishedOn: prop.publishedOn,
          photo: prop.photo,
        };
      });

    const searchFromUI = Object.keys(uiSearchResultList)
      .map((key) => {
        return {
          title: resourcesDataList?.find((label) => label.prefix === key).title,
          description: uiSearchResultList[key].defaultMessage,
          informationDisseminationTypeDescription:
            INFORMATION_TYPES.INFORMATION,
          informationDisseminationTypeKeyValue:
            INFORMATION_DISSEMINATION_TYPE_KEYVALUE.INFORMATION,
          searchResultPath:
            (props.authState?.loggedIn ? "/admin" : "/auth") +
            resourcesDataList?.find((label) => label.prefix === key).path,
          publishedOn: null,
          photo: null,
        };
      })
      .slice(
        Math.max(
          0,
          (page - 1) * PAGINATION.PAGE_SIZE_DISSEMINATION - nrRecordsAPI
        ),
        PAGINATION.PAGE_SIZE_DISSEMINATION * page - 1
      );

    setAllSearchResultList(
      searchFromAPI
        ?.concat(searchFromUI)
        ?.slice(0, PAGINATION.PAGE_SIZE_DISSEMINATION)
    );

    setTotalPages(
      Math.ceil(
        (nrRecordsAPI + nrRecordsUI) / PAGINATION.PAGE_SIZE_DISSEMINATION
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDisseminationState?.result?.data, uiSearchResultList]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const getAllUiInformation = (searchText) => {
    let searchTextArray = searchText
      .trim()
      .split(" ")
      .filter((str) => str !== "");

    const prefix_values = resourcesDataList.map((label) => {
      return label.prefix;
    });

    const filteredLabelsList = Object.keys(labels)
      .filter(
        (key) =>
          searchTextArray.some((word) =>
            removeAccents(labels[key].defaultMessage.toLowerCase()).includes(
              removeAccents(word.toLowerCase())
            )
          ) &&
          prefix_values.some((prefix) => key.includes(prefix)) &&
          !key.includes("Label_Search_Menu_")
      )
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: {
            ...labels[key],
            type: RESOURCES_TYPES.LABEL,
          },
        });
      }, {});

    const filteredTitlesList = Object.keys(titles)
      .filter(
        (key) =>
          searchTextArray.some((word) =>
            removeAccents(titles[key].defaultMessage.toLowerCase()).includes(
              removeAccents(word.toLowerCase())
            )
          ) &&
          prefix_values.some((prefix) => key.includes(prefix)) &&
          !key.includes("Label_Search_Menu_")
      )
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: {
            ...titles[key],
            type: RESOURCES_TYPES.TITLE,
          },
        });
      }, {});

    const filteredLabelsAndTitlesList = {
      ...filteredLabelsList,
      ...filteredTitlesList,
    };

    const oneResultPerPagefilteredLabelsAndTitlesList = Object.keys(
      filteredLabelsAndTitlesList
    ).reduce((obj, key) => {
      const prefix = prefix_values.find((prefix) => key.includes(prefix));
      if (prefix && !(prefix in obj)) {
        obj[prefix] = filteredLabelsAndTitlesList[key];
      }
      return obj;
    }, {});

    setUiSearchResultList(oneResultPerPagefilteredLabelsAndTitlesList);
  };

  return (
    <Grid container classes={{ root: classes.containerRoot }}>
      <PageTitleHeader title={intl.formatMessage(titles.Title_SearchResults)} />

      <Grid item xs={12} md={12}>
        {allSearchResultList !== undefined &&
        allSearchResultList?.length > 0 ? (
          <Grid container style={{ position: "relative" }}>
            {allSearchResultList?.map((info, key) => (
              <Grid key={key} item xs={12} md={6} lg={4} xl={3}>
                <InformationSearchCard
                  informationDisseminationTypeDescription={
                    info.informationDisseminationTypeDescription
                  }
                  informationDisseminationTypeKeyValue={
                    info.informationDisseminationTypeKeyValue
                  }
                  title={info.title}
                  description={info.description}
                  publishedOn={info.publishedOn}
                  photo={info.photo}
                  searchResultPath={info.searchResultPath}
                  itemKey={key}
                />
              </Grid>
            ))}
            <CardsListBackdrop
              open={
                props.informationDisseminationState.searchInformation_loading
              }
            />
          </Grid>
        ) : (
          <div className={classes.noResultsInfo}>
            {intl.formatMessage(labels.Label_No_Results_Found)}
          </div>
        )}
        <Pagination
          className={classes.alignPagination}
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        ></Pagination>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getFilterInformation: (data) => dispatch(getFilteredInformationAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationSearch);
