import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import SendIcon from "@material-ui/icons/Send";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Pagination from "@material-ui/lab/Pagination";

import moment from "moment";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import CardTableContainer from "../Tables/CardTableContainer";
import { ArrayUtils } from "utils/array_utils";

// @material-ui/lab components
// core components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesNotifications from "assets/theme/components/cards/dashboard/card-notifications-dashboard.js";
import { getNotificationsAction, updateNotificationAction } from "redux/actions/notificationsAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylesNotifications = makeStyles(componentStylesNotifications);

function CardNotificationsDashboard(props) {
  const classes = { ...useStylesGeneric(), ...useStylesNotifications() };
  const theme = useTheme();
  const [notificationsList, setNotificationsList] = useState([]);

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.notificationState.notifications_list)) {
      setNotificationsList(props.notificationState.notifications_list);
    } else {
      setNotificationsList([]);
    }
  }, [props.notificationState.notifications_list]);

  const getHours = (date) => {
    if (date) return moment(date).format("HH:mm");
  };
  const getDate = (date) => {
    if (date) return moment(date).format("DD/MM/YYYY");
  };
  const getUnreadNotificationsCount = () => {
    return props.notificationState?.total_records !== undefined ? props.notificationState?.total_records : 0;
  };

  return (
    <CardTableContainer
      title={"Lista de Notificações"}
      count={getUnreadNotificationsCount()}
    >
      {notificationsList !== undefined && notificationsList.length > 0 && (
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableBody>
              {notificationsList?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Grid container component={Box} alignItems="center" justifyContent="space-between">
                      <Accordion
                        classes={{ root: classes.AccordionRoot }}
                        onChange={(_, val) => {
                          if (val && !prop.isRead) props.updateNotification(prop.id);
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <Box
                              component={ExpandMoreIcon}
                              width="9px!important"
                              height="9px!important"
                              color={theme.palette.primary.main}
                            />
                          }
                          aria-controls={"notification-content" + key}
                          id={"notification-content" + key}
                        >
                          <Grid item xs={8} xl={8}>
                            <Box marginTop="10px" display="flex">
                              <Divider
                                flexItem={true}
                                orientation="vertical"
                                classes={{
                                  root:
                                    classes.dividerRoot +
                                    " " +
                                    (!prop.isRead ? classes.readNotificationDividerRoot : ""),
                                }}
                              />
                              <Box marginLeft="20px" marginTop="10px" display="flex">
                                <Box marginTop="7px" className={classes.sentIcon}>
                                  <SendIcon></SendIcon>
                                </Box>
                                <Box marginLeft="10px">
                                  <Box
                                    component={Typography}
                                    classes={{
                                      root: classes.cellTextRoot + " " + classes.cellTitleTextRoot,
                                    }}
                                    marginBottom="0!important"
                                  >
                                    <Box component="span" className={classes.typographyOrigin}>
                                      {prop.origin}
                                    </Box>
                                    <Box className={classes.typographySentBy} marginLeft="5px" component="span">
                                      {prop.sentBy}
                                    </Box>
                                    <Box marginBottom="10px">
                                      <Box
                                        component={Typography}
                                        classes={{
                                          root: classes.cellTextRoot + " " + classes.cellTitleTextRoot,
                                        }}
                                        marginBottom="0!important"
                                      >
                                        <Box className={classes.typographySubject} component="span">
                                          {prop.title}
                                        </Box>
                                      </Box>
                                      <Box
                                        component={Typography}
                                        className={classes.typographyMessage}
                                        marginBottom="0!important"
                                      >
                                        <Box component="span">{prop.subtitle}</Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={4} xl={4}>
                            <Box marginTop="35px" textAlign="end">
                              <Box
                                component={Typography}
                                classes={{ root: classes.cellTextRoot }}
                                marginBottom="0!important"
                              >
                                <Box marginLeft="220px" component="span">
                                  {getHours(prop.date)}
                                </Box>
                              </Box>
                              <Box
                                component={Typography}
                                classes={{ root: classes.cellTextRoot }}
                                marginBottom="0!important"
                              >
                                <Box marginLeft="220px" component="span">
                                  {" "}
                                  {getDate(prop.date)}{" "}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            whiteSpace="normal"
                            padding="10px 40px 10px 40px"
                            className={classes.typographyMessage}
                            dangerouslySetInnerHTML={{ __html: prop.message }}
                          ></Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
          <TableBackdrop open={props.notificationState.notifications_Loading} />
        </TableContainer>
      )}

      <Pagination
        className={classes.alignPagination}
        count={props.notificationState?.total_pages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </CardTableContainer>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getNotifications: () => dispatch(getNotificationsAction()),
  updateNotification: (notificationId) => dispatch(updateNotificationAction(notificationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardNotificationsDashboard);
