import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function EmploymentCentersNetwork() {
  const intl = useIntl();

  const employmentCentersNetworkData = [
    {
      title: "",
      content: [
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/EmploymentCentersNetworkINEFOP.png",
          title: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_Employment_Centers_Network_Table1
          ),
        },
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(
        titles.Title_Menu_Information_EmploymentServices_Employment_Centers_Network
      )}
      data={employmentCentersNetworkData}
    />
  );
}

export default EmploymentCentersNetwork;
