import { INFORMATION_DISSEMINATION } from "utils/actionsConsts";

const informationDisseminationReducer = (
  state = {
    filteredInformation_loading: false,
    filteredStructuringProjects_loading: false,
    get_information_details_loading: false,
    refresh_results: false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION_LOADING:
      return { ...state, filteredInformation_loading: action.payload };
    case INFORMATION_DISSEMINATION.GET_FILTERED_STRUCTURING_PROJECTS:
      return {
        ...state,
        filteredStructuringProjects_result: action.payload,
        filteredStructuringProjects_totalRecords: action.payload.total_records,
        filteredStructuringProjects_totalPages: action.payload.total_pages,
      };
    case INFORMATION_DISSEMINATION.GET_FILTERED_STRUCTURING_PROJECTS_LOADING:
      return { ...state, filteredStructuringProjects_loading: action.payload };
    case INFORMATION_DISSEMINATION.GET_HIGHLIGHTED_INFORMATION_DISSEMINATION:
      return {
        ...state,
        highlightedInformation_result: action.payload,
        highlightedInformation_totalRecords: action.payload.total_records,
        highlightedInformation_totalPages: action.payload.total_pages,
      };
    case INFORMATION_DISSEMINATION.GET_HIGHLIGHTED_INFORMATION_DISSEMINATION_LOADING:
      return { ...state, highlightedInformation_loading: action.payload };
    case INFORMATION_DISSEMINATION.GET_STRUCTURING_PROJECTS:
      return {
        ...state,
        structuringProjects_result: action.payload,
        structuringProjects_totalRecords: action.payload.total_records,
        structuringProjects_totalPages: action.payload.total_pages,
      };
    case INFORMATION_DISSEMINATION.GET_STRUCTURING_PROJECTS_LOADING:
      return { ...state, structuringProjects_loading: action.payload };
    case INFORMATION_DISSEMINATION.GET_SEARCHED_INFORMATION_DISSEMINATION:
      return {
        ...state,
        searchedInformation_result: action.payload,
        searchedInformation_total_records: action.payload.total_records,
        searchedInformation_total_pages: action.payload.total_pages,
      };
    case INFORMATION_DISSEMINATION.GET_SEARCHED_INFORMATION_DISSEMINATION_LOADING:
      return { ...state, searchedInformation_loading: action.payload };
    case INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS:
      return { ...state, informationDetailsData: action.payload };
    case INFORMATION_DISSEMINATION.CLEAR_DETAIL:
      return { ...state, informationDetailsData: {} };
    case INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING:
      return { ...state, get_information_details_loading: action.payload };
    default:
      return state;
  }
};

export default informationDisseminationReducer;
