import React from "react";
//redux
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from "@material-ui/core/FormLabel";
//@material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//custom components
import { opportunityCandidateWithdrawAction } from "redux/actions/opportunitiesAction";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";
import { OPPORTUNITY_CANDIDATE_STATE_KEY } from "utils/const";

//styles
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidateWithdrawDialog(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const methods = useForm({
    mode: "onChange",
  });

  const { handleSubmit, control } = methods;

  const toWithdraw = props.candidateStateKey === OPPORTUNITY_CANDIDATE_STATE_KEY.EMRECRUTAMENTO;

  const onSubmitWithdraw = (data) => {
    let filter = {
      candidateId: props.candidateId,
      opportunityId: props.opportunityId,
      withdrawObservations: data?.observations,
    };
    props.opportunityCandidateWithdraw(filter, props.opportunitiesState.isOpportunityApplication, toWithdraw);
  };

  return (
    <GenericDialog name={props.name} open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitWithdraw)}>
          <Box className={classes.helpIconBox}>
            <HelpOutlineIcon className={classes.helpIconRoot} />
          </Box>

          <Container>
            <Box className={classes.modalHeader} marginBottom="30px!important">
              {toWithdraw
                ? "Pretende registar a desistência do candidato?"
                : "Pretende anular a desistência do candidato?"}
            </Box>

            <Box marginTop="30px">
              <Grid container>
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormLabel>Comentários</FormLabel>
                    <Controller
                      name="observations"
                      control={control}
                      component={Box}
                      defaultValue=""
                      render={({ field: { onChange, name, value } }) => (
                        <OutlinedInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          placeholder={"Escreva aqui os seus comentários"}
                          multiline={true}
                          rows={3}
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Box textAlign="center" marginBottom="50px" marginTop="50px">
            <GenericButton
              typeSubmit={true}
              color="primary"
              loading={props.opportunitiesState.candidateWithdraw_loading}
            >
              {intl.formatMessage(labels.Label_Button_Submit)}
            </GenericButton>
          </Box>
        </form>
      </FormProvider>
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  opportunityCandidateWithdraw: (formData, isOpportunityApplication, toWithdraw) =>
    dispatch(opportunityCandidateWithdrawAction(formData, isOpportunityApplication, toWithdraw)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateWithdrawDialog);
