import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import SearchBar from "components/SearchBar/SearchBar";
import { labels } from "resources/resources";

function SearchBarNavbar(props) {
  const intl = useIntl();

  const frequentlyAskedQuestionsList = [
    {
      title: intl.formatMessage(labels.Label_Menu_Help_FAQs_HowToRegisterInInefop),
      path: "Register_FO_user.pdf",
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Help_FAQs_HowToApplyToAJob),
      path: "Employment_And_Internship_Application.pdf",
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Help_FAQs_HowToApplyToGovernmentSupports),
      path: "Government_Supports_Application.pdf",
    },
  ];

  return (
    <div>
      <SearchBar
        title={intl.formatMessage(labels.Label_SearchBox_Title)}
        placeholder={intl.formatMessage(labels.Label_SearchBox_Placeholder_WriteWhatYoureLookingFor)}
        resultsPagePath={(props.authState?.loggedIn ? "/admin" : "/auth") + "/information-search/"}
        frequentlyAskedQuestionsList={frequentlyAskedQuestionsList}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(SearchBarNavbar);
