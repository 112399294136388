import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function FormationOffer() {
  const intl = useIntl();

  const formationOfferData = [
    {
      title: "",
      content: [
        img("/images/information/formationOffer/formationOfferImage1.png"),
        img("/images/information/formationOffer/formationOfferImage2.png"),
        img("/images/information/formationOffer/formationOfferImage3.png"),
        img("/images/information/formationOffer/formationOfferImage4.png"),
        img("/images/information/formationOffer/formationOfferImage5.png"),
        img("/images/information/formationOffer/formationOfferImage6.png"),
        img("/images/information/formationOffer/formationOfferImage7.png"),
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(
        titles.Title_Menu_Information_ProfessionalTraining_FormationOffer
      )}
      data={formationOfferData}
    />
  );
}

const img = (path) => {
  return {
    type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
    path: path,
    title: "",
  };
};

export default FormationOffer;
