import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { getDatasetStateByName } from "../../../../../utils/datasets";
import {
  getAllProgramsAction,
  getSubprogramByProgramIdAction,
  getShapeProgramsAction,
  getDataSetByNameAction,
  getShapeSubsitesAction
} from "redux/actions/datasetsAction";
import { 
  getSubprogramInformationAction,
  dataForApplicationCreationAction,
} from "redux/actions/applicationsAction";
import { labels, errorMessages } from "resources/resources";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// core components
import componentStyles from "assets/theme/components/cards/tables/card-light-table-tables.js";
import componentStylesBadge from "assets/theme/components/badge.js";
//custom componentsv
import FormSelect from "../../../../../components/FormFields/FormSelect";
//constants
import { APPLICATIONS_ACTIONS } from "utils/actionsConsts";
import { DATASET, SHAPE_PROGRAMS_TARGET, BENEFIT } from "utils/const";
import { getSelectOptionsElementByCode } from "utils/formSelect";

const useStyles = makeStyles(componentStyles);
const useStylesBadge = makeStyles(componentStylesBadge);

const tableHead = ["Programa", "Subprograma"];

function CardSubprogramApplicationTable(props) {
  const classes = { ...useStyles(), ...useStylesBadge() };
  const intl = useIntl();

  useEffect(() => {
    setSubprogramInfo(props.applicationsState.subprogramsInfo?.info);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applicationsState?.subprogramsInfo?.id]);

  useEffect(() => {
    props.getAllPrograms(props.config.pageState.governmentProgramId ?? 1); 
    if (!props.isEditPage) {
      props.setValue(props.config.pageState.programsFieldName, props.config.pageState.programId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config.pageState.governmentProgramId, props.config.pageState.programId]);

  useEffect(() => {
    if (!props.isEditPage) {
      if (props.applicationsState.dataForApplicationCreation.programId) {
        props.setValue(
          props.config.pageState.programsFieldName,
          getSelectOptionsElementByCode(
            getDatasetStateByName(props, DATASET.PROGRAMS),
            props.applicationsState.dataForApplicationCreation.programId
          )
        );
        props.getSubprogramByProgramId(DATASET.SUBPROGRAMS, props.applicationsState.dataForApplicationCreation.programId, true);
      } else props.setValue(props.config.pageState.programsFieldName, undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROGRAMS]]);

  const setSubprogramInfo = (_info) => {
    props.config.setSubprogramInfo(_info);
    //load datasets related to benefits
    if (
      _info &&
      (_info.benefitKeyValue === BENEFIT.CAPACITA ||
        _info.benefitKeyValue === BENEFIT.AVANCO ||
        _info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET)
    ) {
      let target = "";
      if (_info.benefitKeyValue === BENEFIT.AVANCO) target = SHAPE_PROGRAMS_TARGET.AVANCO;
      if (_info.benefitKeyValue === BENEFIT.CAPACITA) target = SHAPE_PROGRAMS_TARGET.CAPACITA;
      if (_info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET) target = SHAPE_PROGRAMS_TARGET.PROFESSIONAL_WALLET;
      props.config.setValue(props.config.pageState.shapeProgramsFieldName, undefined);
      props.config.setValue(props.config.pageState.shapeSubsiteFieldName, undefined);
      props.config.getShapePrograms(
        target,
        props.config.getValues(props.config.pageState?.provinceFieldName)?.code,
        DATASET.SHAPE_PROGRAMS
      );
      //props.getShapeSubsites(props.config.getValues(props.config.pageState.shapeProgramsFieldName)?.code, props.config.getValues(props.config.pageState.provinceFieldName)?.code, DATASET.SHAPE_SUBSITES);
    }
    if (_info && _info.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP) {
      props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    }
  };

  const unregisterComplementaryDataOnChange = () => {
    if (props.config.pageState?.complementaryData?.length > 0) {
      props.config.pageState.complementaryData.forEach((c) => {
        props.config.unregister(c.id);
      });
    }
    clearComplementaryData();
  };

  const programsOnChange = (program) => {
    unregisterComplementaryDataOnChange();
    clearSubProgramInfo();
    changeData(program)
  };

  const changeData = (program) => {
    if(program == null)
    props.dataForApplicationCreation({governmentProgramId: props.applicationsState.dataForApplicationCreation.governmentProgramId, programId: null})
    else
    props.dataForApplicationCreation({governmentProgramId: props.applicationsState.dataForApplicationCreation.governmentProgramId, programId: program.code})
  }

  const subProgramsOnChange = () => {
    props.resetForm();
    clearSubProgramInfo();

    unregisterComplementaryDataOnChange();
    unregisterDocuments();
    props.getSubprogramInformation(props.config.getValues(props.config.pageState.subprogramsFieldName)?.code);

    clearSubprogramBenefitsAndDocuments();
  };

  const unregisterDocuments = () => {
    props.config.pageState?.docFields?.forEach((d) => {
      props.config.setValue(d.id, undefined);
      props.config.unregister(d.id);
    });
  };

  const clearSubprogramBenefitsAndDocuments = () => {
    //reset fields
    props.config.setValue(props.config.pageState.generatedWorkPostsName, "");
    props.config.setValue(props.config.pageState.bankPreferenceFieldName, null);
    props.config.setValue(props.config.pageState.microcreditBenefitTypeName, "");
    props.config.setValue(props.config.pageState.professionalKitBenefitTypeName, "");
    props.config.setValue(props.config.pageState.shapeProgramsFieldName, undefined);
    props.config.setValue(props.config.pageState.shapeSubsiteFieldName, undefined);
    props.config.setValue(props.config.pageState.internshipFieldName, undefined);
  };

  const clearComplementaryData = () => {
    props.config.clearComplementaryData();
  };

  const clearSubProgramInfo = () => {
    props.config.clearSubProgramInfo();
  };

  return (
    <>
      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0.25rem!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  classes={{
                    root: classes.tableCellRoot + " " + classes.programsTableCell,
                  }}
                >
                  {prop}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell classes={{ root: classes.tableCellRoot }}>
                <FormSelect
                  control={props.config.control}
                  fieldName={props.config.pageState.programsFieldName}
                  selectOptions={getDatasetStateByName(props, DATASET.PROGRAMS)}
                  getValues={props.config.getValues}
                  loadChildrenDataSet={props.getSubprogramByProgramId}
                  childrenDataSet={DATASET.SUBPROGRAMS}
                  childrenSelect={props.config.pageState.subprogramsFieldName}
                  setValue={props.config.setValue}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  initialLabel={intl.formatMessage(
                    labels.Label_Admin_Applications_Programs_Field_ProgramSubprogram_Placeholder
                  )}
                  errors={props.config.errors}
                  classes={props.config.classes}
                  onOptionChange={programsOnChange}
                  trigger={props.config.trigger}
                  disabled={props.isEditPage ? true : props.isEditable}
                />
                {props.config.errors[props.config.pageState.programsFieldName] !== undefined && (
                  <FormHelperText component={Box} color={props.config.theme.palette.warning.main + "!important"}>
                    {props.config.errors[props.config.pageState.programsFieldName].message}
                  </FormHelperText>
                )}
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot }}>
                <FormSelect
                  control={props.config.control}
                  fieldName={props.config.pageState.subprogramsFieldName}
                  selectOptions={getDatasetStateByName(props, DATASET.SUBPROGRAMS)}
                  getValues={props.config.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                  initialLabel={intl.formatMessage(
                    labels.Label_Admin_Applications_Programs_Field_Subprogram_Placeholder
                  )}
                  errors={props.config.errors}
                  classes={props.config.classes}
                  onOptionChange={subProgramsOnChange}
                  trigger={props.config.trigger}
                  disabled={
                    props.isEditPage
                      ? true
                      : props.isEditable || !props.config.getValues(props.config.pageState.programsFieldName)
                  }
                />
                {props.config.errors[props.config.pageState.subprogramsFieldName] !== undefined && (
                  <FormHelperText component={Box} color={props.config.theme.palette.warning.main + "!important"}>
                    {props.config.errors[props.config.pageState.subprogramsFieldName].message}
                  </FormHelperText>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Box>
      </TableContainer>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name) => dispatch(getDataSetByNameAction(name)),
  getAllPrograms: (governmentProgramId) => dispatch(getAllProgramsAction(governmentProgramId)),
  getSubprogramByProgramId: (name, program, onlyActive) =>
    dispatch(getSubprogramByProgramIdAction(name, program, onlyActive)),
  getSubprogramInformation: (subprogramId) => dispatch(getSubprogramInformationAction(subprogramId)),
  getShapePrograms: (target, province, name) => dispatch(getShapeProgramsAction(target, province, name)),
  getShapeSubsites: (offer, province, name) => dispatch(getShapeSubsitesAction(offer, province, name)),
  resetForm: () => dispatch({ type: APPLICATIONS_ACTIONS.RESET_FORM }),
  dataForApplicationCreation : (data) => dispatch(dataForApplicationCreationAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CardSubprogramApplicationTable);
