import { COMPLEMENTARY_DATA_ANSWER_TYPE, BENEFIT, FORM_FIELDS_PREFIX } from "utils/const";

export function createApplicationMap(applicationData, pageInfo) {
  if (applicationData && pageInfo.complementaryData !== undefined) {
    const application = {
      subProgramId: applicationData[pageInfo.subprogramsFieldName]
        ? applicationData[pageInfo.subprogramsFieldName].code
        : "",
      provinceId: applicationData[pageInfo.provinceFieldName]
        ? applicationData[pageInfo.provinceFieldName].code
        : "",
      microCreditAmount:
        pageInfo.info.benefitKeyValue === BENEFIT.MICROCREDIT ||
          pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT
          ? applicationData[pageInfo.microcreditBenefitTypeName]
          : "",
      bankPreferenceId:
        pageInfo.info.benefitKeyValue === BENEFIT.MICROCREDIT ||
          pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
          pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT
          ? applicationData[pageInfo.bankPreferenceFieldName].code
          : null,
      professionalKitsNumber:
        pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
          pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT
          ? applicationData[pageInfo.professionalKitBenefitTypeName]
          : "",
      intendedCourseCode:
        (pageInfo.info.benefitKeyValue === BENEFIT.AVANCO ||
          pageInfo.info.benefitKeyValue === BENEFIT.CAPACITA ||
          pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET) &&
          applicationData[pageInfo.shapeProgramsFieldName]
          ? applicationData[pageInfo.shapeProgramsFieldName].code
          : "",
      intendedCourseExternalId:
        pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET &&
          applicationData.shapeProgram
          ? applicationData.shapeProgram.externalId
          : "",
      trainingCenterId:
        (pageInfo.info.benefitKeyValue === BENEFIT.AVANCO ||
          pageInfo.info.benefitKeyValue === BENEFIT.CAPACITA ||
          pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_WALLET) &&
          applicationData[pageInfo.shapeSubsiteFieldName]
          ? applicationData[pageInfo.shapeSubsiteFieldName].code
          : "",
      internshipAreaId:
        pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP &&
          applicationData[pageInfo.internshipFieldName]
          ? applicationData[pageInfo.internshipFieldName].code
          : "",
      parentApplicationId: applicationData[pageInfo.parentApplciationFieldName]
        ? applicationData[pageInfo.parentApplciationFieldName].code
        : "",
      isAlternative: false,
      generatedWorkPosts:
        pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
          pageInfo.info.benefitKeyValue === BENEFIT.MICROCREDIT ||
          pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT_MICROCREDIT
          ? applicationData[pageInfo.generatedWorkPostsName]
            ? applicationData[pageInfo.generatedWorkPostsName]
            : 0
          : 0,
      benefitKeyValue: pageInfo.info.benefitKeyValue,
      provinceKeyValue: applicationData[pageInfo.provinceFieldName]
        ? applicationData[pageInfo.provinceFieldName].keyValue
        : "",
      originKeyValue: "OriginFO",
      applicationDocuments: []
    };
    const answers = pageInfo.complementaryData.map((c) => ({
      complementaryDataId: c.id?.replace(FORM_FIELDS_PREFIX.COMPLEMENTARY_DATA, ''),
      answer:
        c.answerTypeId === COMPLEMENTARY_DATA_ANSWER_TYPE.DATASET
          ? applicationData[c.id]
            ? applicationData[c.id].code + ""
            : ""
          : applicationData[c.id]
            ? applicationData[c.id]
            : "",
    }));

    //Set FormData
    const formData = new FormData();
    application["answers"] = answers;
    //Append files
    let countDoc = 0;
    pageInfo?.docFields?.forEach(dt => {
      if (applicationData[dt.id] && applicationData[dt.id][0]) {
        application.applicationDocuments.push({
          documentTypeId: dt.id?.replace(FORM_FIELDS_PREFIX.DOCUMENT, ''),
          documentId: countDoc //we send the index on the files array so the API can identify the document associated with the type
        });
        formData.append("ApplicationDocuments", applicationData[dt.id][0]);
        countDoc++;
      }
    });

    formData.append("Application", JSON.stringify(application));
    return formData;
  }
  return applicationData;
}

export default createApplicationMap;
