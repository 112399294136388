import { getApplicationVersion, getWhoWeAre } from "crud/applicationconfiguration.crud";

import { AUTH, APPLICATION_CONFIGURATION_ACTIONS } from "utils/actionsConsts";

export const getApplicationVersionAction =
  (userType, date) => async (dispatch) => {
    dispatch({
      type: AUTH.GET_APPLICATION_VERSION_LOADING,
      payload: true,
    });
    getApplicationVersion(userType, date)
      .then((res) => {
        dispatch({
          type: "lastLogin",
          payload: {
            lastLoginDate: res.data.lastLoginDate,
            showNews: res.data.showNews,
            news: res.data.versionMessage,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: AUTH.GET_APPLICATION_VERSION_LOADING,
          payload: false,
        });
      });
  };

export const hideShowNewsAction = (date) => async (dispatch) => {
  dispatch({
    type: "lastLogin",
    payload: {
      lastLoginDate: date,
      showNews: false,
    },
  });
};

export const getWhoWeAreAction = () => async (dispatch) => {
    dispatch({
      type: APPLICATION_CONFIGURATION_ACTIONS.GET_WHO_WE_ARE_LOADING,
      payload: true,
    });
    getWhoWeAre()
      .then((res) => {
        dispatch({
          type: APPLICATION_CONFIGURATION_ACTIONS.GET_WHO_WE_ARE_LOADING,
          payload: false,
        });
        dispatch({
          type: APPLICATION_CONFIGURATION_ACTIONS.GET_WHO_WE_ARE,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch({
          type: APPLICATION_CONFIGURATION_ACTIONS.GET_WHO_WE_ARE_LOADING,
          payload: false,
        });
      });
};
