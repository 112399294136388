import { React, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FilterSection from "./Sections/InformationDissemination_FilterSection";
import ResultSection from "./Sections/InformationDissemination_ResultSection";
import { titles } from "resources/resources";
import LocationHeader from "components/Headers/LocationHeader";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function InformationDissemination(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Grid container classes={{ root: classes.containerRoot }}>
      <LocationHeader
        section="Publicações"
      />
      <Grid item xs={12} md={12}>
        <FilterSection
          page={page}
          handleChangePage={(value) => {
            handleChangePage(undefined, value);
          }}
          informationDisseminationType={props.match.params.infoType}
          title={intl.formatMessage(titles.Title_Posts)}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ResultSection page={page} handleChangePage={handleChangePage} />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(InformationDissemination);
