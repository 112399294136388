import { React, useEffect, useState } from "react";
import { connect } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Container from "@material-ui/core/Container";
import { ArrayUtils } from "utils/array_utils";
import { getOpportunityUsersAction } from "redux/actions/opportunitiesAction";
import Pagination from "@material-ui/lab/Pagination";
import { PAGINATION, DATASET, OPPORTUNITY_STATE_KEY } from "utils/const";

//custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import AddCandidates from "../Modals/AddCandidatesModal.js";
import CandidatesListSection from "../Sections/CandidatesListSection";
import componentStyles from "assets/theme/views/admin/generic-page-table-result.js";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStyles = makeStyles(componentStyles);

function OpportunityCandidatesTab(props) {
  const classes = { ...useStylesGeneric(), ...useStyles() };
  const [opportunityType, setOpportunityType] = useState();

  const [page, setPage] = useState(1);
  const [showAddCandidatesModal, setShowAddCandidatesModal] = useState(false);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const refreshResults = () => {
    let filter = {
      opportunityId: props.opportunityId,
      pageIndex: page,
      pageSize: PAGINATION.PAGE_SIZE,
    };
    props.getOpportunityUsers(filter);
  };

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.opportunitiesState.opportunityDetailsData?.opportunityCandidates)) {
      setOpportunityType(props.opportunitiesState.opportunityDetailsData?.opportunityTypeKey);
    } else {
      setOpportunityType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunitiesState.opportunityDetailsData?.opportunityCandidates]);

  useEffect(() => {
    if (props.datasetsState[DATASET.PROVINCES] === undefined || props.datasetsState[DATASET.PROVINCES]?.length === 0) {
      props.getDataSetByName(DATASET.PROVINCES);
    }

    if (
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY] === undefined ||
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]?.length === 0
    ) {
      props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenAddCandidatesModal = () => {
    setShowAddCandidatesModal(true);
  };

  const handleCloseAddCandidatesModal = () => {
    setShowAddCandidatesModal(false);
  };

  return (
    <Container maxWidth={false} component={Box} marginTop="0">
      <CardTableContainer
        title={"Candidatos"}
        count={props.opportunitiesState?.total_records}
        actionButtonLabel={
          props.opportunitiesState.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.APROVADA &&
          "Adicionar Candidatos"
        }
        actionButtonOnClick={
          props.opportunitiesState.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.APROVADA &&
          handleOpenAddCandidatesModal
        }
      >
        <CandidatesListSection opportunityId={props.opportunityId} opportunityType={opportunityType} />
        <Pagination
          className={classes.align}
          count={props.opportunitiesState?.total_pages}
          page={page}
          onChange={handleChangePage}
          color="primary"
          style={{ margin: "20px" }}
        ></Pagination>
      </CardTableContainer>

      {/* Add Candidates Modal */}
      <GenericDialog maxWidth={"xl"} open={showAddCandidatesModal} onClose={handleCloseAddCandidatesModal}>
        <AddCandidates
          opportunityDetailsData={props.opportunityDetailsData}
          handleClose={handleCloseAddCandidatesModal}
          onSuccess={refreshResults}
        />
      </GenericDialog>
    </Container>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getOpportunityUsers: (formData) => dispatch(getOpportunityUsersAction(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityCandidatesTab);
