/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { FormGroup, FormLabel } from "@material-ui/core";

import componentStyles from "assets/theme/views/admin/static-page.js";

import { getDataSetByNameAction } from "redux/actions/datasetsAction";

import { DATASET, CONTACTS } from "utils/const";
import { getDatasetStateByName } from "utils/datasets";

import FormSelect from "components/FormFields/FormSelect";
import LocationHeader from "components/Headers/LocationHeader";

import EmploymentCenter from "./EmploymentCenter";

import * as S from "./styles";
import Tabs from "components/Tabs/Tabs";
import TrainingCenter from "./TrainingCenter";

const useStyles = makeStyles(componentStyles);

export default function ContactsPage() {
  const theme = useTheme();
  const classes = useStyles();

  const [tab, setTab] = useState(1);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      province: null,
    },
  });

  useEffect(() => {
    dispatch(getDataSetByNameAction(DATASET.PROVINCES));
  }, [dispatch]);

  const provinces = useMemo(() => {
    return getDatasetStateByName(state, DATASET.PROVINCES);
  }, [state]);

  function onChangeTab(_, value) {
    setValue("province", null);
    setTab(value);
  }

  const tabs = [
    {
      text: "Centros de Emprego",
      testId: "employment-center-tab",
      value: 1,
      component: {
        props: {
          control,
        },
        wrapper: EmploymentCenter,
      },
    },
    {
      text: "Centros de Formação",
      testId: "training-center-tab",
      value: 2,
      component: {
        props: {
          control,
        },
        wrapper: TrainingCenter,
      },
    },
  ];

  return (
    <S.Container maxWidth={false} classes={{ root: classes.containerRoot }}>
      <Box marginLeft="-30px">
        <LocationHeader section="Contactos" />
      </Box>

      <Card className={classes.cardRoot}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexDirection: "column", gridGap: 8, marginBottom: 16 }}>
                <Box classes={{ root: classes.pageTitle }}>Contactos</Box>

                <Box color={theme.palette.black.main}>
                  <Box
                    component={PhoneIcon}
                    color={theme.palette.black.main}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    marginRight="1rem"
                  />
                  <span data-testid="contacts-phone" className={classes.phoneNumberGrey}>+244</span> <span>{CONTACTS.PHONE}</span>
                </Box>

                <Link className={classes.linkRoot} to={{ pathname: CONTACTS.MAIL_TO }} target="_blank" rel="noopener">
                  <Box component={EmailIcon} width="1.25rem!important" height="1.25rem!important" marginRight="1rem" />
                  <span data-testid="contacts-email">{CONTACTS.EMAIL}</span>
                </Link>

                <Link className={classes.linkRoot} to={{ pathname: CONTACTS.LOCATION }} target="_blank" rel="noopener">
                  <Box
                    component={LocationOnIcon}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    marginRight="1rem"
                  />
                  <span data-testid="contacts-address">{CONTACTS.ADDRESS}</span>
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <iframe
                data-testid="contacts-map"
                src={CONTACTS.MAP_URL}
                width="900"
                height="1600"
                allowFullScreen=""
                loading="lazy"
                className={classes.iframe}
                title={"mapsIFrame"}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box marginTop={4} />

      <S.FormContainer>
        <S.Form>
          <FormGroup>
            <FormLabel>Província</FormLabel>
            <FormSelect
              control={control}
              fieldName="province"
              selectOptions={provinces}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              classes={classes}
            />
          </FormGroup>
        </S.Form>

        <Tabs onChange={onChangeTab} step={tab} tabs={tabs} disableGutters />
      </S.FormContainer>

      <Grid container>{/* <TabRender useBox={false} tabs={tabs} step={tab} /> */}</Grid>
    </S.Container>
  );
}
