import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const GET_GOVERNMENT_PROGRAMS_URL = "GovernmentPrograms/get-governmentprograms";
export const GET_GOVERNMENT_PROGRAM_DETAILS_URL = "GovernmentPrograms/get-governmentprogram-details";

export function getGovernmentPrograms() {
  return axios.get(EXTERNAL_API_URL + GET_GOVERNMENT_PROGRAMS_URL);
}

export function getGovernmentProgramDetails(programId) {
  return axios.get(EXTERNAL_API_URL + GET_GOVERNMENT_PROGRAM_DETAILS_URL, {
    params: {
      id: programId,
    },
  });
}