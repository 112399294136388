import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function EmploymentCenters() {
  const intl = useIntl();

  const employmentCentersData = [
    {
      title: "",
      content: [
        intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_1) + "\n\n",
        intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_2) + "\n\n",
        intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_3) + "\n\n",
        intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_4) + "\n\n",
        intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Description_5),
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions),
      content: [
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_9
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Attributions_Description_1_BulletPoint_10
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing),
      content: [],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing2),
      content: [
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_Placing_Description_1_BulletPoint_9
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation
      ),
      content: [
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_InformationAndProfessionalOrientation_Description_1_BulletPoint_8
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_ProfessionalTraining_Description_2_BulletPoint_2
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion
      ),
      content: [
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EntrepreneurshipAndEmploymentPromotion_Description_1_BulletPoint_4
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies
      ),
      content: [
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_EmploymentMarketStudies_Description_1_BulletPoint_9
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_RENT),
      content: [
        intl.formatMessage(labels.Label_Menu_Information_EmploymentServices_EmploymentCenters_RENT_Description),
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(titles.Title_Menu_Information_EmploymentServices_EmploymentCenters)}
      section={intl.formatMessage(labels.Label_Menu_Information_EmploymentCenters)}
      data={employmentCentersData}
    />
  );
}

export default EmploymentCenters;
