import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
// @material-ui/icons components
import MenuIcon from "@material-ui/icons/Menu";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

// core components
import { checkOperationPermission } from "utils/permission";
import { OPERATIONS, INFORMATION_DISSEMINATION_TYPE_KEYVALUE } from "utils/const";
import { ArrayUtils } from "utils/array_utils";
import { getGovernmentProgramsAction } from "redux/actions/governmentProgramsAction";
import { dataForApplicationCreationAction } from "redux/actions/applicationsAction";
import { labels, titles } from "resources/resources";

import componentStyles from "assets/theme/components/dropdowns/navbar-menu-dropdown.js";

const useStyles = makeStyles(componentStyles);

function AdminNavbarMenuDropdown(props) {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const [openGovernmentProgramsMenu, setOpenGovernmentProgramsMenu] = useState(false);
  const [openEmploymentAndInternshipMenu, setOpenEmploymentAndInternshipMenu] = useState(false);
  const [openInformationDissemination, setOpenInformationDissemination] = useState(false);
  const [openInstitutionalInformation, setOpenInstitutionalInformation] = useState(false);
  const [openInformation, setOpenInformation] = useState(false);
  const [openInformationCompanyServices, setOpenInformationCompanyServices] = useState(false);
  const [openInformationCitizenServices, setOpenInformationCitizenServices] = useState(false);
  const [openInformationEmploymentServices, setOpenInformationEmploymentServices] = useState(false);
  const [openInformationEntrepreneurshipServices, setOpenInformationEntrepreneurshipServices] = useState(false);
  const [openInformationProfessionalTraining, setOpenInformationProfessionalTraining] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (ArrayUtils.NullOrEmpty(props?.governmentProgramsState?.getGovernmentPrograms_result))
      props.governmentPrograms();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.governmentProgramsState.getGovernmentPrograms_result]);

  const handleNavbarMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenGovernmentProgramsMenu(false);
    setOpenEmploymentAndInternshipMenu(false);
    setOpenInformationDissemination(false);
    setOpenInstitutionalInformation(false);
    setOpenInformation(false);
    setOpenInformationCompanyServices(false);
    setOpenInformationCitizenServices(false);
    setOpenInformationEmploymentServices(false);
    setOpenInformationEntrepreneurshipServices(false);
    setOpenInformationProfessionalTraining(false);
    setOpenHelp(false);
  };

  const handleClickGovernmentProgramsMenu = () => {
    setOpenGovernmentProgramsMenu(!openGovernmentProgramsMenu);
  };

  const handleClickEmploymentAndInternshipMenu = () => {
    setOpenEmploymentAndInternshipMenu(!openEmploymentAndInternshipMenu);
  };

  const changeData = (program) => {
    props.dataForApplicationCreation({
      governmentProgramId: program,
      programId: null,
    });
  };

  const handleClickInformationDissemination = () => {
    setOpenInformationDissemination(!openInformationDissemination);
  };

  const handleClickInstitutionalInformation = () => {
    setOpenInstitutionalInformation(!openInstitutionalInformation);
  };

  const handleClickInformation = () => {
    setOpenInformation(!openInformation);
  };

  const handleClickInformationCompanyServices = () => {
    setOpenInformationCompanyServices(!openInformationCompanyServices);
  };

  const handleClickInformationCitizenServices = () => {
    setOpenInformationCitizenServices(!openInformationCitizenServices);
  };

  const handleClickInformationEmploymentServices = () => {
    setOpenInformationEmploymentServices(!openInformationEmploymentServices);
  };

  const handleClickInformationEntrepreneurshipServices = () => {
    setOpenInformationEntrepreneurshipServices(!openInformationEntrepreneurshipServices);
  };

  const handleClickInformationProfessionalTraining = () => {
    setOpenInformationProfessionalTraining(!openInformationProfessionalTraining);
  };

  const handleClickHelp = () => {
    setOpenHelp(!openHelp);
  };

  const menuId = "dropdowns-navbar-menu-dropdown-id";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography variant="h5" component="h5" classes={{ root: classes.menuTitle }}>
        Menu
      </Typography>
      <List component="nav" aria-labelledby="navbar-menu-list" className={classes.menuRoot}>
        <>
          {/* INEFOP */}
          <>
            <ListItem button onClick={handleClickInstitutionalInformation} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(labels.Label_Menu_INEFOP)}
              />
              {openInstitutionalInformation ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openInstitutionalInformation} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/admin/institution-overview`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOverview)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/admin/institution-history`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionHistory)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/admin/institution-mission-vision-values`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionMissionVisionAndValues)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/admin/institution-organic-status`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOrganicStatus)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/admin/institution-organization-chart`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstitutionOrganizationChart)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/admin/director-biography`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_DirectorBiography)}
                  />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/admin/institute-holders`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_INEFOP_InstituteHolders)}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>

          {/* Apoios */}
          {checkOperationPermission(props.authState.operationsList, OPERATIONS.GOVERNMENT_PROGRAMS) && (
            <>
              <ListItem button onClick={handleClickGovernmentProgramsMenu} className={classes.listItemRoot}>
                <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary="Apoios" />
                {openGovernmentProgramsMenu ? (
                  <ExpandLessIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.expandIcon} />
                )}
              </ListItem>
              <Collapse in={openGovernmentProgramsMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.APPLICATIONS_CREATE_ITEM) && [
                    props?.governmentProgramsState?.getGovernmentPrograms_result?.map((program, key) => (
                      <ListItem
                        key={key}
                        button
                        onClick={() => {
                          handleMenuClose();
                          changeData(program.id);
                          history.push("/admin/application-create");
                        }}
                        className={classes.nestedItem}
                      >
                        <FiberManualRecordIcon className={classes.dotIcon} />
                        <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary={program.value} />
                      </ListItem>
                    )),
                  ]}
                </List>
              </Collapse>
            </>
          )}

          {/* Emprego e Estágio  */}
          {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITY_USER_COLECTIVE_MENU) && (
            <>
              <ListItem button onClick={handleClickEmploymentAndInternshipMenu} className={classes.listItemRoot}>
                <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary="Emprego e Estágio" />
                {openEmploymentAndInternshipMenu ? (
                  <ExpandLessIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.expandIcon} />
                )}
              </ListItem>
              <Collapse in={openEmploymentAndInternshipMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {checkOperationPermission(
                    props.authState.operationsList,
                    OPERATIONS.OPPORTUNITY_USER_COLECTIVE_OPPORTUNITIES_LIST_ITEM
                  ) && (
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push("/admin/opportunities");
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary="Lista de Ofertas" />
                    </ListItem>
                  )}
                  {checkOperationPermission(
                    props.authState.operationsList,
                    OPERATIONS.OPPORTUNITY_USER_COLECTIVE_APPLICATIONS_LIST_ITEM
                  ) && (
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push("/admin/user-colective-opportunity-applications");
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary="Lista de Candidaturas"
                      />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}
          {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITY_USER_SINGULAR_MENU) && (
            <>
              <ListItem button onClick={handleClickEmploymentAndInternshipMenu} className={classes.listItemRoot}>
                <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary="Emprego e Estágio" />
                {openEmploymentAndInternshipMenu ? (
                  <ExpandLessIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.expandIcon} />
                )}
              </ListItem>
              <Collapse in={openEmploymentAndInternshipMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {checkOperationPermission(
                    props.authState.operationsList,
                    OPERATIONS.OPPORTUNITY_USER_SINGULAR_OPPORTUNITIES_LIST_ITEM
                  ) && (
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push("/admin/available-opportunities");
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary="Lista de Ofertas" />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {/* Formação Profissional */}
          {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_TRAINING_MENU) && (
            <ListItem
              button
              onClick={() => {
                handleMenuClose();
                history.push("/formacao/");
              }}
              className={classes.listItemRoot}
            >
              <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary="Formação Profissional" />
            </ListItem>
          )}

          {/* INFORMAÇÃO */}
          <>
            <ListItem button onClick={handleClickInformation} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(labels.Label_Menu_Information)}
              />
              {openInformation ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openInformation} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* Submenu Group: Company Services */}
                {checkOperationPermission(
                  props.authState.operationsList,
                  OPERATIONS.OPPORTUNITY_USER_COLECTIVE_MENU
                ) && (
                  <>
                    <ListItem button onClick={handleClickInformationCompanyServices} className={classes.nestedItem}>
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_CompanyServices)}
                      />
                      {openInformationCompanyServices ? (
                        <ExpandLessIcon className={classes.expandIcon} />
                      ) : (
                        <ExpandMoreIcon className={classes.expandIcon} />
                      )}
                    </ListItem>
                    <Collapse in={openInformationCompanyServices} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => {
                            handleMenuClose();
                            history.push(`/admin/opportunities-create`);
                          }}
                          className={classes.nestedItem}
                        >
                          <FiberManualRecordIcon className={classes.dotIcon} />
                          <ListItemText
                            classes={{ primary: classes.listItemTextPrimary }}
                            primary={intl.formatMessage(
                              titles.Title_Menu_Information_CompanyServices_CreateEmploymentOrInternshipOpportunities
                            )}
                          />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            handleMenuClose();
                            history.push(`/admin/ratify-agreement`);
                          }}
                          className={classes.nestedItem}
                        >
                          <FiberManualRecordIcon className={classes.dotIcon} />
                          <ListItemText
                            classes={{ primary: classes.listItemTextPrimary }}
                            primary={intl.formatMessage(titles.Title_Menu_Information_CompanyServices_RatifyAgreement)}
                          />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            handleMenuClose();
                            history.push(`/admin/information-training-centers-licensing`);
                          }}
                          className={classes.nestedItem}
                        >
                          <FiberManualRecordIcon className={classes.dotIcon} />
                          <ListItemText
                            classes={{ primary: classes.listItemTextPrimary }}
                            primary={intl.formatMessage(titles.Title_Menu_Information_CompanyServices_LicensingCenters)}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </>
                )}

                {/* Submenu Group: Citizen Services */}
                {checkOperationPermission(
                  props.authState.operationsList,
                  OPERATIONS.OPPORTUNITY_USER_SINGULAR_MENU
                ) && (
                  <>
                    <ListItem button onClick={handleClickInformationCitizenServices} className={classes.nestedItem}>
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_CitizenServices)}
                      />
                      {openInformationCitizenServices ? (
                        <ExpandLessIcon className={classes.expandIcon} />
                      ) : (
                        <ExpandMoreIcon className={classes.expandIcon} />
                      )}
                    </ListItem>
                    <Collapse in={openInformationCitizenServices} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => {
                            handleMenuClose();
                            history.push("/admin/available-opportunities");
                          }}
                          className={classes.nestedItem}
                        >
                          <FiberManualRecordIcon className={classes.dotIcon} />
                          <ListItemText
                            classes={{ primary: classes.listItemTextPrimary }}
                            primary={intl.formatMessage(
                              titles.Title_Menu_Information_CitizenServices_SeeEmploymentOpportunities
                            )}
                          />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            window.location.href = "/formacao";
                            handleMenuClose();
                          }}
                          className={classes.nestedItem}
                        >
                          <FiberManualRecordIcon className={classes.dotIcon} />
                          <ListItemText
                            classes={{ primary: classes.listItemTextPrimary }}
                            primary={intl.formatMessage(
                              titles.Title_Menu_Information_CitizenServices_SeeProfessionalTraining
                            )}
                          />
                        </ListItem>
                        <ListItem
                          button
                          onClick={() => {
                            window.open("http://procarteira.inefop.gov.ao/", "_blank", "noreferrer");
                            handleMenuClose();
                          }}
                          className={classes.nestedItem}
                        >
                          <FiberManualRecordIcon className={classes.dotIcon} />
                          <ListItemText
                            classes={{ primary: classes.listItemTextPrimary }}
                            primary={intl.formatMessage(
                              titles.Title_Menu_Information_CitizenServices_SeeProfessionalWallet
                            )}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </>
                )}

                {/* Submenu Group: Employment Services */}
                <ListItem button onClick={handleClickInformationEmploymentServices} className={classes.nestedItem}>
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Information_EmploymentServices)}
                  />
                  {openInformationEmploymentServices ? (
                    <ExpandLessIcon className={classes.expandIcon} />
                  ) : (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )}
                </ListItem>
                <Collapse in={openInformationEmploymentServices} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/admin/information-employment-centers`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_EmploymentCenters)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/admin/information-employment-centers-network`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_EmploymentCentersNetwork)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/admin/information-foreign-workers-contract-registration`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_ForeignWorkersContractRegistration)}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                {/* Submenu Group: Entrepreneurship Services */}
                <ListItem
                  button
                  onClick={handleClickInformationEntrepreneurshipServices}
                  className={classes.nestedItem}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipServices)}
                  />
                  {openInformationEntrepreneurshipServices ? (
                    <ExpandLessIcon className={classes.expandIcon} />
                  ) : (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )}
                </ListItem>
                <Collapse in={openInformationEntrepreneurshipServices} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/admin/information-entrepreneurship-centers`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipCenters)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/admin/information-entrepreneurship-centers-network`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_EntrepreneurshipCentersNetwork)}
                      />
                    </ListItem>
                  </List>
                </Collapse>
                {/* Submenu Group: Professional Training */}
                <ListItem
                  data-testid="admin-navbar-dropdown-submenu-professional-training"
                  button
                  onClick={handleClickInformationProfessionalTraining}
                  className={classes.nestedItem}
                >
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Information_ProfessionalTraining)}
                  />
                  {openInformationProfessionalTraining ? (
                    <ExpandLessIcon className={classes.expandIcon} />
                  ) : (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  )}
                </ListItem>
                <Collapse in={openInformationProfessionalTraining} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/admin/information-professional-wallet`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_ProfessionalWallet)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/admin/information-formation-offer`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_FormationOffer)}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        handleMenuClose();
                        history.push(`/admin/information-training-centers-licensing`);
                      }}
                      className={classes.nestedItem}
                    >
                      <FiberManualRecordIcon className={classes.dotIcon} />
                      <ListItemText
                        classes={{ primary: classes.listItemTextPrimary }}
                        primary={intl.formatMessage(labels.Label_Menu_Information_TrainingCentersLicensing)}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>
          </>

          {/* Projetos Estruturantes */}
          <ListItem
            data-testid="admin-navbar-dropdown-structuring-projects"
            button
            onClick={() => {
              handleMenuClose();
              history.push("/admin/structuringProjects");
            }}
            className={classes.listItemRoot}
          >
            <ListItemText
              classes={{ primary: classes.listItemTextPrimary }}
              primary={intl.formatMessage(labels.Label_Menu_StructuringProjects)}
            />
          </ListItem>

          {/* Publicações */}
          {checkOperationPermission(props.authState.operationsList, OPERATIONS.INFORMATION_DISSEMINATION_MENU) && (
            <>
              <ListItem button onClick={handleClickInformationDissemination} className={classes.listItemRoot}>
                <ListItemText
                  classes={{ primary: classes.listItemTextPrimary }}
                  primary={intl.formatMessage(labels.Label_Menu_Posts)}
                />
                {openInformationDissemination ? (
                  <ExpandLessIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.expandIcon} />
                )}
              </ListItem>
              <Collapse in={openInformationDissemination} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push(`/admin/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.NEWS}`);
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(labels.Label_Menu_Posts_News)}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push(
                        `/admin/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.LEGISLATION}`
                      );
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(labels.Label_Menu_Posts_Legislations)}
                    />
                  </ListItem>

                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push(
                        `/admin/informationDissemination/${INFORMATION_DISSEMINATION_TYPE_KEYVALUE.SCHEDULE}`
                      );
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(labels.Label_Menu_Posts_Schedule)}
                    />
                  </ListItem>
                </List>
              </Collapse>
            </>
          )}

          {/* Bolsa Nacional de Formadores */}
          {/* <ListItem
            button
            onClick={() => {
              handleMenuClose();
              history.push("/admin/national-scholarship-of-trainers");
            }}
            className={classes.listItemRoot}
          >
            <ListItemText
              classes={{ primary: classes.listItemTextPrimary }}
              primary={intl.formatMessage(labels.Label_Menu_Help_NationalScholarshipTrainers)}
            />
          </ListItem> */}

          {/* Contactos */}
          {checkOperationPermission(props.authState.operationsList, OPERATIONS.CONTACTS_MENU) && (
            <ListItem
              button
              onClick={() => {
                handleMenuClose();
                history.push("/admin/contacts");
              }}
              className={classes.listItemRoot}
            >
              <ListItemText classes={{ primary: classes.listItemTextPrimary }} primary="Contactos" />
            </ListItem>
          )}

          {/* Ajuda */}
          <>
            <ListItem button onClick={handleClickHelp} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(labels.Label_Menu_Help)}
              />
              {openHelp ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openHelp} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/admin/frequently-asked-questions`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Help_FAQs)}
                  />
                </ListItem>

                <ListItem
                  button
                  onClick={() => {
                    handleMenuClose();
                    history.push(`/admin/useful-links`);
                  }}
                  className={classes.nestedItem}
                >
                  <FiberManualRecordIcon className={classes.dotIcon} />
                  <ListItemText
                    classes={{ primary: classes.listItemTextPrimary }}
                    primary={intl.formatMessage(labels.Label_Menu_Help_UsefulLinks)}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>
        </>
      </List>
    </Menu>
  );

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" marginLeft="auto">
        <IconButton onClick={handleNavbarMenuOpen} className={classes.menuButton}>
          <Box
            component={MenuIcon}
            color={theme.palette.dark.main}
            width="1.5rem!important"
            height="1.5rem!important"
          />
          <Box
            fontStyle="normal"
            fontSize=".875rem"
            fontWeight="600"
            alignContent="center"
            paddingLeft="0.1rem"
            paddingRight="0.1rem"
            paddingTop="0.5rem"
            paddingBottom="0.5rem"
            margin="0"
          >
            Menu
          </Box>
        </IconButton>
        {renderMenu}
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  governmentPrograms: () => dispatch(getGovernmentProgramsAction()),
  dataForApplicationCreation: (data) => dispatch(dataForApplicationCreationAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbarMenuDropdown);
