import { React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Card, CardHeader, CardContent } from "@material-ui/core";
// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
// components
import GenericButton from "components/Buttons/GenericButton";
//import custom components
import FormSelect from "components/FormFields/FormSelect";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { getUserColectiveOpportunityApplicationsAction } from "redux/actions/opportunitiesAction";
import { getUserColectiveOpportunityApplicationsMap } from "mappers/GetFilteredOpportunitiesMap";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { PAGINATION, DATASET, OPPORTUNITY_TYPE_KEY } from "utils/const";
import { getDatasetStateByName } from "utils/datasets";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityApplicationsFilterSection(props) {
  const classes = useStylesGeneric();
  const [filter, setFilter] = useState({
    applicationCode: null,
    opportunityType: null,
    searchText: null,
    applicationState: null,
    opportunityReference: null,
    userNameOrIdentityCard: null,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE,
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (
      props.datasetsState[DATASET.OPPORTUNITY_TYPE] === undefined ||
      props.datasetsState[DATASET.OPPORTUNITY_TYPE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_TYPE);
    }
    if (
      props.datasetsState[DATASET.OPPORTUNITY_CANDIDATE_STATE] === undefined ||
      props.datasetsState[DATASET.OPPORTUNITY_CANDIDATE_STATE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_CANDIDATE_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.match?.params?.opportunityType === OPPORTUNITY_TYPE_KEY.EMPREGO_URL) {
      setValue("opportunityType", { code: 1, label: "Emprego", keyValue: OPPORTUNITY_TYPE_KEY.EMPREGO });
    }
    if (props.match?.params?.opportunityType === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL_URL) {
      setValue("opportunityType", {
        code: 2,
        label: "Estágio Profissional",
        keyValue: OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL,
      });
    }
    handleSubmit(onSubmit)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match?.params?.opportunityType]);

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  const cleanFilter = () => {
    setValue("applicationCode", "");
    setValue("opportunityType", undefined);
    setValue("searchText", "");
    setValue("applicationState", undefined);
    setValue("opportunityReference", "");
    setValue("userNameOrIdentityCard", "");

    setFilter(0);
  };

  useEffect(() => {
    refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  // Refresh Results
  const refreshResults = () => {
    props.getUserColectiveOpportunityApplications({ ...filter, pageIndex: props.page <= 0 ? 1 : props.page });
  };

  const onSubmit = (data) => {
    let filter = getUserColectiveOpportunityApplicationsMap(data);
    setFilter(filter);
    if (props.page === PAGINATION.FIRST_PAGE) {
      props.getUserColectiveOpportunityApplications(filter);
    } else {
      props.handleChangePage(PAGINATION.FIRST_PAGE);
    }
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={"Pesquisa de Candidaturas"}
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Nº Candidatura</FormLabel>
                  <Controller
                    name="applicationCode"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("applicationCode", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Estado</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="applicationState"
                    selectOptions={getDatasetStateByName(props, DATASET.OPPORTUNITY_CANDIDATE_STATE)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"applicationState"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Nº Oferta</FormLabel>
                  <Controller
                    name="opportunityReference"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("opportunityReference", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Tipo de Oferta</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="opportunityType"
                    selectOptions={getDatasetStateByName(props, DATASET.OPPORTUNITY_TYPE)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"opportunityType"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Pesquisa (título/descrição)</FormLabel>
                  <Controller
                    name="searchText"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("searchText", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Nome ou Nº BI</FormLabel>
                  <Controller
                    name="userNameOrIdentityCard"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("userNameOrIdentityCard", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>

              {/* Botões de Pesquisa */}

              <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  Limpar Filtros
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.opportunitiesState?.getUserColectiveOpportunityApplications_loading}
                >
                  Pesquisar
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUserColectiveOpportunityApplications: (data) => dispatch(getUserColectiveOpportunityApplicationsAction(data)),
  getDataSetByName: (data, parentKeyValue) => dispatch(getDataSetByNameAction(data, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OpportunityApplicationsFilterSection));
