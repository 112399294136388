import React, { useEffect } from "react";
import { connect } from "react-redux";

import MainProjects from "./Sections/MainProjects";
import LastPosts from "./Sections/LastPosts";
import AllProjects from "./Sections/AllProjects";
import Services from "./Sections/Services";
import Partnerships from "./Sections/Partnerships";
import Dashboard from "views/admin/Dashboards/Dashboard";
import WhoWeAre from "./Sections/WhoWeAre";
import InefopDirectorMessage from "./Sections/InefopDirectorMessage";
import Statistics from "./Sections/Statistics";
import { getStructuringProjectsAction } from "redux/actions/informationDisseminationAction";
import { getWhoWeAreAction } from "redux/actions/applicationConfigurationAction";
import {
  INFORMATION_DISSEMINATION_TYPE_KEYVALUE,
  PAGINATION,
  APP_CONFIG_KEYVALUES,
} from "utils/const";

function LandingPage(props) {
  useEffect(() => {
    let filter = {
      searchText: "",
      searchPublishPeriodKeyValue: undefined,
      informationDisseminationTypeKeyValueList: [
        INFORMATION_DISSEMINATION_TYPE_KEYVALUE.STRUCTURING_PROJECT,
      ],
      isPublic:
        props.authState.loggedIn !== undefined && props.authState.loggedIn
          ? null
          : true,
      published: true,
      isHighlighted: null,
      pageIndex: 1,
      pageSize: PAGINATION.PAGE_SIZE_STRUCTURING_PROJECTS,
    };

    props.getStructuringProjects(filter);

    props.getWhoWeAre();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWhoWeAreElementDescriptionBasedOnKeyValue = (keyValue) => {
    return props.applicationConfigurationState?.whoWeAre_result?.find(
      (element) => element.keyValue === keyValue
    )?.description;
  };

  return (
    <div style={{ marginTop: "100px" }}>
      {!props.isLoggedIn && (
        <MainProjects
          structuringProjects={
            props.informationDisseminationState?.structuringProjects_result
              ?.data
          }
        />
      )}
      <Services isLoggedIn={props.isLoggedIn} userType={props.userType} />
      {props.isLoggedIn && <Dashboard />}
      <LastPosts />
      <WhoWeAre
        inefopSummary={getWhoWeAreElementDescriptionBasedOnKeyValue(
          APP_CONFIG_KEYVALUES.INEFOP_SUMMARY
        )}
      />
      <Statistics />
      <AllProjects
        structuringProjects={
          props.informationDisseminationState?.structuringProjects_result?.data
        }
      ></AllProjects>
      <InefopDirectorMessage
        inefopDirectorMessage={getWhoWeAreElementDescriptionBasedOnKeyValue(
          APP_CONFIG_KEYVALUES.INEFOP_DIRECTOR_MESSAGE
        )}
      />
      <Partnerships />
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getStructuringProjects: (data) =>
    dispatch(getStructuringProjectsAction(data)),
  getWhoWeAre: () => dispatch(getWhoWeAreAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
