import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Carousel from "react-material-ui-carousel";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesCarousel from "assets/theme/components/genericPage/carouselMediaPreviewSection";

const useStylesCarousel = makeStyles(componentStylesCarousel);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function MainProjectsPreviewCaroussel(props) {
  const theme = useTheme();
  const history = useHistory();
  const classes = { ...useStylesGeneric(), ...useStylesCarousel() };

  const computeHeight = () => {
    let maxHeight = -1;
    props.mediaList.forEach((image) => {
      let img = new Image();
      img.src = image.photo;

      let imgHeightOnScreen = (img.height / img.width) * window.innerWidth;
      if (isNaN(imgHeightOnScreen)) imgHeightOnScreen = -1;

      maxHeight = maxHeight < imgHeightOnScreen ? imgHeightOnScreen : maxHeight;
    });

    return maxHeight;
  };

  useEffect(() => {
    computeHeight();
    //eslint-disable-next-line
  }, []);

  const [height, setHeight] = useState(computeHeight());

  let TO;
  const handleWindowResize = () => {
    clearTimeout(TO);
    TO = setTimeout(() => setHeight(computeHeight()), 200);
  };
  window.addEventListener("resize", handleWindowResize);

  const getCurHeight = () => {
    if (window.innerWidth > theme.breakpoints.values.sm) return "60vh";
    return height <= 0 ? "30vh" : height;
  };

  return (
    <>
      {props.mediaList !== undefined &&
        props.mediaList !== null &&
        props.mediaList.length > 0 && (
          <Carousel
            autoPlay={true}
            interval={6000}
            cycleNavigation={true}
            height={getCurHeight()}
            navButtonsAlwaysVisible={true}
            navButtonsProps={{
              style: {
                border: "1px solid" + theme.palette.white.main,
                color: theme.palette.white.main,
                background: theme.palette.transparent.main,
              },
            }}
            indicatorIconButtonProps={{
              style: {
                padding: "2px",
                marginTop: "-80px",
                zIndex: "1",
                color: theme.palette.white.main,
              },
            }}
            IndicatorIcon={
              <FiberManualRecordIcon className={classes.indicatorIcon} />
            }
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: theme.palette.white.main,
              },
            }}
            indicatorContainerProps={{
              style: {},
            }}
          >
            {props.mediaList.map((mediaItem, key) => (
              <img
                src={mediaItem.photo}
                alt={mediaItem.title}
                className={classes.img + " " + classes.fullCoverageImg}
                key={key}
                onClick={() => {
                  history.push(props.mediaDetailsPath + mediaItem.alternateKey);
                }}
              />
            ))}
          </Carousel>
        )}
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainProjectsPreviewCaroussel);
