import { React } from "react";
import { connect } from "react-redux";
//@material-ui/core
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
//custom components
import { approveOpportunityCandidateAction } from "redux/actions/opportunitiesAction";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";

//styles
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ApproveCandidateDialog(props) {
  const classes = useStylesGeneric() ;
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit
  } = methods;

  const handleClose = () => {
    props.onClose();
  };
  const onSubmitApprove = () => {
    let filter = {
      candidateId: props.candidateId,
      opportunityId: props.opportunityId,
    };
    props.approveOpportunityCandidate(filter, props.opportunitiesState.isOpportunityApplication);
  };

  return (
    <GenericDialog name={props.name} open={props.open} onClose={props.onClose}>
      <Box className={classes.helpIconBox}>
        {props.overlappingInternship ? (
          <ErrorOutlineOutlinedIcon className={classes.helpIconRoot} />
        ) : (
          <HelpOutlineIcon className={classes.helpIconRoot} />
        )}
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitApprove)}>
          <Box className={classes.modalHeader} marginBottom="30px!important">
            {props.overlappingInternship
              ? "O período previsto de estágio da oferta atual sobrepõe o de um estágio profissional já atribuído ao candidato. Tem a certeza que pretende recrutar o candidato?"
              : "Confirma que pretende recrutar o candidato?"}
          </Box>
          <Grid container classes={{ root: classes.containerButtons }}>
            <Grid item xs={12} sm={1} md={1}></Grid>
            <Grid item xs={12} sm={4} md={5}>
              <FormGroup>
                <GenericButton 
                  typeSubmit={false}
                  color="secondary"
                  onClick={() => handleClose()}
                >
                  Não
                </GenericButton>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4} md={5}>
              <FormGroup>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  onClick={() => {
                    onSubmitApprove();
                  }}
                  disabled={props.opportunitiesState.approveCandidate_loading}
                  loading={props.opportunitiesState.approveCandidate_loading}
                >
                  Sim
                </GenericButton>
              </FormGroup>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  approveOpportunityCandidate: (formData, isOpportunityApplication) =>
    dispatch(approveOpportunityCandidateAction(formData, isOpportunityApplication)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveCandidateDialog);
