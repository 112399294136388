import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  FormGroup
} from "@material-ui/core";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import OpportunityData from "../../../Components/Opportunities/OpportunityData";
import Alert from "@material-ui/lab/Alert";

import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import {
  updateOpportunityStateAction,
  getOpportunitiesDetailsAction,
  updateOpportunityAction,
} from "redux/actions/opportunitiesAction";
import { updateOpportunityMap } from "mappers/OpportunityMap";
import UpdateOpportunityStateDialog from "../Dialogs/UpdateOpportunityStateDialog";
import {
  OPPORTUNITY_STATE_KEY,
  OPPORTUNITY_TYPE_KEY,
  UPDATE_OPPORTUNITY_STATE_ACTION_TYPE,
  OPPORTUNITY_MOTIVES_TYPE_KEY,
} from "utils/const";
import { DATASET } from "utils/const";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityDetailsTab(props) {
  const history = useHistory();
  const classes = useStylesGeneric();
  const [opportunityQuestionsState, setOpportunityQuestionsState] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [opportunityStateData, setopportunityStateData] = useState();
  const [dialogs, setDialogs] = useState(false);
  const DIALOG = { SHOW: true, HIDE: false };

  const methods = useForm({
    mode: "onChange",
  });

  const cancelOnClick = () => {
    history.goBack();
  };

  const showHideDialog = (op) => {
    setDialogs(op);
  };

  function handleLog() {
    console.log(methods.formState.errors);
  }

  useEffect(() => {
    if (props.opportunityDetailsData) {
      if (props.opportunityDetailsData.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL) {
        methods.setValue("contractType", undefined);
        methods.unregister(["contractType"]);
      }
      if (props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO) {
        methods.setValue("mentorName", "");
        methods.setValue("mentorMobilePhone", "");
        methods.setValue("internshipType", undefined);

        methods.unregister(["mentorName", "mentorMobilePhone", "internshipType"]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunityDetailsData]);

  function onSubmit(data) {
    data["alternateKey"] = props.opportunityId;
    data["opportunityTypeId"] = props.opportunityDetailsData?.opportunityTypeId;
    data["opportunityTypeKey"] = props.opportunityDetailsData?.opportunityTypeKey;

    let updateData = updateOpportunityMap(
      data,
      opportunityQuestionsState,
      props.datasetsState[DATASET.OPPORTUNITY_DOCUMENT_TYPE]
    );

    props.updateOpportunity(updateData);
  }

  const handleEditSection = () => {
    setIsEditable(true);
  };

  const handleCloseButton = () => {
    setopportunityStateData({
      id: props.opportunityDetailsData?.id,
      actionType: UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.CLOSE,
      motivesType: OPPORTUNITY_MOTIVES_TYPE_KEY.CLOSE,
    });
    setDialogs(true);
  };

  return (
    <>
      <Container maxWidth={false}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <OpportunityData
              setOpportunityQuestionsState={setOpportunityQuestionsState}
              opportunityQuestionsState={opportunityQuestionsState}
              opportunityDetailsData={props.opportunityDetailsData}
              isEditable={isEditable}
              handleEditSection={handleEditSection}
            />

            <Grid container>
              <Grid item 
                xs={12} 
                lg={
                  (!isEditable && 
                    !(props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO &&
                      (props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.APROVADA ||
                      props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.REJEITADA))
                    &&
                    !(props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL &&
                      props.opportunityDetailsData?.internshipTypeId === 1 &&
                      props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.FECHADA)
                    && 
                    props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.SUBMETIDA
                  )
                  ? 9
                  : 6} 
                xl={
                  (!isEditable && 
                    !(props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO &&
                      (props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.APROVADA ||
                      props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.REJEITADA))
                    &&
                    !(props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL &&
                      props.opportunityDetailsData?.internshipTypeId === 1 &&
                      props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.FECHADA)
                    && 
                    props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.SUBMETIDA
                  )
                  ? 9
                  : 6} 
              ></Grid>
              <Grid item xs={12} lg={3} xl={3}>
                <FormGroup>
                  <GenericButton 
                    typeSubmit={false}
                    color="secondary"
                    onClick={() => cancelOnClick()}
                  >
                    Cancelar
                  </GenericButton>
                </FormGroup>
              </Grid>
              <Grid item xs={12} lg={3} xl={3}>
              <FormGroup classes={{ root: classes.saveButtonFormGroup }}>
                  {!isEditable && 
                    (props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO &&
                      (props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.APROVADA ||
                      props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.REJEITADA)) 
                      ? (
                      <GenericButton 
                        typeSubmit={false}
                        color="primary"
                        disabled={false} 
                        onClick={() => handleCloseButton()}
                      >
                        Fechar Emprego
                      </GenericButton>
                    ): (
                      !isEditable &&
                        props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL &&
                        props.opportunityDetailsData?.internshipTypeId === 1 &&
                        props.opportunityDetailsData?.opportunityStateKey !== OPPORTUNITY_STATE_KEY.FECHADA 
                        ? (
                          <GenericButton 
                            typeSubmit={false}
                            color="primary"
                            disabled={false} 
                            onClick={() => handleCloseButton()}
                          >
                            Fechar Estágio
                          </GenericButton>
                        ): (
                          props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.SUBMETIDA &&
                          <GenericButton 
                            typeSubmit={true}
                            color="primary"
                            disabled={props.opportunitiesState.opportunityUpdateLoading || !isEditable}
                            loading={props.opportunitiesState.opportunityUpdateLoading}
                            onClick={() => handleLog()}
                          >
                            Guardar
                          </GenericButton>
                        )
                    )
                  }
                </FormGroup>
              </Grid>
            </Grid>
            {props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.SUBMETIDA && (
              <Grid container>
                <Grid item xs={12}>
                  <Alert severity="info">
                    {
                      "Aviso: Esta oferta será validada pelo INEFOP antes de ficar disponível para o público em geral."
                    }
                  </Alert>
                </Grid>
              </Grid>
            )}
            {props.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.APROVADA && (
              <Grid container>
                <Grid item xs={12}>
                  <Alert severity="info">
                    {
                      "Aviso: Esta oferta já se encontra aprovada e publicada. Caso pretenda efetuar alterações, deverá contactar o INEFOP."
                    }
                  </Alert>
                </Grid>
              </Grid>
            )}
          </form>
        </FormProvider>
      </Container>
      <UpdateOpportunityStateDialog
        name="update_opportunity-state-dialog"
        open={dialogs}
        history={history}
        data={opportunityStateData}
        onClose={() => showHideDialog(DIALOG.HIDE)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),

  updateOpportunityState: (data, onCloseMethod) => dispatch(updateOpportunityStateAction(data, onCloseMethod)),

  getOpportunityDetails: (opportunityId) => dispatch(getOpportunitiesDetailsAction(opportunityId)),
  updateOpportunity: (data) => dispatch(updateOpportunityAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetailsTab);
