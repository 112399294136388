import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import AngolaINEFOP from "../../../assets/img/backgroundImages/AngolaINEFOP.png"; //Designed by Wirestock / Freepik: http://www.freepik.com
import { getInformationTypeChipColor } from "utils/getChipColors";

// core components
import componentStyles from "assets/theme/views/admin/card-text-image";
import componentStylesLandingPage from "assets/theme/components/landingPage/landing-page.js";

const useStyles = makeStyles(componentStyles);
const useStylesLandingPage = makeStyles(componentStylesLandingPage);

function LastPostsCard(props) {
  const classes = { ...useStyles(), ...useStylesLandingPage() };
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

  useEffect(() => {
    if (props.authState?.loggedIn !== undefined && props.authState.loggedIn) setUserIsAuthenticated(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoItem = props.item;

  return (
    <div style={{marginTop: "-60px", marginBottom: "-20px"}}>
      <Tooltip
        title={infoItem.title}
        placement="bottom"
        arrow
        interactive
        enterDelay={300}
        enterNextDelay={300}
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      >
        <Typography variant="h3" className={classes.containerCardTitle + " " + classes.oneLineEllipsis}>
          {infoItem.title}
        </Typography>
      </Tooltip>

      <div>      
        <Button
          className={classes.containerCardTextImg}
          style={{
            backgroundImage: infoItem.photo ? `url('${infoItem.photo}')` : `url(${AngolaINEFOP})`, 
            backgroundSize: "cover", 
            backgroundPosition: "center center",
            height: "200px",
            width: "100%"
          }}
          component={Link}
          to={
            userIsAuthenticated
              ? `/admin/informationDissemination-details/${infoItem.alternateKey}`
              : `/auth/informationDissemination-details/${infoItem.alternateKey}`
          }
        >
          <div
            style={{
              backgroundColor: getInformationTypeChipColor(infoItem.informationDisseminationTypeKeyValue),
              position: "absolute",
              bottom: "20px", 
              padding: "5px 10px",
              fontSize: "10px",
            }}
            className={classes.informationTypeChipRoot}
          >
            {infoItem.informationDisseminationTypeDescription}
          </div>
        </Button>
      </div>
      <div>
        {infoItem.publishedOn}
      </div>
      <div className={classes.doubleLineTitleEllipsis}>
        {ReactHtmlParser(infoItem.description)}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(LastPostsCard);
