import axios from 'axios';

import { EXTERNAL_API_URL } from '../';

const GET_EMPLOYMENT_CENTERS = 'contacts/get-employment-centers';
const GET_TRAINING_CENTERS = 'contacts/get-training-centers';

export const getEmploymentCentersContacts = async (body) => {
  const response = await axios.post(`${EXTERNAL_API_URL}${GET_EMPLOYMENT_CENTERS}`, body);
  
  return response.data;
}

export const getTrainingCentersContacts = async (body) => {
  const response = await axios.post(`${EXTERNAL_API_URL}${GET_TRAINING_CENTERS}`, body);
  
  return response.data;
}