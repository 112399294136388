const componentStyles = (theme) => ({
  photoItemDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
    backgroundColor: theme.palette.white.smoke,
  },

  formVerificationInputContainer: {
    width: "330px",
    [theme.breakpoints.down("sm")]: {
      width: "220px",
    },
  },

  formVerificationInputCharacter: {
    border: "none",
    borderBottom: "2px solid " + theme.palette.gray[200],
    color: theme.palette.primary.greyBlue,
  },

  formVerificationInputInactiveCharacter: {
    background: theme.palette.white.main,
    color: theme.palette.gray[300],
  },

  formVerificationInputSelectedCharacter: {
    outline: "none !important",
    borderColor: theme.palette.orange[200],
  },

  formVerificationInputErrorMessage: {
    marginTop: "10px",
  },
});

export default componentStyles;
