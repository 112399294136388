import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function EntrepreneurshipCentersNetwork() {
  const intl = useIntl();

  const entrepeneurshipCentersNetwork = [
    {
      title: "",
      content: [
        {
          type: DISPLAY_DATA_CONTENT_TYPE.IMAGE,
          path: "/images/information/EntrepeneurshipCentersNetwork.png",
          title: "",
        },
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(
        titles.Title_Menu_Information_EntrepreneurshipServices_EntrepreneurshipCentersNetwork
      )}
      data={entrepeneurshipCentersNetwork}
    />
  );
}

export default EntrepreneurshipCentersNetwork;
