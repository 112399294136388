// import getListAsString from "utils/formSelectMultiple";
import moment from "moment";

export function userProfilePersonalUpdateMap(formData, documentTypes, file) {
  console.log(
    "🚀 ~ file: UserProfilePersonalUpdateMap.js ~ line 4 ~ userProfilePersonalUpdateMap ~ formData",
    formData
  );
  if (formData != null && formData !== undefined) {
    //Set FormData
    const data = new FormData();
    const user = {
      Id: formData.id,
      UserType: 1,
      Name: formData.name ? formData.name : "",
      MobilePhone: formData.mobilePhone ? formData.mobilePhone : "",
      AlternativeMobilePhone: formData.alternativeMobilePhone ? formData.alternativeMobilePhone : "",
      Email: formData.email ? formData.email : "",
      Photo: file,
    };
    const userSingular = {
      LastName: formData.userSingular.lastName ? formData.userSingular.lastName : "",
      DateOfBirth: formData.userSingular.dateOfBirth
        ? moment(formData.userSingular.dateOfBirth).format("YYYY-MM-DD")
        : "",
      IdentityCard: formData.userSingular.identityCard ? formData.userSingular.identityCard : "",
      specialNeedsId: formData.userSingular.specialNeedsId ? formData.userSingular.specialNeedsId.code : 0,
      specialNeedsPercent: formData.userSingular.specialNeedsPercent ? formData.userSingular.specialNeedsPercent : "",
      GenderId: formData.gender ? formData.gender.code : 0,
      NationalityId: formData.nationalityId ? formData.nationalityId.code : 0,
      MaritalStatusId: formData.maritalStatus ? formData.maritalStatus.code : 0,
      NISS: formData.userSingular.niss ? formData.userSingular.niss : "",
      HasNISS: formData.userSingular.hasNISS ? formData.userSingular.hasNISS : "",
      AcademicLevelId: formData.academicLevel ? formData.academicLevel.code : "",
      DegreeId: formData.degree && formData.degree.code !== "" ? formData.degree.code : "",
      ProfessionalCategoryId:
        formData.professionalCategory && formData.professionalCategory.code !== ""
          ? formData.professionalCategory.code
          : "",
      ProfessionalTrainingId: formData.professionalTraining ? formData.professionalTraining.code : 0,
      EntrepreneurshipTrainingId: formData.entrepreneurshipTraining ? formData.entrepreneurshipTraining.code : 0, // .label?
      SmallBusinessMgmtTrainingId: formData.smallBusinessMgmtTraining ? formData.smallBusinessMgmtTraining.code : 0, // .la 0,
      Experience: formData.userSingular.experience ? formData.userSingular.experience : 0,
      IsAvailableForInternshipOpportunities: formData.userSingular.isAvailableForInternshipOpportunities ?? false,
      IsAvailableForEmploymentOpportunities: formData.userSingular.isAvailableForEmploymentOpportunities ?? false,
      FamilyIncome: formData.userSingular.familyIncome ?? 0,
      Household: formData.userSingular.household ?? 0,
      IntendedWorkplaceProvinceId: formData.userSingular.intendedWorkplaceProvince
        ? formData.userSingular.intendedWorkplaceProvince.code
        : null,
      ProfessionalQualification: formData.userSingular.professionalQualification ?? "",
      OtherProfessionalExperience: formData.userSingular.otherProfessionalExperience ?? "",
      SecondaryExperience: formData.userSingular.secondaryExperience ?? 0,
      OccupationalCategoryId: formData.userSingular.occupationalCategory
        ? formData.userSingular.occupationalCategory.code
        : null,
    };
    const compositeAddress = {
      ProvinceId: formData.province ? formData.province.code : 0,
      MunicipalityId: formData.municipality ? formData.municipality.code : 0,
      District: formData.userSingular?.compositeAddress?.district
        ? formData.userSingular.compositeAddress.district
        : "",
      Address: formData.userSingular?.compositeAddress?.address ? formData.userSingular.compositeAddress.address : "",
    };
    //Append data
    for (const key in user) {
      if (key === "Photo" && !user[key]) continue;
      data.append("user." + key, user[key]);
    }
    for (const key in userSingular) {
      data.append("user.userSingular." + key, userSingular[key]);
    }
    for (const key in compositeAddress) {
      data.append("user.userSingular.compositeAddress." + key, compositeAddress[key]);
    }
    //Lists need to be treated seperately
    formData.professionalExperience?.forEach((element) => {
      data.append("user.userSingular.ProfessionalExperienceList", element.code);
    });
    formData.secondaryProfessionalExperience?.forEach((element) => {
      data.append("user.userSingular.SecondaryProfessionalExperienceList", element.code);
    });
    formData.studiedLanguages?.forEach((element) => {
      data.append("user.userSingular.StudiedLanguagesList", element.code);
    });
    //Append documents
    let countDoc = 0;
    documentTypes?.forEach((dt) => {
      if (formData[dt.documentTypeId.toString()] && formData[dt.documentTypeId.toString()][0]) {
        data.append("user.UserDocuments[" + countDoc + "].documentTypeId", dt.documentTypeId);
        data.append("user.UserDocuments[" + countDoc + "].documentIndex", countDoc); //we send the index on the files array so the API can identify the document associated with the type
        data.append("UserDocuments", formData[dt.documentTypeId.toString()][0]);
        countDoc++;
      }
    });

    return data;
  }
  return formData;
}

export default userProfilePersonalUpdateMap;
