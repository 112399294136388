import { AUTH, REGISTER } from "utils/actionsConsts";

const authReducer = (
  state = {
    email: "",
    phoneNumber: "",
    loggedIn: false,
    token: "",
    personalInformation: {},
    confirmationModal: {
      showConfirmationModal: false,
      actionType: "",
      mobilePhone: "",
    },
    newPasswordModal: {
      showNewPasswordModal: false,
      mobilePhone: "",
      resetToken: "",
    },
    login_loading: false,
    register_loading: false,
    session_expired: false,
    reset_password_loading: false,
    login_message: null,
    lastLoginDate: "",
    showNews: false,
    getUserEmploymentHistory_loading: false,
    getUserUnemploymentHistory_loading: false,
    getFoUSInterventionOtherArea_loading: false,
    getFoUSInterventionOtherArea__result: [],
  },
  action
) => {
  switch (action.type) {
    case REGISTER.REGISTER:
      return {
        ...state,
        registrationResult: action.payload,
        register_loading: false,
        register_userType: action.userType,
        showRegistrationResultModal: action.payload,
      };
    case REGISTER.REGISTER_LOADING:
      return { ...state, register_loading: true };
    case REGISTER.HIDE_REGISTRATION_MODAL:
      return { ...state, showRegistrationResultModal: action.payload };
    case AUTH.SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: {
          showConfirmationModal: action.payload.toShow,
          actionType: action.payload.toShow ? action.payload.actionType : "",
          mobilePhone: action.payload.toShow ? action.payload.mobilePhone : "",
        },
      };
    case AUTH.SHOW_NEW_PASSWORD_MODAL:
      return {
        ...state,
        newPasswordModal: {
          showNewPasswordModal: action.payload.toShow,
          mobilePhone: action.payload.toShow ? action.payload.mobilePhone : "",
          resetToken: action.payload?.resetToken,
        },
      };
    case AUTH.LOGIN_LOADING:
      return { ...state, login_loading: action.payload };
    case "updateMustConfirmPhone":
      return { ...state, mustConfirmPhone: !action.payload };
    case AUTH.LOGIN:
      return {
        ...state,
        email: action.payload.email,
        phoneNumber: action.payload.phoneNumber,
        loggedIn: action.payload.succeeded,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        name: action.payload.name,
        lastName: action.payload.lastName,
        photo: action.payload.photo,
        login_error: !action.payload.succeeded,
        mustConfirmPhone: action.payload.mustConfirmPhone,
        mustConfirmEmail: action.payload.mustConfirmEmail,
        login_loading: false,
        operationsList: action.payload.operationsList,
        userType: action.payload.userType,
        lastLoginDate: action.payload.lastLoginDate,
      };
    case "lastLogin":
      return {
        ...state,
        lastLoginDate: action.payload.lastLoginDate,
        showNews: action.payload.showNews,
        news: action.payload.news,
      };
    case "logout":
      return {
        ...state,
        loggedIn: false,
        login_error: false,
        token: "",
        login_loading: false,
        reset_password_loading: false,
        login_message: null,
      };
    case "refresh-token":
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
        token: action.payload.jwtToken,
      };
    case "recover-password":
      return {
        ...state,
        showRecoverPasswordResultModal: true,
        recoverPasswordResult: action.payload,
      };
    case AUTH.LOGIN_UNNAPROVED:
      return {
        ...state,
        login_message: action.payload.login_message,
      };
    case AUTH.SESSION_EXPIRED:
      return { ...state, loggedIn: false, session_expired: true };
    case AUTH.RESET_SESSION_EXPIRED:
      return { ...state, session_expired: false };
    case AUTH.RESET_PASSWORD_LOADING:
      return {
        ...state,
        reset_password_loading: action.payload,
      };
    case AUTH.RESET_PASSWORD:
      return {
        ...state,
        reset_ok: action.payload,
        sent: false,
        reset_password_loading: false,
      };
    case AUTH.RESET_RESET_PASSWORD_STATE:
      return {
        ...state,
        reset_ok: false,
      };
    case AUTH.RESET_LOGIN_FORM:
      return {
        ...state,
        loggedIn: false,
        login_error: false,
        token: "",
        login_loading: false,
        reset_password_loading: false,
        login_message: null,
      };

    case AUTH.HIDE_RECOVER_PASSWORD_MODAL:
      return { ...state, showRecoverPasswordResultModal: action.payload };
    case AUTH.GET_PERSONAL_INFORMATION:
      return {
        ...state,
        personalInformation: action.payload,
        personalInformation_loading: false,
      };
    case AUTH.GET_PERSONAL_INFORMATION_LOADING:
      return { ...state, personalInformation_loading: action.payload };
    case "updateProfile":
      var splitName = action.payload.name.split(" ");
      var splitSurname = action.payload.lastName?.split(" ");
      return {
        ...state,
        name: action.payload.userType === 1 ? splitName[0] : action.payload.name,
        lastName: action.payload.userType === 1 ? splitSurname[splitSurname.length - 1] : "",
        photo: action.payload.photo ?? state.photo,
        update_profile_loading: false,
      };
    case AUTH.SET_TARGET_PATH_AFTER_LOGIN:
      return {
        ...state,
        targetPathAfterLogin: action.payload?.targetPath,
        targetPathAfterLoginUserType: action.payload?.pathUserType,
      };
    case AUTH.GET_USER_EMPLOYMENT_HISTORY:
      return {
        ...state,
        getUserEmploymentHistory_loading: false,
        getUserEmploymentHistory_result: action.payload,
      };
    case AUTH.GET_USER_EMPLOYMENT_HISTORY_LOADING:
      return { ...state, getUserEmploymentHistory_loading: action.payload };
    case AUTH.GET_USER_UNEMPLOYMENT_HISTORY:
      return {
        ...state,
        getUserUnemploymentHistory_loading: false,
        getUserUnemploymentHistory_result: action.payload,
      };
    case AUTH.GET_USER_UNEMPLOYMENT_HISTORY_LOADING:
      return { ...state, getUserUnemploymentHistory_loading: action.payload };
    case AUTH.GET_FOUS_INTERVENTION_OTHER_AREA:
      return {
        ...state,
        getFoUSInterventionOtherArea_loading: false,
        getFoUSInterventionOtherArea_result: action.payload,
      };
    case AUTH.GET_FOUS_INTERVENTION_OTHER_AREA_LOADING:
      return { ...state, getFoUSInterventionOtherArea_loading: action.payload };
    default:
      return state;
  }
};

export default authReducer;
