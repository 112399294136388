import React from "react";

import CardInstituteHolder from "components/Cards/Cards/CardInstituteHolder";

import { Box, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import themeColors from "assets/theme/colors";
import componentStyles from "assets/theme/views/admin/institute-holders";
import LocationHeader from "components/Headers/LocationHeader";
import { direcaoGeralInfo, servicosDeApoioInfo, servicosExecutivosInfo } from "constants/instituteHoldersInformation";

const useStyles = makeStyles(componentStyles);

function InstituteHoldersPage(props) {
  const classes = useStyles();

  return (
    <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
      <Box marginLeft="-45px">
        <LocationHeader
          section={props.section ?? props.title}
          subsection={props.subsection}
          subsectionLink={props.subsectionLink}
        />
      </Box>
      <Grid container rowSpacing={3}>
        <Grid item xs={12}>
          <Box data-testid="information-page-title" classes={{ root: classes.pageTitle }}>
            {props.title}
          </Box>
          <Box classes={{ root: classes.pageSubtitle }}>{props.subtitle}</Box>
        </Grid>
        <Grid item xs={12} classes={{ root: classes.boxRoot }} style={{ backgroundColor: themeColors.gray[100] }}>
          <Box classes={{ root: classes.titleBox }}>{direcaoGeralInfo.title}</Box>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardInstituteHolder
                holderName={direcaoGeralInfo.holders[0].holderName}
                holderPosition={direcaoGeralInfo.holders[0].holderPosition}
                holderPhoto={direcaoGeralInfo.holders[0].holderPhoto}
                holderLink={direcaoGeralInfo.holders[0].holderLink}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardInstituteHolder
                holderName={direcaoGeralInfo.holders[1].holderName}
                holderPosition={direcaoGeralInfo.holders[1].holderPosition}
                holderPhoto={direcaoGeralInfo.holders[1].holderPhoto}
                holderLink={direcaoGeralInfo.holders[1].holderLink}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
              <CardInstituteHolder
                holderName={direcaoGeralInfo.holders[2].holderName}
                holderPosition={direcaoGeralInfo.holders[2].holderPosition}
                holderPhoto={direcaoGeralInfo.holders[2].holderPhoto}
                holderLink={direcaoGeralInfo.holders[2].holderLink}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          classes={{ root: classes.boxRoot }}
          style={{ backgroundColor: themeColors.gray[200] }}
        >
          <Box classes={{ root: classes.titleBox }}>{servicosExecutivosInfo.title}</Box>
          <Grid container justifyContent="center" alignItems="stretch" spacing={3}>
            {servicosExecutivosInfo.holders.map((holder, index) => (
              <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
                <CardInstituteHolder
                  holderName={holder.holderName}
                  holderPosition={holder.holderPosition}
                  holderPhoto={holder.holderPhoto}
                  holderLink={holder.holderLink}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          classes={{ root: classes.boxRoot }}
          style={{ backgroundColor: themeColors.gray[300] }}
        >
          <Box classes={{ root: classes.titleBox }}>{servicosDeApoioInfo.title}</Box>
          <Grid container justifyContent="center" alignItems="stretch" spacing={3}>
            {servicosDeApoioInfo.holders.map((holder, index) => (
              <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
                <CardInstituteHolder
                  holderName={holder.holderName}
                  holderPosition={holder.holderPosition}
                  holderPhoto={holder.holderPhoto}
                  holderLink={holder.holderLink}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default InstituteHoldersPage;
