import { React, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//MaterialUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import OpportunityCard from "../../Components/Opportunities/Card/OpportunityCard";
import CardsListBackdrop from "components/Backdrops/CardsListBackdrop";
// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { getRecommendedOpportunitiesAction } from "redux/actions/opportunitiesAction";
import { PAGINATION } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const useStyles = makeStyles((theme) => ({
  RecommendedOpportunitiesTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "40px",
    color: theme.palette.primary.main,
    marginBottom: "20px",
    marginLeft: "25px"
  },
}));

function RecommendedOpportunities(props) {
  const classes = {...useStylesGeneric(), ...useStyles()};

  useEffect(() => {
    props.getRecommendedOpportunities({ pageSize: props.pageSize ? props.pageSize + 1 : PAGINATION.PAGE_SIZE_AVAILABLE_OPPORTUNITIES })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box classes={{ root: classes.RecommendedOpportunitiesTitle }}>
        Recomendados para si:
      </Box>
      {props.opportunitiesState?.recommended_result?.data?.length > 0 ? (
        <Grid container style={{ position: "relative" }}>
          {props.opportunitiesState.recommended_result.data.filter(o => o.id !== props.excludeOpportunityId).slice(0, props.pageSize ?? PAGINATION.PAGE_SIZE_AVAILABLE_OPPORTUNITIES).map((info, key) => (
            <Grid key={key} item xs={12} md={props.fullWidth ? 12 : 6} lg={props.fullWidth ? 12 : 4} xl={props.fullWidth ? 12 : 3}>
              <OpportunityCard
                info={info}
              />
            </Grid>
          ))}
          <CardsListBackdrop open={props.opportunitiesState?.searchRecommendedOpportunities_loading} />
        </Grid>
      )
        :
        (
          <Box>
            <Box classes={{ root: classes.noResultsInfo }}>
              Sem recomendações para a sua área profissional.
            </Box>
          </Box>
        )
      }
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getRecommendedOpportunities: (data) => dispatch(getRecommendedOpportunitiesAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedOpportunities);
