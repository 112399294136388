import React, {useEffect, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";

// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import StructuringProjectCard from "components/LandingPage/Components/StructuringProjectCard.js";
import { ArrayUtils } from "utils/array_utils";
import CardsListBackdrop from "components/Backdrops/CardsListBackdrop";
import { getFilteredStructuringProjectsAction } from "redux/actions/informationDisseminationAction";
import { PAGINATION, INFORMATION_DISSEMINATION_TYPE_KEYVALUE } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ResultSection(props) {
  const classes = useStylesGeneric();
  const [informationList, setInformationList] = useState([]);
  const [filter, setFilter] = useState({
    searchText: "",
    searchPublishPeriodKeyValue: undefined,
    informationDisseminationTypeKeyValueList: [INFORMATION_DISSEMINATION_TYPE_KEYVALUE.STRUCTURING_PROJECT],
    isPublic: props.authState.loggedIn !== undefined && props.authState.loggedIn ? null : true, // if user logged in show all, otherwise show only public ones
    published: true,
    isHighlighted: null,
    pageIndex: props.page,
    pageSize: PAGINATION.PAGE_SIZE_DISSEMINATION,
  });

  useEffect(() => {
    let temp_filter = {
      ...filter,
      pageIndex: props.page,
    };

    refreshResults(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.informationDisseminationState.filteredStructuringProjects_result)) {
      setInformationList(props.informationDisseminationState.filteredStructuringProjects_result?.data);
    } else {
      setInformationList([]);
    }
  }, [props.informationDisseminationState.filteredStructuringProjects_result]);

  const refreshResults = (temp_filter) => {
    setFilter(temp_filter);
    props.getFilteredStructuringProjects(temp_filter);
  };

  return (
    <>
      {informationList !== undefined && informationList?.length > 0 ? (
        <Grid container style={{ position: "relative" }}>
          {informationList?.map((info, key) => (
            <Grid key={key} item xs={12} md={6} lg={4} xl={3}>
              <StructuringProjectCard
                item={info}

              />
            </Grid>
          ))}
          <CardsListBackdrop open={props.informationDisseminationState.filteredStructuringProjects_loading} />
        </Grid>
      ) : (
        <Box classes={{ root: classes.noResultsInfo }}>
          Não foram encontrados resultados para esta pesquisa.
        </Box>
      )}
      <Pagination
        className={classes.alignPagination}
        count={props.informationDisseminationState?.filteredStructuringProjects_totalPages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getFilteredStructuringProjects: (data) => dispatch(getFilteredStructuringProjectsAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultSection);
