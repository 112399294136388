import { React, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
//MaterialUI
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import Tooltip from "@material-ui/core/Tooltip";
import ApproveCandidateDialog from "../Dialogs/ApproveCandidateDialog";
import RejectCandidateDialog from "../Dialogs/RejectCandidateDialog";
import _ from "lodash";
// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidatesListSection(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const history = useHistory();
  const [candidateId, setCandidateId] = useState();
  const [overlappingInternship, setOverlappingInternship] = useState(false);

  //Dialogs Logic
  const DIALOG = { SHOW: true, HIDE: false };
  const [dialogs, setDialogs] = useState({
    approveModal: false,
    rejectModal: false,
    updateModal: false,
  });

  const showHideDialog = (dialog, op) => {
    let dialogs_temp = _.mapValues(dialogs, () => false);

    dialogs_temp[dialog] = op;

    setDialogs(dialogs_temp);
  };

  function handleApproveModal(candidateId, overlappingInternship) {
    setCandidateId(candidateId);
    setOverlappingInternship(overlappingInternship);
    showHideDialog("approveModal", DIALOG.SHOW);
  }
  function handleRejectModal(candidateId) {
    setCandidateId(candidateId);
    showHideDialog("rejectModal", DIALOG.SHOW);
  }

  const tableHead = ["Nome", "Nº BI", "Estado", "Data de Candidatura", "", "", ""];

  return (
    <>
      {props.opportunitiesState?.result?.data !== undefined && props.opportunitiesState?.result?.data?.length > 0 && (
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.opportunitiesState?.result?.data?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.userName}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.identityCard}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.candidateStateDescription}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.applicationDate}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box alignItems="center" display="flex">
                      <Box alignItems="right">
                        <GenericButton
                          typeSubmit={false}
                          color="tertiary"
                          size="small"
                          disabled={prop.candidateState !== 1}
                          onClick={() => handleRejectModal(prop.alternateKey)}
                        >
                          Rejeitar
                        </GenericButton>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box alignItems="center" display="flex">
                      <Box alignItems="left">
                        <GenericButton
                          typeSubmit={false}
                          color="tertiary"
                          size="small"
                          disabled={prop.candidateState !== 1}
                          onClick={() => handleApproveModal(prop.alternateKey, prop.papeInternshipOverlapsOthersOfUser)}
                        >
                          Recrutar
                        </GenericButton>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box alignItems="center" display="flex">
                      <Tooltip title="Detalhes">
                        <IconButton
                          aria-label="details"
                          component={Link}
                          to={`/admin/candidate-details/${prop.alternateKey}`}
                        >
                          <Box
                            component={ArrowForwardIosIcon}
                            width="9px!important"
                            height="9px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TableBackdrop open={props.opportunitiesState?.opportunityUsers_loading} />
            </TableBody>
          </Box>
        </TableContainer>
      )}
      <ApproveCandidateDialog
        name="approve-dialog"
        maxWidth={"xl"}
        open={dialogs.approveModal}
        candidateId={candidateId}
        opportunityId={props.opportunityId}
        overlappingInternship={overlappingInternship}
        history={history}
        onClose={() => showHideDialog("approveModal", DIALOG.HIDE)}
      />

      <RejectCandidateDialog
        name="reject-dialog"
        maxWidth={"xl"}
        open={dialogs.rejectModal}
        candidateId={candidateId}
        opportunityId={props.opportunityId}
        history={history}
        onClose={() => showHideDialog("rejectModal", DIALOG.HIDE)}
      />
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(CandidatesListSection);
