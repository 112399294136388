import { React, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FilterSection from "./Sections/OpportunitiesFilterSection";
import ResultSection from "./Sections/OpportunitiesResultSection";
import RecommendedOpportunities from "./RecommendedOpportunities";
import LocationHeader from "components/Headers/LocationHeader";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunitiesList(props) {
  const classes = useStylesGeneric();
  const [page, setPage] = useState(1);
  const [showRecommendedOpportunities, setShowRecommendedOpportunities] =
    useState(true);
  const [preLoadOpportunities, setPreLoadOpportunities] = useState(false);
  const isMounted = useRef(false);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (isMounted.current) {
      if (
        !props.opportunitiesState?.searchRecommendedOpportunities_loading &&
        props.opportunitiesState?.recommended_result?.data?.length === 0
      ) {
        setShowRecommendedOpportunities(false);
        setPreLoadOpportunities(true);
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunitiesState?.searchRecommendedOpportunities_loading]);

  return (
    <Grid container classes={{ root: classes.containerRoot }}>
      <LocationHeader
        section="Emprego e Estágio Profissional"
      />
      <Grid item xs={12} md={12}>
        <FilterSection
          page={page}
          handleChangePage={(value) => {
            handleChangePage(undefined, value);
          }}
          showRecommendedOpportunities={showRecommendedOpportunities}
          setShowRecommendedOpportunities={setShowRecommendedOpportunities}
          preLoadOpportunities={preLoadOpportunities}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        {showRecommendedOpportunities ? (
          <RecommendedOpportunities />
        ) : (
          <ResultSection page={page} handleChangePage={handleChangePage} />
        )}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(OpportunitiesList);
