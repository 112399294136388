import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

//material-ui icons
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import componentStylesGeneric from "assets/theme/views/admin/generic";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import GenericButton from "components/Buttons/GenericButton";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import { titles, labels } from "resources/resources";
import { getAuthenticationContext } from "utils/auth";
import { USER_TYPE_KEY } from "utils/const.js";
import LocationHeader from "components/Headers/LocationHeader";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Register() {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();

  const tableHead = [
    {
      title: intl.formatMessage(labels.Label_Auth_Register_Introduction_TableHead_Area),
    },
    {
      title: intl.formatMessage(labels.Label_Auth_Register_Introduction_TableHead_Service),
    },
    {
      title: intl.formatMessage(labels.Label_Auth_Register_TableHead_EntityTypeIndividual),
      //path: `/${getAuthenticationContext()}/individual-user-registration`
    },
    {
      title: intl.formatMessage(labels.Label_Auth_Register_TableHead_EntityTypeCompany),
      //path: `/${getAuthenticationContext()}/collective-user-registration`
    },
  ];

  const servicesList = [
    {
      area: intl.formatMessage(labels.Label_Auth_Register_Introduction_ServicesList_Area_GovernmentSupports),
      details: [
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_GovernmentSupports_Service_ApplyToGovernmentSupports
          ),
          individual: true,
          company: false,
        },
      ],
    },
    {
      area: intl.formatMessage(labels.Label_Auth_Register_Introduction_ServicesList_Area_EmploymentAndInternship),
      details: [
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_EmploymentAndInternship_Service_CreateOpportunities
          ),
          individual: false,
          company: true,
        },
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_EmploymentAndInternship_Service_ApplyToOpportunities
          ),
          individual: true,
          company: false,
        },
      ],
    },
    {
      area: intl.formatMessage(labels.Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining),
      details: [
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckAvailableCourses
          ),
          individual: true,
          company: false,
        },
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_ApplyToCourses
          ),
          individual: true,
          company: false,
        },
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckMyCourses
          ),
          individual: true,
          company: false,
        },
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckMyCoursesCalendar
          ),
          individual: true,
          company: false,
        },
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_ProfessionalTraining_Service_CheckMyCourseCertificates
          ),
          individual: true,
          company: false,
        },
      ],
    },
    {
      area: intl.formatMessage(labels.Label_Auth_Register_Introduction_ServicesList_Area_Posts),
      details: [
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_Posts_Service_CheckPosts
          ),
          individual: true,
          company: true,
        },
      ],
    },
    {
      area: intl.formatMessage(labels.Label_Auth_Register_Introduction_ServicesList_Area_Contacts),
      details: [
        {
          service: intl.formatMessage(
            labels.Label_Auth_Register_Introduction_ServicesList_Area_Contacts_Service_CheckContacts
          ),
          individual: true,
          company: true,
        },
      ],
    },
  ];
  const registerOnClick = (registerType) => {
    switch (registerType) {
      case USER_TYPE_KEY.INDIVIDUAL:
        history.push(`/${getAuthenticationContext()}/individual-user-registration`);
        break;
      case USER_TYPE_KEY.COLETIVO:
        history.push(`/${getAuthenticationContext()}/collective-user-registration`);
        break;
      default:
        return;
    }
  };
  return (
    <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
      <Box marginLeft="-10px">
        <LocationHeader section="Registo" />
      </Box>

      <PageTitleHeader title={intl.formatMessage(titles.Title_Auth_Register)} />

      <Box component={Grid} container justifyContent="center">
        <Grid item xs={12} lg={12} md={12}>
          <Container maxWidth={false}>
            <Box className={classes.pageLabelsWithoutMargin}>
              {intl.formatMessage(labels.Label_Auth_Register_Introduction_1)}
            </Box>
            <Box className={classes.pageLabels}>{intl.formatMessage(labels.Label_Auth_Register_Introduction_2)}</Box>
          </Container>
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Container maxWidth={false}>
            <CardTableContainer title={intl.formatMessage(titles.Title_Register_Card_Title)}>
              <TableContainer>
                <Box component={Table} alignItems="center" marginBottom="0!important">
                  <TableHead>
                    <TableRow>
                      {tableHead.map((prop, key) => (
                        <TableCell
                          key={key}
                          style={{ textAlign: "center" }}
                          classes={{
                            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                          }}
                        >
                          {prop.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servicesList.map((item, key) => (
                      <React.Fragment key={key}>
                        <TableRow>
                          <TableCell
                            rowSpan={item?.details?.length + 1}
                            classes={{ root: classes.tableCellRoot }}
                            style={{
                              fontWeight: "bold",
                              borderBottom: key === servicesList?.length - 1 ? "0" : "",
                            }}
                          >
                            {item?.area}
                          </TableCell>
                        </TableRow>
                        {item?.details?.map((detail, key) => (
                          <TableRow key={key}>
                            <TableCell classes={{ root: classes.tableCellRoot }}>{detail?.service}</TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }} style={{ width: "16%" }}>
                              {detail?.individual ? (
                                <CheckCircleIcon classes={{ root: classes.tableCheckIcon }} />
                              ) : (
                                <CancelIcon classes={{ root: classes.tableErrorIcon }} />
                              )}
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }} style={{ width: "16%" }}>
                              {detail?.company ? (
                                <CheckCircleIcon classes={{ root: classes.tableCheckIcon }} />
                              ) : (
                                <CancelIcon classes={{ root: classes.tableErrorIcon }} />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Box>
              </TableContainer>
            </CardTableContainer>
          </Container>
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Grid container classes={{ root: classes.containerButtons }}>
            <Grid item xs={12} md={6} xl={8}></Grid>
            <Grid item xs={12} sm={6} md={3} xl={2}>
              <FormGroup>
                <GenericButton color="tertiary" onClick={() => registerOnClick(USER_TYPE_KEY.INDIVIDUAL)}>
                  {intl.formatMessage(labels.Label_Auth_Register_Button_EntityTypeIndividual)}
                </GenericButton>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3} xl={2}>
              <FormGroup>
                <GenericButton color="tertiary" onClick={() => registerOnClick(USER_TYPE_KEY.COLETIVO)}>
                  {intl.formatMessage(labels.Label_Auth_Register_Button_EntityTypeCompany)}
                </GenericButton>
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Register;
