import { React, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

// @material-ui/core components
import {
  Container,
  Grid,
  FormGroup,
} from "@material-ui/core";

import FollowUpDetails from "../../../Components/OpportunityApplicationsFollowUp/FollowUpDetails";
import { OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY } from "utils/const";
import { updateOpportunityFollowupPeriodAction } from "redux/actions/opportunitiesAction";
import { updateOpportunityFollowupPeriodMap } from "mappers/OpportunityMap";
import GenericButton from "components/Buttons/GenericButton";

function FollowUpDetailsTab(props) {
  const history = useHistory();
  const [isEditable, setIsEditable] = useState(false);
  const methods = useForm({
    mode: "onChange",
  });

  const cancelOnClick = () => {
    history.goBack();
  };

  function handleLog() {
    console.log(methods.formState.errors);
  }

  const handleEditSection = () => {
    setIsEditable(true);
  };

  function onSubmit(data) {
    data["candidateCode"] = props.opportunityApplicationFollowUpDetails?.candidateCode;

    let updateData = updateOpportunityFollowupPeriodMap(
      data,
    );

    props.updateOpportunityFollowupPeriod(
      updateData,
      () => {
        props.handleRefreshPage();
      }
    );
  }

  return (
    <>
      <Container maxWidth={false}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FollowUpDetails
                opportunityApplicationFollowUpDetails={props.opportunityApplicationFollowUpDetails}
                isEditable={isEditable}
                handleEditSection={handleEditSection}
                isUserColective={true}
            />
            {isEditable && props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateKey === OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.PORINICIAR &&(
              <>
                <Grid container>
                  <Grid item xs={12} sm={2} md={6}></Grid>
                  <Grid item xs={12} sm={5} md={3}>
                    <FormGroup>
                      <GenericButton 
                        typeSubmit={false}
                        color="secondary"
                        onClick={() => cancelOnClick()}
                      >
                        Cancelar
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={5} md={3}>
                    <FormGroup >
                      <GenericButton 
                        typeSubmit={true}
                        color="primary"
                        disabled={props.opportunitiesState?.update_opportunity_followup_period_loading}
                        loading={props.opportunitiesState?.update_opportunity_followup_period_loading}
                        onClick={() => handleLog()}
                      >
                        Guardar
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                </Grid>

                <div style={{marginBottom: "90px"}}> </div>
              </>
            )}
            </form>
            {!isEditable && props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateKey === OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.PORINICIAR && (
              <div style={{marginBottom: "170px"}}> </div>
            )}
        </FormProvider>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateOpportunityFollowupPeriod: (data, onCloseMethod) => dispatch(updateOpportunityFollowupPeriodAction(data, onCloseMethod)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpDetailsTab);
