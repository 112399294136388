import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { hideGenericDialogAction } from "redux/actions/dialogsAction";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//import custom components
import componentStyles from "assets/theme/components/dialogs/generic-dialog.js";
import GenericButton from "components/Buttons/GenericButton";

import { DIALOG, DIALOG_ICON } from "utils/actionsConsts";

const useStyles = makeStyles(componentStyles);

function GenericDialog(props) {
  const classes = useStyles();
  const history = useHistory();

  const handleGenericDialogOpen = () => {
    return props.dialogState.showGenericDialog !== undefined
      ? props.dialogState.showGenericDialog
      : false;
  };

  const handleGenericDialogClose = () => {
    props.hideGenericDialog();
    if (props.dialogState.genericDialogData?.onCloseAction) {
      props.dialogState.genericDialogData.onCloseAction()
    }
    if (
      props.dialogState.genericDialogData === undefined ||
      props.dialogState.genericDialogData.goBack
    ) {
      if (props.dialogState.genericDialogData.onClose !== undefined) {
        history.push(props.dialogState.genericDialogData.onClose);
      }
    }
  };

  const handleGenericDialogOK = () => {
    props.hideGenericDialog();
    if (
      props.dialogState.genericDialogData === undefined ||
      props.dialogState.genericDialogData.okAction
    ) {
      props.dialogState.genericDialogData.okAction();
    }
    if (
      props.dialogState.genericDialogData === undefined ||
      props.dialogState.genericDialogData.goBack
    ) {
      if (props.dialogState.genericDialogData.onClose !== undefined) {
        history.push(props.dialogState.genericDialogData.onClose);
      }
    }
  };

  const handleGenericDialogYes = () => {
    props.hideGenericDialog();
 
    if (
      props.dialogState.genericDialogData === undefined ||
      props.dialogState.genericDialogData.yesAction
    ) {
      props.dialogState.genericDialogData.yesAction();
    }
  };

  const handleGenericDialogNo = () => {
    props.hideGenericDialog();

    if (
      props.dialogState.genericDialogData === undefined ||
      props.dialogState.genericDialogData.noAction
    ) {
      props.dialogState.genericDialogData.noAction();
    }
  };

  const loadDialogButtons = (dialogType) => {
    switch (dialogType) {
      case DIALOG.SIMPLE_TYPE:
        return <></>;
      case DIALOG.ONE_BUTTON_TYPE:
        return (
          <GenericButton 
            typeSubmit={false}
            color="primary"
            classes={classes.centeredButton}
            onClick={handleGenericDialogOK}
          >
            OK
          </GenericButton>
        );
      case DIALOG.TWO_BUTTON_TYPE:
        return (
          <Grid
            container
            direction="row-reverse"
            alignItems="flex-end"
            style={{ justifyContent: "center", marginTop: "25px" }}
          >
            <Grid item>
              <GenericButton 
                typeSubmit={false}
                color="primary"
                onClick={handleGenericDialogYes}
              >
                Sim
              </GenericButton>
            </Grid>
            <Grid item>
              <GenericButton 
                typeSubmit={false}
                color="secondary"
                onClick={handleGenericDialogNo}
              >
                No
              </GenericButton>
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  };

  return (
    <Dialog
      open={handleGenericDialogOpen()}
      classes={{ paper: classes.modalResult }}
      onClose={handleGenericDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <IconButton
        edge="start"
        onClick={handleGenericDialogClose}
        classes={{ root: classes.modalResultClose }}
      >
        <CloseIcon />
      </IconButton>
      <CardContent>
        <Box component={Grid} alignItems="center">
          {GetIcon()}
          <Typography classes={{ root: classes.modalResultHeader }}>
            {props.dialogState.genericDialogData !== undefined
              ? props.dialogState.genericDialogData.message
              : "Ocorreu um erro a criar o Subprograma"}
          </Typography>

          {showSubMessage(props.dialogState.genericDialogData?.submessage)}

          {loadDialogButtons(props.dialogState.genericDialogData?.type)}

        </Box>
      </CardContent>
    </Dialog>
  );

  function showSubMessage(text) {
    return (
      <div className={classes.modalResultInfoText}>
        <Typography variant="h4" component="h4">
          {text}
        </Typography>
      </div>
    );
  }

  function GetIconLegacy() {
    return props.dialogState.genericDialogData !== undefined &&
      props.dialogState.genericDialogData.isSuccess ? (
      <CheckCircleOutlinedIcon classes={{ root: classes.modalResultIcon }} />
    ) : (
      <CancelOutlinedIcon classes={{ root: classes.modalErrorResultIcon }} />
    );
  }

  function GetIcon() {
    if (
      props.dialogState.genericDialogData !== undefined &&
      props.dialogState.genericDialogData.iconType
    ) {
      switch (props.dialogState.genericDialogData.iconType) {
        case DIALOG_ICON.QUESTION:
          return <HelpOutlineIcon classes={{ root: classes.modalHelpIcon }} />;
        default:
          return GetIconLegacy();
      }
    } else return GetIconLegacy();
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  hideGenericDialog: () => dispatch(hideGenericDialogAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericDialog);
