import React from "react";

import { Fragment } from "react";
import { useIntl } from "react-intl";

import { Box, Card, CardContent, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import componentStyles from "assets/theme/views/admin/institute-holders";
import DisplayDataInstituteHolders from "components/FormFields/DisplayDataInstituteHolders";
import LocationHeader from "components/Headers/LocationHeader";
import { labels } from "resources/resources";

const useStyles = makeStyles(componentStyles);

function BiographyPage(props) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
      <Box marginLeft="-45px">
        <LocationHeader
          section={props.data.name}
          subsection={props.section ?? props.title}
          subsectionLink="/auth/institute-holders"
        />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box data-testid="information-page-title" classes={{ root: classes.pageTitle }}>
            {props.title}
          </Box>
          <Box classes={{ root: classes.pageSubtitle }}>{props.subtitle}</Box>
        </Grid>

        <Card className={classes.cardRoot}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={5}>
                {props.data.photo ? (
                  <img src={props.data.photo} width="100%" alt={"Foto - " + props.data.title} />
                ) : (
                  <img src="/images/INEFOP_azul.png" width="100%" alt="Logo INEFOP" />
                )}
              </Grid>
              <Grid item xs={12} md={7}>
                <Box classes={{ root: classes.descriptionRoot }}>
                  {props.data.content && props.data.content?.length > 0 ? (
                    <Fragment>
                      <DisplayDataInstituteHolders data={props.data} />
                    </Fragment>
                  ) : (
                    <div className={classes.noResults}>
                      {intl.formatMessage(labels.Label_No_Information_To_Present)}
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
}

export default BiographyPage;
