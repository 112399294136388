import React, { useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import componentStylesGeneric from "assets/theme/views/admin/generic";

import { FOLLOWUP_STATE_KEY_QUERY } from "utils/const.js";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import MyOpportunityApplicationsFollowUpsTab from "views/admin/UserArea/UserSingular/MyFollowUpsTabs/MyOpportunityApplicationsFollowUpsTab";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function MyFollowUpsList() {
  const classes = useStylesGeneric();
  const [tabStep, setTabStep] = useState("ongoing");

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);
    }
  };

  function loadTabContent() {
    switch (tabStep) {
      case "finished":
        return <MyOpportunityApplicationsFollowUpsTab followUpState={FOLLOWUP_STATE_KEY_QUERY.FINISHED} />;
      case "ongoing":
      default:
        return <MyOpportunityApplicationsFollowUpsTab followUpState={FOLLOWUP_STATE_KEY_QUERY.ONGOING} />;
    }
  }

  return (
    <>
      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
        <LocationHeader
          section={"Acompanhamentos"}
        />

        <PageTitleHeader title={"Acompanhamentos"} />

        <Box component={Grid} container justifyContent="center">
          <Grid item xs={12} lg={12} md={12}>
            <ToggleButtonGroup
              value={tabStep}
              exclusive
              onChange={handleTabStep}
              aria-label="tab Step"
              className={classes.changeTabsGroup}
              style={{ marginLeft: "15px" }}
            >
              <ToggleButton value="ongoing" className={classes.changeTabs}>
                Em Curso
              </ToggleButton>
              <ToggleButton value="finished" className={classes.changeTabs}>
                Concluídos
              </ToggleButton>
            </ToggleButtonGroup>

            <Grid item xs={12} lg={12} md={12}>
              {loadTabContent()}
            </Grid>
            
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(MyFollowUpsList);
