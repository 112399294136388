import { getReport } from "crud/reports.crud";
import { REPORTS } from "utils/actionsConsts";
import { convertByteArrayToBlobUrl } from "utils/converters";

export const getReportAction = (formData, reportFileName) => async (dispatch) => {
  dispatch({
    type: REPORTS.GET_REPORTS_LOADING,
    payload: true,
  });
  getReport(formData)
    .then((res) => {
      const link = document.createElement("a");
      link.href = convertByteArrayToBlobUrl(res.data);
      link.setAttribute("download", reportFileName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch({
        type: REPORTS.GET_REPORTS,
        result: false,
        payload: [],
      });
      dispatch({
        type: REPORTS.GET_REPORTS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: REPORTS.GET_REPORTS,
        result: false,
        payload: null,
      });
      dispatch({
        type: REPORTS.GET_REPORTS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export default getReportAction;
