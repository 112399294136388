import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  stepperRoot: {
    width: "100%",
    "& .MuiStepIcon-root": {
      width: "25px",
      height: "25px",
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: theme.palette.primary.greyBlue,
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: theme.palette.primary.darkBlue,
    },

    "& .MuiStepLabel-label.MuiStepLabel-active": {
      color: theme.palette.primary.greyBlue,
    },
    "& .MuiStepLabel-alternativeLabel": {
      color: theme.palette.primary.darkBlue,
    },
    color: theme.palette.white.main,
    backgroundColor: theme.palette.transparent.main,
    marginTop: "-30px",
  },
  buttonImg: {
    verticalAlign: "middle",
    borderStyle: "none",
  },
  buttonRoot: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.main,
    boxShadow: boxShadows.buttonBoxShadowNeutral,
    borderColor: theme.palette.white.main + "!important",
    "&:hover": {
      color: theme.palette.gray[900],
      borderColor: theme.palette.white.main + "!important",
      backgroundColor: theme.palette.white.main,
    },
  },
  formControlLabelRoot: {
    position: "relative",
    display: "flex",
    minHeight: "1.5rem",
    WebkitPrintColorAdjust: "exact",
    marginLeft: "17px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  formControlLabelLabel: {
    cursor: "pointer",
    fontSize: ".875rem",
    position: "relative",
    verticalAlign: "top",
    display: "inline-block",
    color: theme.palette.gray[600],
  },
  footerLinks: {
    color: theme.palette.gray[400],
    textDecoration: "none",
    fontSize: "80%",
  },
  gridButtonsBackForth: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  boxButtonRegister: {
    textAlign: "right",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: "1.5rem",
      marginBottom: "1rem",
    },
  },
  buttonRegisterRoot: {
    background:
      "linear-gradient(90deg, " +
      theme.palette.primary.greyBlue +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    borderRadius: "4px",
    border: "0px",
  },
  boxButtonBack: {
    textAlign: "left",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: "1rem",
      marginBottom: "1.5rem",
    },
  },
  buttonBackRoot: {
    background: theme.palette.white.main,
    /* Default */
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
      background: "rgba(82, 95, 127, 0.04)",
    },
  },
  toggleButtonRoot: {
    width: "190px",
    [theme.breakpoints.down("xs")]: {
      width: "120px",
    },
    heigth: "43px",
    backgroundColor: theme.palette.white.light,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px 0px 0px 4px",

    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    /* identical to box height */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    textTransform: "uppercase",

    /* Text Color/Dark Blue */

    color: theme.palette.primary.greyBlue,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.greyBlue + " !important",
      /* Color Border/Secondary */

      border: "1px solid " + theme.palette.white.light,
      boxSizing: "border-box",
      borderRadius: "4px",

      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      /* identical to box height */

      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: theme.palette.white.main,
    },
  },
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },

  selectBorderWarningInputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.warning.main + "!important",
    },
  },
  registHeader: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "34px",
    color: theme.palette.primary.darkBlue,
    paddingBottom: "30px",
  },
  informationListItemRoot: {
    display: "flex!important",
    paddingBottom: "0px!important",
    paddingTop: "0px!important",
    marginLeft: "35px",
  },
  informationListIconRoot: {
    minWidth: "30px!important",
  },
  marginBottom30px: {
    marginBottom: "30px!important",
  },
  informationFontSize: {
    fontSize: "1rem!important",
  },
  informationSubheaderPosition: {
    position: "inherit!important",
    height: "35px",
  },
  informationCardHeader: {
    padding: "0.5rem 0.5rem 1rem",
  },
  informationAccordionSummaryContent: {
    display: "block",
  },
});

export default componentStyles;
