import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

//request needs to be changed from API side 
export const POST_FRONTOFFICE_PERSONAL_PROFILE_UPDATE_URL = "Account/update";

export function frontOfficePersonalProfileUpdate(formData) {
  return axios.post(EXTERNAL_API_URL + POST_FRONTOFFICE_PERSONAL_PROFILE_UPDATE_URL , formData);
}

