import { SOCIAL_MEDIA_ACTIONS } from "utils/actionsConsts";

const socialMediaReducer = (
  state = {
    socialMediaLinks_loading: false,
  },
  action
) => {
  switch (action.type) {
    case SOCIAL_MEDIA_ACTIONS.SOCIAL_MEDIA_LINKS:
      return {
        ...state,
        socialMediaLinks_result: action.payload,
      };
    case SOCIAL_MEDIA_ACTIONS.SOCIAL_MEDIA_LINKS_LOADING:
      return { ...state, socialMediaLinks_loading: action.payload };
    default:
      return state;
  }
};

export default socialMediaReducer;
