import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  getDataSetByNameAction,
  getGeographicDataByProvinceAction,
} from "redux/actions/datasetsAction";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { labels } from "resources/resources";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import { getApplicationDocumentAction } from "redux/actions/applicationsAction";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
import ProgramTable from "../../Components/ProgramTable";
import { COMPLEMENTARY_DATA_ANSWER_TYPE, UNITS, BENEFIT, DATASET } from "utils/const";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ApplicationData(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();
  const [bankPreferenceName, setBankPreferenceName] = useState("");

  const getCardHeaderTitle = () => {
    return "Dados da Candidatura";
  };

  const checkIfShouldShow = (field) => {
    if (!field.hasDependencies || (field.hasDependencies && field.answer))
      return true;
    return false;
  };
  const getValuesToShow = (field) => {
    switch (field.answerTypeId) {
      case COMPLEMENTARY_DATA_ANSWER_TYPE.TEXT:
        return field.answer;
      case COMPLEMENTARY_DATA_ANSWER_TYPE.DATASET:
      case COMPLEMENTARY_DATA_ANSWER_TYPE.BOOLEAN:
        if (
          props.datasetsState[field.dataSetName] === undefined ||
          props.datasetsState[field.dataSetName] === []
        ) {
          props.getDataSetByName(field.dataSetName);
        }
        if (field.answer !== null && field.answer !== "") {
          var fieldLabel = getSelectOptionsElementByCode(
            props.datasetsState[field.dataSetName],
            Number.parseInt(field.answer)
          );
          return fieldLabel?.label;
        }

        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (props.datasetsState && !props.datasetsState[DATASET.BANK])
      props.getDataSetByName(DATASET.BANK);
    else if (props.datasetsState && props.datasetsState[DATASET.BANK] && props.generalData?.bankPreferenceId)
      setBankPreferenceName(getSelectOptionsElementByCode(props.datasetsState[DATASET.BANK],props.generalData.bankPreferenceId).label);
    else
      setBankPreferenceName("Sem preferência");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.BANK], props.generalData?.bankPreferenceId]);

  return (
    <Container maxWidth={false}>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            title: classes.cardHeader,
          }}
          title={getCardHeaderTitle()}
          action={
            props.generalData?.applicationStateId === 2 && (
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size='small'
                onClick={() => {
                  history.push(`/admin/edit-application/${props.applicationNumber}`)
                }}
              >
                <Brightness1RoundedIcon
                  classes={{ root: classes.pendingIcon }}
                />
                Adicionar Dados Em Falta
              </GenericButton>
            )
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(
                    labels.Label_Admin_Applications_Programs_Field_Province
                  )}
                </FormLabel>
                <Box>
                  {props.generalData?.provinceName}
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(
                    labels.Label_Admin_Applications_Programs_Field_ProgramSubprogram
                  )}
                </FormLabel>
                <ProgramTable
                  governmentProgram = {props.generalData?.governmentProgramName}
                  program={props.generalData?.programName}
                  subprogram={props.generalData?.subprogramName}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(
                    labels.Label_Admin_Applications_Programs_Field_ParentApplication
                  )}
                </FormLabel>
                <Box>
                  {props.generalData?.parentApplicationCode ?? "N/A"}
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormGroup>
                <FormLabel>Tipo de benefício</FormLabel>
                <Box>
                  {props.generalData?.benefitName}
                </Box>
              </FormGroup>
            </Grid>
            {(props.generalData?.benefitKeyValue === BENEFIT.MICROCREDIT ||
              props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
              props.generalData?.benefitKeyValue ===
                BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
              <>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(
                        labels.Label_Admin_Applications_Programs_Field_GeneratedWorkPosts
                      )}
                    </FormLabel>
                    <Box>
                      {props.generalData?.generatedWorkPosts}
                    </Box>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Banco</FormLabel>
                    <Box>
                      {bankPreferenceName}
                    </Box>
                  </FormGroup>
                </Grid>
              </>
            )}
            {(props.generalData?.benefitKeyValue === BENEFIT.MICROCREDIT ||
              props.generalData?.benefitKeyValue ===
                BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>Valor pretendido</FormLabel>
                  <Box>
                    {(props.generalData?.microcreditAmount ?? 0) +
                      " " +
                      UNITS.MONETARY}
                  </Box>
                </FormGroup>
              </Grid>
            )}
            {(props.generalData?.benefitKeyValue === BENEFIT.PROFESSIONAL_KIT ||
              props.generalData?.benefitKeyValue ===
                BENEFIT.PROFESSIONAL_KIT_MICROCREDIT) && (
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>Número de kits</FormLabel>
                  <Box>
                    {props.generalData?.professionalKitsNumber ?? 0}
                  </Box>
                </FormGroup>
              </Grid>
            )}
            {props.generalData?.benefitKeyValue ===
              BENEFIT.PROFESSIONAL_INTERNSHIP && (
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormLabel>Área onde pretende fazer o estágio</FormLabel>
                  <Box>
                    {props.generalData?.internshipAreaDescription}
                  </Box>
                </FormGroup>
              </Grid>
            )}
            {(props.generalData?.benefitKeyValue === BENEFIT.CAPACITA ||
              props.generalData?.benefitKeyValue === BENEFIT.AVANCO ||
              props.generalData?.benefitKeyValue ===
                BENEFIT.PROFESSIONAL_WALLET) && (
              <>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>
                      {props.generalData?.benefitKeyValue ===
                      BENEFIT.PROFESSIONAL_WALLET
                        ? "Escolha da carteira pretendida"
                        : "Curso pretendido"}
                    </FormLabel>
                    <Box>
                      {props.generalData?.intendedCourseName}
                    </Box>
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>Centro de formação</FormLabel>
                    <Box>
                      {props.generalData?.trainingCenterName}
                    </Box>
                  </FormGroup>
                </Grid>
              </>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <Box className={classes.cardHeader}>Dados Complementares</Box>
              </FormGroup>
            </Grid>
            {props.generalData?.complementaryData?.map(
              (cd, key) =>
                checkIfShouldShow(cd) && (
                  <Grid item xs={12} md={4} key={key}>
                    <FormGroup>
                      <FormLabel>{cd.question}</FormLabel>
                      <Box>
                        {getValuesToShow(cd)}
                      </Box>
                    </FormGroup>
                  </Grid>
                )
            )}
            {props.generalData?.documents?.length > 0 && (
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Documentos submetidos</FormLabel>
                  <Grid
                    container
                    component={Box}
                    marginTop="1rem"
                    className={classes.documentBadgeContainer}
                  >
                    {props.generalData?.documents.map((file, i) => (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.paddingRight0px}
                        key={i}
                      >
                        <FormGroup>
                          <FormLabel>
                            {file.documentTypeName}
                          </FormLabel>
                          <SubmitedDocumentsSection
                            files={[file]}
                            getDocument={props.getApplicationDocument}
                          />
                        </FormGroup>
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) =>
    dispatch(getDataSetByNameAction(name, addEmptyField)),
  getGeographicDataByProvince: (name, province) =>
    dispatch(getGeographicDataByProvinceAction(name, province)),
  getApplicationDocument: (docId, docName) =>
    dispatch(getApplicationDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationData);
