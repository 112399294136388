import React from "react";
import { Controller } from "react-hook-form";
import {
  FormHelperText,
  FormGroup,
  FormLabel,
  Box,
  OutlinedInput,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
//style
import componentStyles from "assets/theme/views/admin/user-profile.js";
const useStyles = makeStyles(componentStyles);

export default function FormInput({
  name,
  label,
  control,
  rules,
  errors,
  disabled,
  placeholder,
  multiline,
  rows,
  numberType,
}) {
  const classes = useStyles();
  const theme = useTheme();

  var splitName = name.split(".");

  const checkErrors = (errors, name) => {
    const nameSplit = name?.split(".");

    let hasErrors = false;

    nameSplit?.map((nameElement) => {
      if (errors[nameElement] !== undefined) {
        errors = errors[nameElement];
        hasErrors = true;
      } else {
        hasErrors = false;
      }

      return hasErrors;
    });

    return hasErrors;
  };

  return (
    <FormGroup>
      <FormLabel className={classes.boxNameCategory}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        component={Box}
        defaultValue=""
        rules={rules}
        render={({ field: { onChange, name, value } }) => (
          <OutlinedInput
            name={name}
            value={value ? value : ""}
            onChange={onChange}
            fullWidth
            autoComplete="off"
            placeholder={placeholder}
            multiline={multiline}
            rows={rows}
            type={numberType ? "number" : "text"}
            disabled={disabled}
            classes={{
              notchedOutline: clsx({
                [classes.borderWarning]:
                  errors !== undefined && checkErrors(errors, name),
              }),
            }}
          />
        )}
      />
      {splitName.length === 1 && errors[name] !== undefined && (
        <FormHelperText
          component={Box}
          color={theme.palette.warning.main + "!important"}
        >
          {errors[name].message}
        </FormHelperText>
      )}
      {splitName.length === 2 &&
        errors[splitName[0]] !== undefined &&
        errors[splitName[0]][splitName[1]] !== undefined && (
          <FormHelperText
            component={Box}
            color={theme.palette.warning.main + "!important"}
          >
            {errors[splitName[0]][splitName[1]].message}
          </FormHelperText>
        )}
      {splitName.length === 3 &&
        errors[splitName[0]] !== undefined &&
        errors[splitName[0]][splitName[1]] !== undefined &&
        errors[splitName[0]][splitName[1]][splitName[2]] !== undefined && (
          <FormHelperText
            component={Box}
            color={theme.palette.warning.main + "!important"}
          >
            {errors[splitName[0]][splitName[1]][splitName[2]].message}
          </FormHelperText>
        )}
    </FormGroup>
  );
}
