import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const GET_FILTERED_INFORMATION_URL = "InformationDissemination/get-filtered-information";
export const GET_INFORMATION_DETAILS_URL = "InformationDissemination/get-information-details";
export const GET_INFORMATION_DETAILS_PUBLIC_URL = "InformationDissemination/get-information-details-public";

export function getFilteredInformation(data) {
  return axios.post(EXTERNAL_API_URL + GET_FILTERED_INFORMATION_URL, data);
}

export function getInformationDetails(data) {
  return axios.get(EXTERNAL_API_URL + GET_INFORMATION_DETAILS_URL, {
    params: {
      alternateKey: data,
    },
  });
}

export function getInformationDetailsPublic(data) {
  return axios.get(EXTERNAL_API_URL + GET_INFORMATION_DETAILS_PUBLIC_URL, {
    params: {
      alternateKey: data,
    },
  });
}
