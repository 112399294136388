import { React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Card, CardHeader, CardContent } from "@material-ui/core";

//import custom components
import GenericButton from "components/Buttons/GenericButton";
import FormSelect from "components/FormFields/FormSelect";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { getFilteredOpportunitiesAction } from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { PAGINATION, DATASET, OPERATIONS } from "utils/const";
import { getFilteredOpportunitiesMap } from "mappers/OpportunityMap";
import { getDatasetStateByName } from "utils/datasets";
import { checkOperationPermission } from "utils/permission";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSection(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const [filter, setFilter] = useState({
    searchText: null,
    reference: null,
    provinceId: null,
    opportunityTypeId: null,
    opportunityStateId: null,
    professionalCategoryId: null,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE,
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.datasetsState[DATASET.PROVINCES] === undefined || props.datasetsState[DATASET.PROVINCES]?.length === 0) {
      props.getDataSetByName(DATASET.PROVINCES);
    }
    if (
      props.datasetsState[DATASET.OPPORTUNITY_TYPE] === undefined ||
      props.datasetsState[DATASET.OPPORTUNITY_TYPE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_TYPE);
    }
    if (
      props.datasetsState[DATASET.OPPORTUNITY_STATE] === undefined ||
      props.datasetsState[DATASET.OPPORTUNITY_STATE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_STATE);
    }
    if (
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY] === undefined ||
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]?.length === 0
    ) {
      props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  const cleanFilter = () => {
    setValue("searchText", "");
    setValue("reference", "");
    setValue("province", undefined);
    setValue("opportunityType", undefined);
    setValue("opportunityState", undefined);
    setValue("professionalCategory", undefined);

    setFilter(0);
  };

  //Refresh Results
  const refreshResults = () => {
    let temp_filter = { ...filter, pageIndex: props.page };
    setFilter(temp_filter);
    props.getFilteredOpportunities(temp_filter);
  };

  const onSubmit = (data) => {
    let filter = getFilteredOpportunitiesMap(data);
    setFilter(filter);
    if (props.page === PAGINATION.FIRST_PAGE) {
      props.getFilteredOpportunities(filter);
    } else {
      props.handleChangePage(PAGINATION.FIRST_PAGE);
    }
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <Grid container>
            <Box>Pesquisa de Ofertas </Box>
            <Box style={{ paddingRight: "15px", marginLeft: "20px" }}>
              {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITY_CREATE_BUTTON) && (
                <GenericButton
                  typeSubmit={false}
                  color="tertiary"
                  onClick={() => {
                    history.push("/admin/opportunities-create");
                  }}
                >
                  Criar Oferta
                </GenericButton>
              )}
            </Box>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>Nº Oferta</FormLabel>
                  <Controller
                    name="reference"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("reference", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>Tipo de Oferta</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="opportunityType"
                    selectOptions={getDatasetStateByName(props, DATASET.OPPORTUNITY_TYPE)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"opportunityType"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"province"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>Área Profissional</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="professionalCategory"
                    selectOptions={getDatasetStateByName(props, DATASET.PROFESSIONAL_CATEGORY)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"professionalCategory"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>Pesquisa (título/descrição)</FormLabel>
                  <Controller
                    name="searchText"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("searchText", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormGroup>
                  <FormLabel classes={{ root: classes.filterLabel }}>Estado</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="opportunityState"
                    selectOptions={getDatasetStateByName(props, DATASET.OPPORTUNITY_STATE)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"opportunityState"}
                  />
                </FormGroup>
              </Grid>
              {/* Botões de Pesquisa */}

              <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  Limpar Filtros
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.opportunitiesState?.searchOpportunities_loading}
                >
                  Pesquisar
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getFilteredOpportunities: (data) => dispatch(getFilteredOpportunitiesAction(data)),
  getDataSetByName: (data, parentKeyValue) => dispatch(getDataSetByNameAction(data, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
