import React from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
// import Swiper core and required modules
import SwiperCore, { Navigation, Autoplay } from "swiper/core";

// core components

import componentStyles from "assets/theme/views/admin/carousel";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const useStyles = makeStyles(componentStyles);

function CustomCarousel(props) {
  const classes = { ...useStyles() };

  const ComponentCard = props.ComponentCard;

  return (
    <>
      <Swiper
        navigation={props.activateNavigation}
        className={classes.containerSwiper}
        spaceBetween={50}
        autoplay={{ delay: 4000 }}
        breakpoints={{
          640: {
            width: 640,
            slidesPerView: 1,
          },
          768: {
            width: 768,
            slidesPerView: 2,
          },
          1008: {
            width: 1008,
            slidesPerView: 3,
          },
          1200: {
            width: 1200,
            slidesPerView: 3,
          },
          1650: {
            width: 1650,
            slidesPerView: 4,
          },
          1850: {
            width: 1850,
            slidesPerView: 4,
          },
          2200: {
            width: 2200,
            slidesPerView: 5,
          },
          2500: {
            width: 2500,
            slidesPerView: 5,
          },
        }}
      >
        {props.itemsList?.map((item, key) => (
          <SwiperSlide key={key} className={classes.containerSwiperSlide}>
            <ComponentCard item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(CustomCarousel);
