import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl } from "react-intl";
import { labels } from "resources/resources";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import individualRegistrationMap from "mappers/IndividualRegistrationMap";
import { registerAction } from "redux/actions/registerAction";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import componentStylesRegister from "assets/theme/views/auth/register.js";
import componentStylesGeneric from "assets/theme/views/admin/generic";

//custom components
import GenericButton from "components/Buttons/GenericButton";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import PersonalData from "./PersonalData";
import Password from "./Password";
import LocationHeader from "components/Headers/LocationHeader.js";
import { DATASET, USER_TYPE_KEY, USER_TYPE } from "utils/const"
import { titles } from "resources/resources"
const useStylesRegister = makeStyles(componentStylesRegister);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function IndividualRegistrationForm(props) {
  const classes = { ...useStylesGeneric(), ...useStylesRegister() };
  const theme = useTheme();
  const intl = useIntl();

  const methods = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    //If context is SSO, we need to redirect to SSO login page
    props.registerAction(individualRegistrationMap(data, props.datasetsState?.FO_UserDocumentTypes), USER_TYPE.INDIVIDUAL);
  };

  useEffect(() => {
    props.getDataSetByName(DATASET.USER_DOCUMENT_TYPES, USER_TYPE_KEY.INDIVIDUAL)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
      <LocationHeader
        section={intl.formatMessage(labels.Label_BreadCrumb_Individual_Registration)}
        subsection={intl.formatMessage(labels.Label_BreadCrumb_Registration)}
        subsectionLink={"/auth/register"}
      />

      <PageTitleHeader title={intl.formatMessage(titles.Title_Auth_Register_Individual_User)} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Box component={Grid} container justifyContent="center">
            <Grid item xs={12} lg={12} md={12}>
              <Container maxWidth={false}>
                <Card classes={{ root: classes.cardRoot }}>
                  <CardHeader
                    title={intl.formatMessage(
                      labels.Label_Auth_IndividualRegistrationSteps_MainForm_PersonalDataStep
                    )}
                    classes={{ title: classes.cardHeader }}
                  ></CardHeader>
                  <CardContent classes={{ root: classes.cardContent }}>
                    <PersonalData
                      control={methods.control}
                      getValues={methods.getValues}
                      errors={methods.formState.errors}
                      classes={classes}
                      theme={theme}
                      trigger={methods.trigger}
                      intl={intl}
                    />
                  </CardContent>
                </Card>

                <Card classes={{ root: classes.cardRoot }}>
                  <CardHeader
                    title={intl.formatMessage(
                      labels.Label_Auth_IndividualRegistrationSteps_MainForm__PasswordStep
                    )}
                    classes={{ title: classes.cardHeader }}
                  ></CardHeader>
                  <CardContent classes={{ root: classes.cardContent }}>
                    <Password
                      control={methods.control}
                      getValues={methods.getValues}
                      trigger={methods.trigger}
                      classes={classes}
                      theme={theme}
                      errors={methods.formState.errors}
                      intl={intl}
                    />
                  </CardContent>
                </Card>

                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ display: "flex", justifyContent: "right" }}
                  >
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      disabled={props.authState.register_loading}
                      loading={props.authState.register_loading}
                    >
                      {intl.formatMessage(
                        labels.Label_Button_Register
                      )}
                    </GenericButton>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  registerAction: (user, userType) => dispatch(registerAction(user, userType)),
  getDataSetByName: (name, addEmptyField) =>
    dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndividualRegistrationForm);
