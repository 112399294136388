import React from "react";

import { useIntl } from "react-intl";

import InstituteHoldersPage from "components/GenericPage/InstituteHolders";
import { titles } from "resources/resources";

function InstituteHolders() {
  const intl = useIntl();

  return (
    <InstituteHoldersPage
      title={intl.formatMessage(titles.Title_Menu_InstitutionalInformation_InstituteChiefs)}
      subtitle={intl.formatMessage(titles.Title_Menu_InstitutionalInformation_InstituteChiefs_Subtitle)}
    />
  );
}

export default InstituteHolders;
