import { CardContent, Divider, Grid, Typography, makeStyles } from "@material-ui/core";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";

import componentStyles from "assets/theme/views/admin/static-page.js";

import * as S from "./styles";
import React from "react";
import { Skeleton } from "@material-ui/lab";
import { getEmploymentCentersContacts } from "crud/contacts.crud";
import { useWatch } from "react-hook-form";

const useStyles = makeStyles(componentStyles);

export default function EmploymentCenter({ control }) {
  const provinceKeyValue = useWatch({ name: "province", control });

  const [page, setPage] = React.useState(1);

  const [employmentCenters, setEmploymentCenters] = React.useState(null);

 const fetchEmploymentCenters = React.useCallback(async () => {
   try {
     const response = await getEmploymentCentersContacts({
       provinceKeyValue: provinceKeyValue?.keyValue,
       pageIndex: page <= 0 ? 1 : page,
       pageSize: 8,
     });

     setEmploymentCenters(response);
   } catch (error) {
     console.error(error);
   }
 }, [page, provinceKeyValue]);

 React.useEffect(() => {
   fetchEmploymentCenters();
 }, [fetchEmploymentCenters]);

  const isLoading = React.useMemo(() => !employmentCenters, [employmentCenters]);

  const classes = useStyles();

  if (isLoading)
    return Array.from({ length: 8 }).map((_, key) => (
      <Grid item xs={12} md={3} lg={3} key={key}>
        <S.Card>
          <CardContent>
            <S.Title>
              <Skeleton animation="wave" height={10} width="100%" style={{ marginBottom: 6 }} />
            </S.Title>
            <Divider />
            <S.Container>
              <S.ContainerItem>
                <LocationOnIcon />
                <Skeleton animation="wave" height={10} width="50%" style={{ marginBottom: 6 }} />
              </S.ContainerItem>
              <S.ContainerItem>
                <PhoneIcon />
                <Skeleton animation="wave" height={10} width="50%" style={{ marginBottom: 6 }} />
              </S.ContainerItem>
              <S.ContainerItem>
                <EmailIcon />
                <Skeleton animation="wave" height={10} width="50%" style={{ marginBottom: 6 }} />
              </S.ContainerItem>
            </S.Container>
          </CardContent>
        </S.Card>
      </Grid>
    ));

  return (
    <section data-testid="employment-center">
      {employmentCenters.data.map(({ name, address, phoneNumber, email }, key) => (
        <Grid item xs={12} md={6} lg={3} key={key}>
          <S.Card>
            <CardContent>
              <S.Title>{name}</S.Title>
              <Divider />
              <S.Container>
                <S.ContainerItem>
                  <LocationOnIcon />
                  <Typography variant="caption" color="text.secondary">
                    {address}
                  </Typography>
                </S.ContainerItem>
                <S.ContainerItem>
                  <PhoneIcon />
                  <Typography variant="body2" color="text.secondary">
                    {phoneNumber}
                  </Typography>
                </S.ContainerItem>
                <S.ContainerItem>
                  <EmailIcon />
                  <a className={classes.linkRoot} href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                    {email}
                  </a>
                </S.ContainerItem>
              </S.Container>
            </CardContent>
          </S.Card>
        </Grid>
      ))}

      <S.Pagination
        count={employmentCenters.totalPages}
        page={page}
        onChange={(_, value) => setPage(value)}
        color="primary"
        size="small"
      />
    </section>
  );
}
