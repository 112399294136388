const componentStyles = (theme) => ({
    mainContainer: {
        backgroundColor: theme.palette.gray[100],
        paddingBottom: "0px",
        paddingTop: "10px"
    },
    directorImage: {
        maxWidth: "100%", maxHeight: "500px"
    },
    directorQuoteArea: {
        paddingTop: "40px",
    },
    quoteIcon: {
        marginBottom: "10px"
    },
    directorQuote: {
        textAlign: "justify", 
        fontSize: "33px", 
        fontStyle: "italic", 
        fontFamily: "PoppinsRegular",
        minWidth: "550px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "22px", 
            minWidth: "0px",
        },
    },
    sectionButton: {
        background: theme.palette.transparent.main,
        marginRight: "20px",
        marginBottom: "30px",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "20px"
        },
    },
    mainGrid: {
        maxWidth: "1525px", 
        justifyContent: "center", 
    },
    directorName: {
        fontWeight: "bold",
        fontSize: "20px", 
        marginTop: "20px"
    },
    directorFullJobDescription: {
        fontSize: "18px", 
        marginBottom: "30px"
    }
});

export default componentStyles;
  