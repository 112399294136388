const componentStyles = (theme) => ({
  modalHeader: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: theme.palette.primary.main,
    marginBottom: "15px",
  },
  boxNameCategory: {
    height: "19px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
  },
  formControlLabelCheckboxInTableHeader: {
    fontSize:"14px",
    fontWeight: "400",
    color:theme.palette.gray[900]
  },
  formControlLabel: {
    margin: 0
  },
});

export default componentStyles;
