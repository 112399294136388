import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  containerRoot: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "6rem",
      paddingRight: "6rem",
    },
    paddingTop: "8.5rem",
  },
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    width: "100%",
  },
  pageTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    textTransform: "uppercase",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginBottom: "20px",
  },
  descriptionRoot: {
    marginBottom: "1.5rem",
    textAlign: "justify",
  },
  itemRoot: {
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
  },
  linkRoot: {
    color: theme.palette.black.main,
    "&:hover": {
      color: theme.palette.primary.greyBlue,
    },
  },
  iframe: {
    border: 0,
    width: "100%",
    height: "100%",
    minHeight: "300px",
  },
  phoneNumberGrey: {
    color: theme.palette.gray[500],
  },

  boxMargin: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  title: {
    fontWeight: 600,
    fontSize: "18px",
    color: theme.palette.primary.main,
    marginBottom: "60px",
    textTransform: "uppercase",
  },
  subtitle: {
    textDecorationLine: "underline",
  },
  tableTitle: {
    fontWeight: "bold",
    marginBottom: "60px",
    textTransform: "uppercase",
  },
  content: {
    whiteSpace: "pre-line",
  },
  linkStandard: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1rem",
    marginLeft: "0.25rem",
    marginRight: "0.25rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    transition: "all .15s linear",
    color: theme.palette.grey[900],
    margin: "0",
    "& i": {
      marginRight: "0.25rem",
    },
    "&:hover": {
      color: theme.palette.primary.greyBlue,
      fontWeight: "600",
    },
  },
  helpIcon: {
    width: "35px",
    height: "35px",
    color: theme.palette.primary.greyBlue,
  },
  images: {
    maxWidth: "100%",
  },
  headerImage: {
    objectFit: "cover",
    objectPosition: "bottom center",
    maxHeight: "300px",
    boxShadow: boxShadows.boxShadow,
  },
  listItem: {
    color: theme.palette.black.main,
    marginTop: "-15px",
    "& .MuiSvgIcon-root": {
      width: "10px",
      height: "10px",
      marginTop: "-2px",
    },
  },
  noResults: {
    fontSize: "24px",
    margin: "20px 0px",
  },
});

export default componentStyles;
