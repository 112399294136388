import { UPDATE_PROFILE } from "utils/actionsConsts";

const personalProfileReducer = (state = { update_profile_loading: false }, action) => {
  switch (action.type) {
    case UPDATE_PROFILE.PERSONAL:
      return {
        ...state,
        result: action.payload,
        update_profile_loading: false,
      };
    case UPDATE_PROFILE.PERSONAL_LOADING:
      return { ...state, update_profile_loading: true };
    default:
      return state;
  }
};

export default personalProfileReducer;