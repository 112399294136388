import { reset_password } from "crud/auth.crud";
import { AUTH } from "utils/actionsConsts";

const resetPasswordAction = (username, password, token) => async (dispatch) => {
  dispatch({
    type: AUTH.RESET_PASSWORD_LOADING,
    payload: true,
  });
  reset_password(username, password, token)
    .then((res) => {
      dispatch({
        type: AUTH.RESET_PASSWORD,
        payload: {
          sent: res.data,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: AUTH.RESET_PASSWORD,
        payload: { sent: false },
      });
      console.log("error", error);
    });
};

export const resetResetPasswordStateAction = () => async (dispatch) => {
    dispatch({
      type: AUTH.RESET_RESET_PASSWORD_STATE,
      payload: false,
    });
  };

export default resetPasswordAction;
