const componentStyles = (theme) => ({
  titleGrid: {
    display: "flex"
  },
  container: {
    marginBottom: "14px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "44px",
  },
  subsection: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.mediumDarkBlue,
    cursor: "pointer",
    textDecorationLine: "underline",
    "&:hover": {
      fontWeight: "600",
    }
  },
  separator: {
    fontFamily: "FontAwesome",
    fontStyle: "normal",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[600],
    marginLeft: "12px",
    marginRight: "12px",
    "& .MuiSvgIcon-root": {
      width: "10px",
      height: "10px",
    },
  },
  section: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.black.main,
  },
  homepageIcon: {
    width: "23px",
    height: "23px",
  },
  homepageButton: {
    marginRight: "-10px",
  }
});

export default componentStyles;