import React from "react";
//material-ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
//custom components
import { CustomDialogTitle } from "components/Dialogs/CustomDialogTitle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  dialogContent: {
    width: "100%",
    height: "100%",
  },
}));

export default function GenericDialog(props) {
  const classes = useStyles();

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="generic-dialog-v2"
    >
      <CustomDialogTitle onClose={props.onClose} />
      <DialogContent className={classes.dialogContent}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
}
