import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
  },
  cardRootDark: {
    backgroundColor: theme.palette.dark.main,
    "& *": {
      color: theme.palette.white.main,
    },
    "& $tableCellRoot, & $tableRoot": {
      color: theme.palette.white.main,
      borderColor: theme.palette.dark.tableBorder,
    },
    "& $tableCellRootHead": {
      color: theme.palette.dark.tableHeadColor,
      backgroundColor: theme.palette.dark.tableHeadBgColor,
    },
  },
  cardHeader: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "25px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  cardActionsRoot: {
    paddingBottom: "1.5rem!important",
    paddingTop: "1.5rem!important",
    borderTop: "0!important",
  },
  width10: {
    width: "10%",
  },
  width15: {
    width: "15%",
  },
  tableButtons: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },

  tableButtonsDisable: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.info.grey,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.info.grey,
    textTransform: "uppercase",
  },

  align: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
  searchTotalBox: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.greyBlue,
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  tableRoot: {
    marginBottom: "0!important",
  },
  tableCellRoot: {
    verticalAlign: "middle",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    borderTop: "0",
    fontSize: ".75rem",
    padding: ".25rem",
  },
  tableCellRootHead: {
    backgroundColor: theme.palette.secondary.lightSkyBlue,
    color: theme.palette.gray[900],
    fontSize: ".7rem !important",
    fontWeight: "650",
    textTransform: "none !important",
  },
  tableCellRootHeadMultiple: {
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[600],
    fontSize: ".7rem !important",
    fontWeight: "650",
    textTransform: "none !important",
  },
  tableCellRootBodyHead: {
    textTransform: "unset!important",
    fontSize: ".75rem",
  },
  tableCellHeadStickyRight: {
    textAlign: "center",
    position: "sticky",
    right: 0,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    boxShadow: "inset 2px 0" + theme.palette.gray[200],
  },
  tableCellBodyStickyRight: {
    position: "sticky",
    right: 0,
    backgroundColor: theme.palette.white.main,
    textAlign: "center",
    boxShadow: "inset 2px 0" + theme.palette.gray[200],
  },
  linearProgressRoot: {
    height: "3px!important",
    width: "120px!important",
    margin: "0!important",
  },
  bgGradientError: {
    background:
      "linear-gradient(87deg," +
      theme.palette.error.main +
      ", " + theme.palette.primary.main + ")!important",
  },
  bgGradientSuccess: {
    background:
      "linear-gradient(87deg," +
      theme.palette.success.main +
      ", " + theme.palette.primary.mediumBlue + " )!important",
  },
  bgGradientPrimary: {
    background:
      "linear-gradient(87deg," +
      theme.palette.primary.main +
      ", " + theme.palette.primary.greyBlue + ")!important",
  },
  bgGradientInfo: {
    background:
      "linear-gradient(87deg," +
      theme.palette.info.main +
      ", " + theme.palette.primary.mediumBlue + " )!important",
  },
  bgGradientWarning: {
    background:
      "linear-gradient(87deg," +
      theme.palette.warning.main +
      "," + theme.palette.primary.mediumBlue + ")!important",
  },
  bgPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  bgPrimaryLight: {
    backgroundColor: theme.palette.primary.light,
  },
  bgError: {
    backgroundColor: theme.palette.error.main,
  },
  bgErrorLight: {
    backgroundColor: theme.palette.error.light,
  },
  bgWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  bgWarningLight: {
    backgroundColor: theme.palette.warning.light,
  },
  bgInfo: {
    backgroundColor: theme.palette.info.main,
  },
  bgInfoLight: {
    backgroundColor: theme.palette.info.light,
  },
  bgSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  verticalAlignMiddle: {
    verticalAlign: "middle",
  },
  avatarRoot: {
    width: "35px",
    height: "35px",
    fontWeight: 600,
    fontSize: "12.25px",
    lineHeight: "17px",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
  bgTransparent: {
    backgroundColor: theme.palette.transparent.main,
  },
  cardHeaderAction: {
    marginTop: "0px !important",
  },
  arrowAction: {
    color: "black !important",
  },
  cardHeaderCounter: {
    fontSize: "2.625rem !important",
  },
  actionsCell: {
    textAlign: "right",
  },
  tableActionButtonsRoot: {
    fontSize: ".765rem",
    marginRight: "10px",
  },
  noResultsInfo: {
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "20px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.white.main,
    marginLeft: "50px",
    marginBottom: "20px",
  },
  noResultsInfoColored: {
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "20px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  noResultsInfoInTable: {
    margin: "20px",
  },
  rowEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth:"175px"
  },

  editButton: {
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    width: "100%",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    textTransform: "uppercase",
    marginTop:"5px"
  },
});

export default componentStyles;
