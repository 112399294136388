import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import "moment/locale/pt";
import moment from "moment";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { FormGroup } from "@material-ui/core";

import {
  createUserUnemploymentHistoryAction,
  updateUserUnemploymentHistoryAction,
} from "redux/actions/auth/userAction";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";
import FormInput from "components/FormFields/FormInput";
import FormDatePicker from "components/Forms/FormDatePicker";
import formatDate from "utils/date_utils";
import useTokenHelper from "hooks/useTokenHelper";

export default function AddUnemploymentHistoryModal({ data, editing = false, onCloseModal, onSuccess }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const tokenHelper = useTokenHelper();

  const defaultValues = useMemo(() => {
    if (editing) {
      return {
        ...data,
        startDate: moment(data.startDate),
        endDate: data.endDate ? moment(data.endDate) : "",
      };
    }

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  async function onSubmit(data) {
    const dataToSubmit = {
      ...data,
      unemploymentHistoryEntryId: data?.id ?? null,
      FO_UserSingularId: tokenHelper.getUserId(),
      startDate: formatDate(data.startDate),
      endDate: formatDate(data.endDate),
      observations: data.observations,
    };

    if (editing) {
      await dispatch(
        updateUserUnemploymentHistoryAction(dataToSubmit, () => {
          onCloseModal();
          onSuccess();
        })
      );
    } else {
      await dispatch(
        createUserUnemploymentHistoryAction(dataToSubmit, () => {
          onCloseModal();
          onSuccess();
        })
      );
    }
  }

  const title = useMemo(() => {
    const titleSufix = intl.formatMessage(
      labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentRecord
    );

    let formatedTitle = "";

    if (editing) {
      formatedTitle = `${intl.formatMessage(
        labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Edit
      )} ${titleSufix}`;
    } else {
      formatedTitle = `${intl.formatMessage(
        labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Add
      )} ${titleSufix}`;
    }

    return formatedTitle;
  }, [editing, intl]);

  return (
    <Container>
      <Box>
        <h2 style={{ textAlign: "center" }}>{title}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                name="startDate"
                label={intl.formatMessage(
                  labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_StartDate
                )}
                control={control}
                getValues={getValues}
                trigger={trigger}
                endDateFieldName="endDate"
                maxDateToday
                isRequired
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                name="endDate"
                label={intl.formatMessage(
                  labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EndDate
                )}
                control={control}
                getValues={getValues}
                trigger={trigger}
                startDateFieldName="startDate"
                maxDateToday
                disabled={isSubmitting}
              />
            </Grid>
          </Grid>

          <FormInput
            name="observations"
            label={intl.formatMessage(
              labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Observations
            )}
            control={control}
            disabled={isSubmitting}
            multiline
            rows={3}
          />

          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <GenericButton typeSubmit={false} color="secondary" onClick={onCloseModal}>
                  {intl.formatMessage(labels.Label_Button_Cancel)}
                </GenericButton>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <GenericButton typeSubmit={true} color="primary" loading={isSubmitting}>
                  {intl.formatMessage(labels.Label_Button_Submit)}
                </GenericButton>
              </FormGroup>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
}
