import { login } from "../../../crud/auth.crud";
import { createCookie } from "utils/auth";
import { AUTH_COOKIE_NAME } from "utils/const";
import { CONFIRMATION_ACTIONS } from "utils/const";
import { AUTH } from "utils/actionsConsts";
import { showAuthenticationModalAction } from "./authenticationAction";
import { b64ToImage } from "utils/converters";

export const loginAction = (email, password) => async (dispatch) => {
  dispatch({
    type: AUTH.LOGIN_LOADING, 
    payload: true,
  });
  login(email, password)
    .then((res) => {
      dispatch({
        type: AUTH.LOGIN,
        payload: {
          email: res.data.email,
          phoneNumber: res.data.phoneNumber,
          succeeded: res.data.succeeded,
          token: res.data.jwtToken,
          refreshToken: res.data.refreshToken,
          name: res.data.name,
          lastName: res.data.lastName,
          photo: b64ToImage(res.data.photo),
          mustConfirmPhone: res.data.mustConfirmPhone,
          mustConfirmEmail: res.data.mustConfirmEmail,
          operationsList: res.data.operationsList,
          userType: res.data.userType,
          login_message: "",
          lastLoginDate : res.data.lastLoginDate
        },
      });
      dispatch({
        type: AUTH.LOGIN_UNNAPROVED,
        payload: {
          login_message: null
        },
      });
      //Create cookie to be accessible by SHAPE
      createCookie(AUTH_COOKIE_NAME, res.data.jwtToken, 1);
      if (res.data.mustConfirmPhone)
        dispatch(
          showAuthenticationModalAction(
            true,
            res.data.phoneNumber,
            CONFIRMATION_ACTIONS.CONFIRMATION
          )
      );
    })
    .catch((error) => {
      dispatch({
        type: AUTH.LOGIN,
        payload: false,
      });
      dispatch({
        type: AUTH.LOGIN_LOADING, 
        payload: false,
      });
      dispatch({
        type: AUTH.LOGIN_UNNAPROVED,
        payload: {
          login_message: error.response?.data?.errors
            ? error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0]
            : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
        },
      });
      console.log("error", error);
    });
};

export const setTargetPathAfterLoginAction = (targetPath, pathUserType) => async (dispatch) => {
  dispatch({
    type: AUTH.SET_TARGET_PATH_AFTER_LOGIN,
    payload: {
      targetPath,
      pathUserType
    },
  });
};
