import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";
import { DISPLAY_DATA_CONTENT_TYPE } from "utils/const";

function OrganicStatus() {
  const intl = useIntl();

  const organicStatusData = [
    {
      title: intl.formatMessage(
        labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_2
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_3
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_1
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_1_Description
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_2
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_2_Description
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_3
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_3_Description
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_4
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Article_4_Description
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_4
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_PresidentialDecree_Description_5
        ) + "\n\n",
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Description_1
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_1
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_1_Description
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_2
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_2_Description
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_3
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_3_Description
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_4
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_4_Description
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_9
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_10
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_11
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_12
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_13
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_1_Article_5_Description_BulletPoint_14
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Description_1
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_1_BulletPoint_4
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_2_BulletPoint_3
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_2_Article_6_Description_3_BulletPoint_6
          ),
        },
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_2
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_2_BulletPoint_4
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_3_BulletPoint_3
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_4
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_5
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_6
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_7_Description_7_BulletPoint_3
          ),
        },
        "\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_2_BulletPoint_8
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_8_Description_3
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_2_BulletPoint_6
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_3_BulletPoint_5
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_9_Description_4
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_2
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_3_BulletPoint_4
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_10_Description_4
        ) + "\n\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_3
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_4
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_9
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_10
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_2_BulletPoint_11
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_11_Description_3
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_2_BulletPoint_9
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_3
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_1
        ),
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_9
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_10
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_11
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_12
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_13_Description_2_BulletPoint_13
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_12_Description_3
        ) + "\n\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_5
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_6
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_2_BulletPoint_9
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_14_Description_3
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_2_BulletPoint_9
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_15_Description_3
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_8
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_9
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_10
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_11
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_12
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_13
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_14
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_2_BulletPoint_15
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_16_Description_3
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_2_BulletPoint_8
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_17_Description_3
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_2_BulletPoint_7
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_18_Description_3
        ) + "\n\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_7
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Description_8
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_19
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_19_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_19_Description_2
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_3
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_4
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_5
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_6
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_7
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_1_BulletPoint_8
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_3_Article_20_Description_2
        ) + "\n\n",
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Description_1
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1
        ),
        {
          type: DISPLAY_DATA_CONTENT_TYPE.FIRST_BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1_BulletPoint_1
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1_BulletPoint_2
          ),
        },
        {
          type: DISPLAY_DATA_CONTENT_TYPE.BULLET_POINT,
          description: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_21_Description_1_BulletPoint_3
          ),
        },
        "\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_22
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_22_Description_1
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_23
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_4_Article_23_Description_1
        ) + "\n\n",
      ],
    },
    {
      title: intl.formatMessage(
        labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5
      ),
      content: [
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Description_1
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24_Description_1
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24_Description_2
        ) + "\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_24_Description_3
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_25
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_25_Description_1
        ) + "\n\n",
        {
          type: DISPLAY_DATA_CONTENT_TYPE.SUBTITLE,
          subtitle: intl.formatMessage(
            labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_26
          ),
        },
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Article_26_Description_1
        ) + "\n\n\n",
        intl.formatMessage(
          labels.Label_Menu_InstitutionalInformation_OrganicStatus_OrganicStatusINEFOP_Chapter_5_Description_2
        ),
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(
        titles.Title_Menu_InstitutionalInformation_OrganicStatus
      )}
      data={organicStatusData}
    />
  );
}

export default OrganicStatus;
