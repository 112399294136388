import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { InputBase, IconButton } from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

// core components
import componentStyles from "assets/theme/components/search-bar";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import GenericButton from "components/Buttons/GenericButton";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function SearchBar(props) {
  const classes = { ...useStyles(), ...useStylesGeneric() };
  const history = useHistory();
  const [searchValue, setSearchValue] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  //Hook that alerts clicks outside of the passed ref
  function ClickOutsideComponent(ref) {
    useEffect(() => {
      //Alert if clicked outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenDropdown(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  ClickOutsideComponent(wrapperRef);

  const handleSearch = () => {
    setOpenDropdown(false);
    history.push(props.resultsPagePath + searchValue);
  };

  const handleChangeSearchValue = (newValue) => {
    setSearchValue(newValue);
  };

  return (
    <div ref={wrapperRef}>
      <IconButton
        classes={{
          root: classes.menuSearchIconButton,
        }}
        onClick={() => {
          setOpenDropdown(!openDropdown);
        }}
        data-testid="search-bar-main-search-button"
      >
        <SearchIcon
          classes={{
            root: classes.searchIconRoot,
          }}
        />
      </IconButton>

      <div
        className={classes.searchBox}
        style={{
          opacity: !openDropdown ? "0" : "1",
          transition: "all 0.5s ease-in-out",
          visibility: !openDropdown ? "hidden" : "visible",
        }}
      >
        <div className={classes.searchBoxTitle}>{props.title}</div>

        <div className={classes.searchSection}>
          <div className={classes.searchBar}>
            <InputBase
              placeholder={props.placeholder}
              classes={{
                root: classes.inputRoot,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => handleChangeSearchValue(e?.target?.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          </div>

          <div className={classes.searchIconDiv}>
            <div className={classes.searchFieldDiv} style={{ marginRight: "-10px" }}>
              <IconButton
                classes={{
                  root: classes.searchIconButton,
                }}
                onClick={handleSearch}
                data-testid="search-bar-search-button"
              >
                <SearchIcon
                  classes={{
                    root: classes.searchIconRoot,
                  }}
                />
              </IconButton>
            </div>
          </div>
        </div>

        <div className={classes.faqsDiv}>
          {props.frequentlyAskedQuestionsList &&
            props.frequentlyAskedQuestionsList.length > 0 &&
            props.frequentlyAskedQuestionsList?.slice(0, 3).map((faq, key) => (
              <div key={key}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  component={Link}
                  to={"/quick-guides/" + faq.path}
                  target="_blank"
                  classes={classes.faqButton}
                >
                  {faq.title}
                </GenericButton>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(SearchBar);
