import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

//request needs to be changed from API side 
export const POST_FRONTOFFICE_ENTERPRISE_PROFILE_UPDATE_URL = "User/update-frontoffice-user{id}";

export function frontOfficeEnterpriseProfileUpdate(formData) {
  return axios.post(EXTERNAL_API_URL + POST_FRONTOFFICE_ENTERPRISE_PROFILE_UPDATE_URL , {
    formData
  });
}

