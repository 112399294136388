import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Carousel from "react-material-ui-carousel";

import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import componentStylesLandingPage from "assets/theme/components/landingPage/landing-page.js";
import GenericButton from "components/Buttons/GenericButton";
import { getStatisticalDataAction } from "redux/actions/statisticalDataAction";
import { labels } from "resources/resources";
import useWindowSize from "hooks/useWindowSize";

function Statistics(props) {
  const useStylesLandingPage = makeStyles(componentStylesLandingPage);

  const classes = useStylesLandingPage();
  const intl = useIntl();
  const history = useHistory();
  const theme = useTheme();
  const windowSize = useWindowSize();

  const [statisticsList, setStatisticsList] = useState([]);
  const [currentBreakpoint, setCurrentBreakpoint] = useState("xs");

  useEffect(() => {
    props.getStatisticalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentBreakpoint(getCurrentBreakpoint(windowSize?.width));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize?.width]);

  useEffect(() => {
    separateStatisticsInGroups(
      props.statisticalDataState?.getStatisticalData_result,
      currentBreakpoint
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBreakpoint,
    props.statisticalDataState?.getStatisticalData_result,
  ]);

  const getCurrentBreakpoint = (windowSz) => {
    if (windowSz < theme.breakpoints.values.sm) {
      return "xs";
    } else if (windowSz < theme.breakpoints.values.md) {
      return "sm";
    } else if (windowSz < theme.breakpoints.values.lg) {
      return "md";
    } else if (windowSz < theme.breakpoints.values.xl) {
      return "lg";
    } else {
      return "xl";
    }
  };

  const getNumberOfElementsByScreenSize = (screenSize) => {
    if (screenSize === "xs") {
      return 1;
    } else if (screenSize === "sm") {
      return 2;
    } else {
      return 4;
    }
  };

  const separateStatisticsInGroups = (statsList, screenSize) => {
    const numberOfElements = getNumberOfElementsByScreenSize(screenSize);

    const statisticsGroups = [];
    let auxStatisticsGroup = [];

    statsList?.forEach((statistics) => {
      if (auxStatisticsGroup.length < numberOfElements) {
        auxStatisticsGroup.push(statistics);
      } else {
        statisticsGroups.push(auxStatisticsGroup);
        auxStatisticsGroup = [statistics];
      }
    });

    if (auxStatisticsGroup.length > 0) {
      statisticsGroups.push(auxStatisticsGroup);
    }

    setStatisticsList(statisticsGroups);
  };

  return (
    <Container
      maxWidth={false}
      className={classes.homepageSectionContainer}
      style={{ height: "500px" }}
    >
      <img
        src={"/images/statistics/INEFOP_Training.png"}
        alt={"INEFOP_Training"}
        className={classes.statisticsSectionBackgroundImage}
      />

      <div className={classes.statisticsSectionMainDiv}>
        <Typography
          component="h5"
          variant="h5"
          className={
            classes.homepageSectionMainHeader +
            " " +
            classes.statisticsSectionTitle
          }
        >
          {currentBreakpoint !== "xl" ? (
            intl.formatMessage(labels.Label_HomePage_Statistics_Description_1) +
            " " +
            intl.formatMessage(labels.Label_HomePage_Statistics_Description_2)
          ) : (
            <>
              <div>
                {intl.formatMessage(
                  labels.Label_HomePage_Statistics_Description_1
                )}
              </div>
              <div>
                {intl.formatMessage(
                  labels.Label_HomePage_Statistics_Description_2
                )}
              </div>
            </>
          )}
        </Typography>

        <GenericButton
          typeSubmit={false}
          color="orange"
          onClick={() =>
            history.push(
              (props.authState?.loggedIn ? "/admin" : "/auth") + "/statistics"
            )
          }
          style={{
            marginTop: "30px",
          }}
        >
          {intl.formatMessage(labels.Label_See_Data)}
        </GenericButton>
      </div>

      <div className={classes.statisticsSectionStatisticsCard}>
        {statisticsList && statisticsList?.length > 0 && (
          <Carousel
            height="120px"
            navButtonsAlwaysInvisible={true}
            className={classes.indicatorIconButton}
            IndicatorIcon={
              <FiberManualRecordIcon className={classes.indicatorIcon} />
            }
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: theme.palette.gray[200],
              },
            }}
          >
            {statisticsList?.map((statisticsGroup, key) => (
              <div key={key}>
                <Grid container key={key} style={{ margin: "30px 10px" }}>
                  {statisticsGroup?.map((data, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <div className={classes.statisticsMetric}>
                        +{data.metric?.toLocaleString("pt")}
                      </div>
                      <div className={classes.statisticsMetricDescription}>
                        {data.description}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getStatisticalData: () => dispatch(getStatisticalDataAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
