const componentStyles = (theme) => ({
    searchBox: {
        backgroundColor: theme.palette.blue.dark, 
        padding: "20px 20px 30px 20px",
        marginTop: "5px",
        left: "0",
        position: "absolute",
        width: "100%"
    },
    searchBoxTitle: {
        fontFamily: "PoppinsRegular",
        fontSize: "30px",
        fontWeight: "bold",
        margin: "5px 0px 25px 0px",
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            fontSize: "24px",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
    searchSection:{
        display: "flex",
        margin: "auto",
        width: "800px",
        [theme.breakpoints.down("md")]: {
            width: "100%"
        },
    },
    searchBar: {
        width: "100%",
        borderRadius: "10px",
        background: theme.palette.white.main,
    },
    searchIconRoot: {
        height: "25px",
        width: "25px",
        padding: "2px"
    },
    searchIconButton: {
        padding: "5px",
        marginTop: "3px",
        marginLeft: "10px",
        color: theme.palette.white.main,
        border: "1px solid " + theme.palette.white.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.greyBlue,
        },
        "&:active": {
            backgroundColor: theme.palette.primary.greyBlue,
        },
    },
    menuSearchIconButton: {
        padding: "5px",
        marginTop: "5px",
        color: theme.palette.primary.darkBlue,
    },
    inputRoot: {
        color: 'inherit',
        width: "100%",
        "& .MuiInputBase-input": {
            border: "none"
        },
    },
    searchFieldDiv: {
        overflow: "hidden",
    },
    searchIconDiv: {
        float: "right",
        marginLeft: "5px",
    },
    informationTypeChipRoot: {
        display: "table-cell",
        color: theme.palette.white.main,
        padding: "5px",
        textTransform: "uppercase"
    },
    faqsDiv:{
        [theme.breakpoints.up("md")]: {
            display: "flex",
            justifyContent: "center",
        },
    },
    faqButton:{
        marginBottom: "-20px",
        fontSize: "10px",
        textTransform: "none",
        textAlign: "left",
        color: theme.palette.white.main,
        "&:hover": {
            color: theme.palette.white.main,
            fontWeight: "bold"
        },
        "&:focus": {
            color: theme.palette.white.main,
            fontWeight: "bold"
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "center",
        },
    }
  });
  
  export default componentStyles;
  