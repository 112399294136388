import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

// core components
import componentStyles from "assets/theme/components/cards/dashboard/card-dashboard-generic.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { useTheme } from "@material-ui/core/styles";
import { getUserCollectiveRecentOpportunityApplicationsInternshipDashboardAction } from "redux/actions/dashboardAction";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import { TABLE_STATE_CHIP_COLOR, OPPORTUNITY_CANDIDATE_STATE_KEY, OPPORTUNITY_TYPE_KEY } from "utils/const";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = ["Nome da Oferta", "Candidato", "Nº Candidatura", "Estado", ""];

function CardMyOpportunityApplicationsInternship(props) {
  const classes = { ...useStylesGeneric(), ...useStyles() };
  const theme = useTheme();

  useEffect(() => {

    props.getUserCollectiveRecentOpportunityApplicationsInternshipDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapRecentApplicationsDashboardChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_STATE_KEY.EMRECRUTAMENTO:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.REJEITADA:
        return TABLE_STATE_CHIP_COLOR.RED;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.APROVADA:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.DESISTENCIA:
        return TABLE_STATE_CHIP_COLOR.BLUE;
      default:
        return <></>
    }
  };

  return (
    <CardTableContainer
      title={"Candidaturas em Curso a Ofertas de Estágio"}
      count={props.dashboardState?.getUserCollectiveRecentOpportunityApplicationsInternshipDashboard_result?.info?.totalApplicationsCount
        ? props.dashboardState?.getUserCollectiveRecentOpportunityApplicationsInternshipDashboard_result?.info?.totalApplicationsCount
        : 0}
      viewMoreLink={`../admin/user-colective-opportunity-applications/${OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL_URL}`}
    >
      {props?.dashboardState?.getUserCollectiveRecentOpportunityApplicationsInternshipDashboard_result?.info?.opportunityCandidateDashboardCard !== undefined &&
        props?.dashboardState?.getUserCollectiveRecentOpportunityApplicationsInternshipDashboard_result?.info?.opportunityCandidateDashboardCard?.length > 0 && (
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.dashboardState?.getUserCollectiveRecentOpportunityApplicationsInternshipDashboard_result?.info?.opportunityCandidateDashboardCard?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableRowEllipsis }} style={{ maxWidth: "140px" }}>
                      {prop.title}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableRowEllipsis }} title={prop.candidateName} style={{ maxWidth: "140px" }}>
                      {prop.candidateName}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableRowEllipsis }} style={{ maxWidth: "140px" }}>
                      {prop.applicationCode}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: classes.tableCellRoot + " " + classes.actionsCell,
                      }}
                    >
                      <Box paddingTop=".35rem" paddingBottom=".35rem">
                        <TableStateChip
                          state={mapRecentApplicationsDashboardChipColors(prop.state?.keyValue)}
                          message={prop.state?.description}
                        />
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Tooltip title="Detalhes">
                        <IconButton
                          aria-label="details"
                          component={Link}
                          to={`candidate-details/${prop.applicationCode}`}
                        >
                          <Box
                            component={ArrowForwardIosIcon}
                            width="9px!important"
                            height="9px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>
          </TableContainer>
        )}
    </CardTableContainer>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUserCollectiveRecentOpportunityApplicationsInternshipDashboard: () => dispatch(getUserCollectiveRecentOpportunityApplicationsInternshipDashboardAction(OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardMyOpportunityApplicationsInternship);
