import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Typography } from "@material-ui/core";

import componentStyles from "assets/theme/components/landingPage/landing-page.js";
import { titles, labels } from "resources/resources";
import CustomCarousel from "components/Carousel/CarouselIndex";
import StructuringProjectCard from "../Components/StructuringProjectCard";
import GenericButton from "components/Buttons/GenericButton";

function AllProjects(props) {
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  return (
    <Container maxWidth={false} className={classes.homepageSectionContainer}>
      <Typography
        component="h5"
        variant="h5"
        className={classes.homepageSectionMainHeader}
        data-testid="all-projects-section-title"
      >
        {intl.formatMessage(titles.Title_HomePage_StructuringProjects)}

        <GenericButton
          typeSubmit={false}
          color="ghost"
          onClick={() => {
            history.push(
              (props.authState?.loggedIn ? "/admin" : "/auth") +
                "/structuringProjects"
            );
          }}
          classes={classes.seeMorePostsButton}
        >
          {intl.formatMessage(labels.Label_See_All_M)}
        </GenericButton>
      </Typography>

      <div className={classes.structuringProjectSectionDescription}>
        {intl.formatMessage(
          labels.Label_HomePage_StructuringProjects_Description
        )}
      </div>

      {props.structuringProjects?.length > 0 ? (
        <Grid container style={{ position: "relative" }}>
          <CustomCarousel
            ComponentCard={StructuringProjectCard}
            itemsList={props.structuringProjects}
            activateNavigation={true}
          />
        </Grid>
      ) : (
        <Box
          classes={{ root: classes.noResultsInfo }}
          style={{ marginTop: "20px" }}
        >
          {intl.formatMessage(labels.Label_No_Results_Found)}
        </Box>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(AllProjects);
