import VerificationInput from "react-verification-input";
import { useIntl } from "react-intl";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { messages } from "resources/resources";
import componentStylesVerificationInput from "assets/theme/components/formFields/formVerificationInput";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylescomponentStylesVerificationInput = makeStyles(componentStylesVerificationInput);
const useStylescomponentStylesGeneric = makeStyles(componentStylesGeneric);

export default function FormVerificationInput({ length, handleOnChange, value, showError }) {
  const intl = useIntl();
  const classes = { ...useStylescomponentStylesGeneric(), ...useStylescomponentStylesVerificationInput() };

  return (
    <Box>
      <VerificationInput
        length={length}
        placeholder=""
        value={value}
        onChange={handleOnChange}
        classNames={{
          container: classes.formVerificationInputContainer,
          character: classes.formVerificationInputCharacter,
          characterInactive: classes.formVerificationInputInactiveCharacter,
          characterSelected: classes.formVerificationInputSelectedCharacter,
        }}
      />

      {showError && (
        <Box className={classes.errorMessage + " " + classes.formVerificationInputErrorMessage}>
          {intl.formatMessage(messages.Message_Generic_MandatoryField)}
        </Box>
      )}
    </Box>
  );
}
