import React from "react";
//redux
import { connect } from "react-redux";
//redux-actions

//@material-ui/core
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
//@material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//custom components
import { rejectOpportunityCandidateAction } from "redux/actions/opportunitiesAction";
import RejectCandidateSection from "../Sections/RejectCandidateSection";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";

//styles
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function RejectCandidateDialog(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = methods;

  //GET MOTIVES
  React.useEffect(() => {}, []);

  const onSubmitReject = (data) => {
    let filter = {
      candidateId: props.candidateId,
      opportunityId: props.opportunityId,
      rejectMotiveId: data?.motive?.code,
      rejectMotiveObservations: data?.notes,
    };
    props.rejectOpportunityCandidate(filter, props.opportunitiesState.isOpportunityApplication);
  };

  return (
    <GenericDialog name={props.name} open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitReject)}>
          <Box className={classes.helpIconBox}>
            <HelpOutlineIcon className={classes.helpIconRoot} />
          </Box>

          <RejectCandidateSection
            getValues={getValues}
            isEditable={true}
            errors={errors}
            classes={classes}
            setValue={setValue}
            theme={theme}
            trigger={trigger}
          ></RejectCandidateSection>
          <Box textAlign="center" marginBottom="50px" marginTop="50px">
            <GenericButton
              typeSubmit={true}
              color="primary"
              loading={props.opportunitiesState.rejectCandidate_loading}
            >
              Submeter
            </GenericButton>
          </Box>
        </form>
      </FormProvider>
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  rejectOpportunityCandidate: (formData, isOpportunityApplication) => dispatch(rejectOpportunityCandidateAction(formData, isOpportunityApplication)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectCandidateDialog);
