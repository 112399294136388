import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// @material-ui/icons components

// core components
import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminNavbarAlternative from "components/Navbars/AdminNavbarAlternative.js";
import AdminFooter from "components/Footers/AdminFooter.js";

import routes from "routes.js";

import componentStyles from "assets/theme/layouts/admin.js";
import GradientHeader from "components/Headers/GradientHeader.js";

const useStyles = makeStyles(componentStyles);

const Search = (props) => {
  const classes = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/search") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (props.authState.loggedIn === undefined || !props.authState.loggedIn) {
    return <Redirect to="/auth/home" />;
  } else {
    return (
      <>
        <Box>
          <Box position="relative" flex="1" className={classes.mainContent}>
            {location.pathname === "/admin/alternative-dashboard" ? (
              <AdminNavbarAlternative/>
            ) : (
              <AdminNavbar/>
            )}
            <GradientHeader />
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/admin/home" />
            </Switch>
          </Box>

          <AdminFooter />
        </Box>
      </>
    );
  }
};

// export default Auth;
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, {})(Search);
