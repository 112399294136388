import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useFormContext, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import moment from "moment";
import ReactDatetime from "react-datetime";
import clsx from "clsx";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRange from "@material-ui/icons/DateRange";
import FormHelperText from "@material-ui/core/FormHelperText";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

import TableStateChip from "components/Cards/Tables/TableStateChip";
import DisplayField from "components/FormFields/DisplayField"
import { messages, errorMessages } from "resources/resources";
import GenericButton from "components/Buttons/GenericButton";
import { OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY, TABLE_STATE_CHIP_COLOR, REGEX_PATTERNS } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FollowUpDetails(props) {
  const classes = useStylesGeneric();
  const methods = useFormContext();
  const intl = useIntl();
  const theme = useTheme();

  const [isEditableDataSection, setIsEditableDataSection] = useState(props.isEditable);
  const [followUpStartDateState, setFollowUpStartDateState] = useState(true);
  const [followUpEndDateState, setFollowUpEndDateState] = useState(true);

  useEffect(() => {
    if (!props.isEditable) {
      setIsEditableDataSection(false);
    }
  }, [props.isEditable]);

  useEffect(() => {
    if (props.isUserColective){
      methods.setValue(
        "followUpStartDate",
        props.opportunityApplicationFollowUpDetails?.followUpStartDate ? moment(props.opportunityApplicationFollowUpDetails?.followUpStartDate) : null
      );
      methods.setValue(
        "followUpEndDate",
        props.opportunityApplicationFollowUpDetails?.followUpEndDate ? moment(props.opportunityApplicationFollowUpDetails?.followUpEndDate) : null
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunityApplicationFollowUpDetails]);

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE_SIMPLE.test(date)) {
      onChange(date);
    }
  };

  const handleValidSecondDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE_SIMPLEe.test(date)) {
      onChange(date);
    }
  };

  useEffect(() => {
    if (!props.isEditable) {
      setIsEditableDataSection(false);
    }
  }, [props.isEditable]);

  const handleEditDataSection = () => {
    setIsEditableDataSection(true);
    props.handleEditSection();
  };

  const mapOpportunityFollowUpStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.PORINICIAR:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.ADECORRER:
        return TABLE_STATE_CHIP_COLOR.GREEN
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.TERMINADO:
        return TABLE_STATE_CHIP_COLOR.RED
      default:
        return <></>;
    }
  };

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            title: classes.cardHeader,
          }}
          title= "Dados Gerais"
          action={
            <Grid container>
              <Grid item>
                <TableStateChip
                  state={mapOpportunityFollowUpStateToTableChipColors(props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateKey)}
                  message={props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateDescription}
                />
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            <DisplayField xs={12} sm={4} md={4} label="Empresa" data={props.opportunityApplicationFollowUpDetails?.companyName} />
            <DisplayField xs={12} sm={4} md={4} label="Tipo de Oferta" data={props.opportunityApplicationFollowUpDetails?.opportunityTypeDescription} />
            <DisplayField xs={12} sm={4} md={4} label="Sector" data={props.opportunityApplicationFollowUpDetails?.opportunityProfessionalCategory} />
            <DisplayField xs={12} sm={4} md={4} label="Data de Submissão da Candidatura" data={props.opportunityApplicationFollowUpDetails?.applicationSubmissionDate ? moment(props.opportunityApplicationFollowUpDetails?.applicationSubmissionDate).format("DD/MM/YYYY") : ""} />
            <DisplayField xs={12} sm={4} md={4} label="Candidato" data={props.opportunityApplicationFollowUpDetails?.userName} />
            <DisplayField xs={12} sm={4} md={4} label="Nível Académico" data={props.opportunityApplicationFollowUpDetails?.userAcademicLevelDescription} /> 
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            title: classes.cardHeader,
          }}
          title={"Datas"}
          action={
           
            <Grid container>
              {props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateKey === OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.PORINICIAR && props.isUserColective &&(
                <Grid item>
                    <GenericButton
                        typeSubmit={false}
                        color="tertiary"
                        size="small"
                        onClick={() => {
                          handleEditDataSection();
                        }}
                    >
                        Alterar Datas
                    </GenericButton>
                </Grid>
              )}
            </Grid>
          }
        />
        <CardContent>
          <Grid container>
            {(props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateKey === OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.PORINICIAR && props.isUserColective) ? (
              <>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Data de Estágio Início</FormLabel>
                    <Controller
                      name="followUpStartDate"
                      control={methods.control}
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                        },
                        validate: {
                          isLessThanEndDate: (value) => {
                            if (!methods.getValues("followUpEndDate")) return true;
                            return (
                              (value &&
                                value instanceof moment &&
                                methods.getValues("followUpEndDate") &&
                                methods.getValues("followUpEndDate") instanceof moment &&
                                value.startOf("day") < methods.getValues("followUpEndDate")?.startOf("day")) ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateFromLessThanDateToField)
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <ReactDatetime
                          name={name}
                          value={value}
                          onChange={(e) => {
                            setFollowUpStartDateState(true);
                            onChange(e);
                            methods.trigger("followUpEndDate");
                            methods.trigger("followUpStartDate");
                          }}
                          locale="pt"
                          dateFormat={"DD/MM/YYYY"}
                          timeFormat={false}
                          closeOnSelect={true}
                          closeOnTab={true}
                          closeOnClickOutside={true}
                          inputProps={{
                            placeholder: "DD/MM/AAAA",
                            className: "",
                            disabled: !isEditableDataSection,
                          }}
                          renderInput={(dateInputProps) => (
                            <OutlinedInput
                              value={followUpStartDateState ? dateInputProps.value : ""}
                              onClick={isEditableDataSection ? dateInputProps.onClick : undefined}
                              onFocus={isEditableDataSection ? dateInputProps.onFocus : undefined}
                              onKeyDown={isEditableDataSection ? dateInputProps.onKeyDown : undefined}
                              disabled={dateInputProps.disabled}
                              placeholder={dateInputProps.placeholder}
                              type={dateInputProps.type}
                              fullWidth
                              onChange={(e) => {
                                setFollowUpStartDateState(true);
                                handleValidDate(e.target.value, dateInputProps.onChange);
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                                </InputAdornment>
                              }
                              classes={{
                                notchedOutline: clsx({
                                  [classes.borderWarning]: methods.formState.errors["followUpStartDate"] !== undefined,
                                }),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    {methods.formState.errors["followUpStartDate"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {methods.formState.errors["followUpStartDate"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                    
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Data de Estágio Fim</FormLabel>
                    <Controller
                      name="followUpEndDate"
                      control={methods.control}
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                        },
                        validate: {
                          isGreaterThanStartDate: (value) => {
                            if (!methods.getValues("followUpStartDate")) return true;
                            return (
                              (value &&
                                value instanceof moment &&
                                methods.getValues("followUpStartDate") &&
                                methods.getValues("followUpStartDate") instanceof moment &&
                                value.startOf("day") > methods.getValues("followUpStartDate")?.startOf("day")) ||
                              intl.formatMessage(errorMessages.ErrorMessage_Generic_DateToGreaterThanDateFromField)
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <ReactDatetime
                          name={name}
                          value={value}
                          onChange={(e) => {
                            setFollowUpEndDateState(true);
                            onChange(e);
                            methods.trigger("followUpStartDate");
                            methods.trigger("followUpEndDate");
                          }}
                          locale="pt"
                          dateFormat={"DD/MM/YYYY"}
                          timeFormat={false}
                          closeOnSelect={true}
                          closeOnTab={true}
                          closeOnClickOutside={true}
                          inputProps={{
                            placeholder: "DD/MM/AAAA",
                            className: "",
                            disabled: !isEditableDataSection,
                          }}
                          renderInput={(dateInputProps) => (
                            <OutlinedInput
                              disabled={!isEditableDataSection || dateInputProps.disabled}
                              value={followUpEndDateState ? dateInputProps.value : ""}
                              onClick={isEditableDataSection ? dateInputProps.onClick : undefined}
                              onFocus={isEditableDataSection ? dateInputProps.onFocus : undefined}
                              onKeyDown={isEditableDataSection ? dateInputProps.onKeyDown : undefined}
                              placeholder={dateInputProps.placeholder}
                              type={dateInputProps.type}
                              fullWidth
                              onChange={(e) => {
                                setFollowUpEndDateState(true);
                                handleValidSecondDate(e.target.value, dateInputProps.onChange);
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                                </InputAdornment>
                              }
                              classes={{
                                notchedOutline: clsx({
                                  [classes.borderWarning]: methods.formState.errors["followUpEndDate"] !== undefined,
                                }),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    {methods.formState.errors["followUpEndDate"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {methods.formState.errors["followUpEndDate"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
              </>
            ) : (
              <>
                <DisplayField 
                  xs={12} sm={4} md={3} 
                  label="Data de Estágio Início" 
                  data={props.opportunityApplicationFollowUpDetails?.followUpStartDate ? moment(props.opportunityApplicationFollowUpDetails?.followUpStartDate).format("DD/MM/YYYY") : ""}/>
                <DisplayField 
                  xs={12} sm={4} md={3} 
                  label="Data de Estágio Fim" 
                  data={props.opportunityApplicationFollowUpDetails?.followUpEndDate ? moment(props.opportunityApplicationFollowUpDetails?.followUpEndDate).format("DD/MM/YYYY") : ""}/>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(FollowUpDetails);
