import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  containerRoot: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "6rem",
      paddingRight: "6rem",
    },
    paddingTop: "8.5rem",
  },
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    width: "100%",
  },
  pageTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    textTransform: "uppercase",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  boxMargin: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  pageSubtitle: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.grey[900],
    marginBottom: "20px",
  },
  title: {
    fontWeight: 600,
    fontSize: "18px",
    color: theme.palette.primary.main,
    marginBottom: "60px",
    textTransform: "uppercase",
  },
  subtitle: {
    fontStyle: "normal",
    fontSize: "16px",
  },
  content: {
    whiteSpace: "pre-line",
    fontSize: "18px",
  },
  listItem: {
    color: theme.palette.black.main,
    marginTop: "-15px",
    "& .MuiSvgIcon-root": {
      width: "10px",
      height: "10px",
      marginTop: "-2px",
    },
  },
  boxRoot: {
    marginTop: "20px",
    marginBottom: "20px",
    padding: "20px 40px",
    minHeight: "200px",
  },
  titleBox: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.grey[900],
    marginBottom: "20px",
  },
});

export default componentStyles;
