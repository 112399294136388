import { DIALOG, DIALOG_ICON } from "utils/actionsConsts";

export const hideGenericDialogAction = () => async (dispatch) => {
  dispatch({ type: DIALOG.HIDE, payload: false });
};


export const showGenericDialogAction = () => async (dispatch) => {
  dispatch({ type: DIALOG.SHOW, payload: true });
};
export const showGenericTwoButtonDialogAction =
  (message, submessage, yesAction, noAction) => async (dispatch) => {
    dispatch({
      type: DIALOG.SHOW,
      payload: {
        showGenericDialog: true,
        genericDialogData: {
          type: DIALOG.TWO_BUTTON_TYPE,
          isSuccess: false,
          iconType: DIALOG_ICON.QUESTION,
          message: message,
          submessage: submessage,
          yesAction: yesAction,
          noAction: noAction,
        },
      },
    });
  };

export default hideGenericDialogAction;
