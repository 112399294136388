import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { usePasswordValidation } from "hooks/usePasswordValidation";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { messages, titles } from "resources/resources";
//@material-ui/core components
import { makeStyles, useTheme, createTheme, ThemeProvider } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormLabel from "@material-ui/core/FormLabel";
import CardContent from "@material-ui/core/CardContent";
//@material-ui/icons components
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { green, red } from "@material-ui/core/colors";
//custom components
import GenericButton from "components/Buttons/GenericButton";
import CustomDialogTitle from "components/Dialogs/CustomDialogTitle";
//Styles
import componentStyles from "assets/theme/components/modals/auth/authentication-modal.js";
import { changePasswordMap2 } from "mappers/ChangePasswordMap";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import InfoContent from "views/auth/Dialogs/InfoContent";
import { showNewPasswordModalAction } from "redux/actions/auth/authenticationAction";
import resetPasswordAction from "redux/actions/auth/resetPasswordAction";
import { resetResetPasswordStateAction } from "redux/actions/auth/resetPasswordAction";
import ErrorContent from "views/auth/Dialogs/ErrorContent";

const useStyles = makeStyles(componentStyles);

function ResetPassword(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();

  const iconTheme = createTheme({
    overrides: {
      MuiIconButton: {
        root: {
          // This may seem useless but the Icons would not
          // change their fontSize if we didnt do this
          // first.
          fontSize: "20px",
        },
      },
    },
  });

  const getShowModal = () => {
    return props.authState.newPasswordModal !== undefined
      ? props.authState.newPasswordModal.showNewPasswordModal
      : false;
  };

  const handleCloseModal = () => {
    props.showNewPasswordModal(false);
  };

  const handleLoginState = () => {
    history.push("/auth/home");
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

  const [recoverSuccessModal, setRecoverSuccessModal] = useState(false);
  const handleCloseSuccess = () => {
    setRecoverSuccessModal(false);
    props.resetResetState();
  };
  const [recoverErrorModal, setRecoverErrorModal] = useState(false);
  const handleCloseError = () => {
    setRecoverErrorModal(false);
  };

  const [password, setPassword] = useState({
    firstPassword: "",
    secondPassword: "",
  });

  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (props.authState.newPasswordModal?.mobilePhone !== undefined) {
      setUserName(props.authState.newPasswordModal.mobilePhone);
    }
  }, [props.authState.newPasswordModal?.mobilePhone]);

  const [token, setToken] = useState("");

  useEffect(() => {
    if (props.authState.newPasswordModal?.resetToken !== undefined) {
      setToken(props.authState.newPasswordModal.resetToken);
    }
  }, [props.authState.newPasswordModal?.resetToken]);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [passwordValidation] = usePasswordValidation({
    firstPassword: getValues("password"),
    secondPassword: getValues("passwordConfirmation"),
  });

  useEffect(() => {
    if (passwordValidation?.isValid != null) trigger("password");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordValidation?.isValid]);

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };

  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  function changePw(data) {
    if (userName && token && changePasswordMap2(data)) {
      props.resetPassword(userName, changePasswordMap2(data).Password, token);
    }
  }

  useEffect(() => {
    setValue("password", "");
    setValue("passwordConfirmation", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.authState?.reset_ok) {
      setRecoverSuccessModal(true);
    }
  }, [props.authState?.reset_ok]);

  return (
    <>
      <GenericDialog open={getShowModal()} classes={{ paper: classes.modal }}>
        <CardContent classes={{ root: classes.cardContent }}>
          <Box marginTop="-50px">
            <Grid container>
              <Grid item>
                <Box
                  color={theme.palette.gray[600]}
                  textAlign="left"
                  marginBottom="1rem"
                  marginTop="0px"
                  fontSize="2rem"
                >
                  <Box
                    style={{
                      fontSize: "2rem",
                      lineHeight: "3rem",
                      color: theme.palette.primary.greyBlue,
                    }}
                    className={classes.modalHeader}
                  >
                    Alteração de Palavra-passe
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <CustomDialogTitle id="customized-dialog-title" onClose={handleCloseModal} />
              </Grid>
            </Grid>
          </Box>
          <form onSubmit={handleSubmit(changePw)}>
            <Box>
              <Box marginTop="20px">
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormLabel style={{ color: theme.palette.gray[900] }} className={classes.authenticationLabels}>
                        Nova palavra-passe
                      </FormLabel>
                      <Controller
                        name="password"
                        control={control}
                        component={Box}
                        defaultValue=""
                        value={getValues("password")}
                        rules={{
                          required: {
                            value: true,
                            message: "O campo é obrigatório",
                          },
                          validate: {
                            validPassword: () => {
                              if (!passwordValidation.isValid) return "Password inválido";
                              return true;
                            },
                          },
                        }}
                        render={({ field: { onChange, name, value } }) => (
                          <OutlinedInput
                            placeholder={intl.formatMessage(titles.Label_LoginModal_PasswordInputPlaceholder)}
                            name={name}
                            value={value}
                            fullWidth
                            autoComplete="off"
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => {
                              onChange(e);
                              setFirst(e);
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <ThemeProvider theme={iconTheme}>
                                  <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </ThemeProvider>
                              </InputAdornment>
                            }
                          />
                        )}
                      />
                      {errors["password"] !== undefined && (
                        <FormHelperText
                          className={classes.formHelperError}
                          component={Box}
                          color={theme.palette.warning.main + "!important"}
                        >
                          {errors["password"].message}
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormLabel style={{ color: theme.palette.gray[900] }} className={classes.authenticationLabels}>
                        Confirmação da nova palavra-passe
                      </FormLabel>
                      <Controller
                        name="passwordConfirmation"
                        defaultValue=""
                        value={getValues("passwordConfirmation")}
                        control={control}
                        component={Box}
                        rules={{
                          required: {
                            value: true,
                            message: "O campo é obrigatório",
                          },
                          validate: {
                            PasswordMatch: (value) => {
                              return (
                                value === getValues("password") ||
                                "O valor inserido deve ser igual ao campo 'Palavra-passe'"
                              );
                            },
                          },
                        }}
                        render={({ field: { onChange, name, value } }) => (
                          <OutlinedInput
                            placeholder={intl.formatMessage(titles.Label_LoginModal_PasswordInputPlaceholder)}
                            name={name}
                            value={value}
                            fullWidth
                            autoComplete="off"
                            type={showPasswordConfirm ? "text" : "password"}
                            onChange={(e) => {
                              onChange(e);
                              setSecond(e);
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <ThemeProvider theme={iconTheme}>
                                  <IconButton
                                    onClick={handleClickShowPasswordConfirm}
                                    onMouseDown={handleMouseDownPasswordConfirm}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </ThemeProvider>
                              </InputAdornment>
                            }
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]: errors["passwordConfirmation"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />
                      {errors["passwordConfirmation"] !== undefined && (
                        <FormHelperText
                          className={classes.formHelperError}
                          component={Box}
                          color={theme.palette.warning.main + "!important"}
                        >
                          {errors["passwordConfirmation"].message}
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <FormLabel>{intl.formatMessage(messages.Message_Auth_Password_Rules_1)}</FormLabel>
                    <List dense={true}>
                      <ListItem>
                        <Fragment>
                          {passwordValidation.validLength ? (
                            <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                          ) : (
                            <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                          )}
                          {intl.formatMessage(messages.Message_Auth_Password_Rules_2)}
                        </Fragment>
                      </ListItem>
                      <ListItem>
                        <Fragment>
                          {passwordValidation.upperCase ? (
                            <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                          ) : (
                            <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                          )}
                          {intl.formatMessage(messages.Message_Auth_Password_Rules_3)}
                        </Fragment>
                      </ListItem>
                      <ListItem>
                        <Fragment>
                          {passwordValidation.lowerCase ? (
                            <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                          ) : (
                            <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                          )}
                          {intl.formatMessage(messages.Message_Auth_Password_Rules_4)}
                        </Fragment>
                      </ListItem>
                      <ListItem>
                        <Fragment>
                          {passwordValidation.hasNumber ? (
                            <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                          ) : (
                            <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                          )}
                          {intl.formatMessage(messages.Message_Auth_Password_Rules_5)}
                        </Fragment>
                      </ListItem>
                      <ListItem>
                        <Fragment>
                          {passwordValidation.specialChar ? (
                            <CheckCircleOutlinedIcon style={{ marginRight: 8, color: green[500] }} />
                          ) : (
                            <CancelOutlinedIcon style={{ marginRight: 8, color: red[500] }} />
                          )}
                          {intl.formatMessage(messages.Message_Auth_Password_Rules_6)}
                        </Fragment>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>
              <Box textAlign="right" marginBottom="0" marginTop="30px">
                <GenericButton typeSubmit={true} color="primary" loading={props.authState.reset_password_loading}>
                  Submeter
                </GenericButton>
              </Box>
            </Box>
          </form>
        </CardContent>
      </GenericDialog>

      <GenericDialog
        name="recover-success-dialog"
        open={recoverSuccessModal}
        onClose={() => {
          if (!props.sso) handleLoginState();
          handleCloseSuccess();
          handleCloseModal();
        }}
      >
        <InfoContent
          message={"Palavra-passe alterada com sucesso."}
          onClick={() => {
            if (!props.sso) handleLoginState();
            handleCloseSuccess();
            handleCloseModal();
          }}
        />
      </GenericDialog>

      <GenericDialog
        name="recover-error-dialog"
        open={recoverErrorModal}
        onClose={() => {
          if (!props.sso) handleLoginState();
          handleCloseError();
          handleCloseModal();
        }}
      >
        <ErrorContent
          message={"Ocorreu um erro a alterar a palavra-passe."}
          onClick={() => {
            if (!props.sso) handleLoginState();
            handleCloseError();
            handleCloseModal();
          }}
        />
      </GenericDialog>
      {/* </Container> */}
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  showNewPasswordModal: (toShow, username, action) => dispatch(showNewPasswordModalAction(toShow, username, action)),
  resetPassword: (username, password, token) => dispatch(resetPasswordAction(username, password, token)),
  resetResetState: () => dispatch(resetResetPasswordStateAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
