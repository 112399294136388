import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
    border: "0!important",
  },
  listItemRoot: {
    borderBottom: "1px solid " + theme.palette.gray[200],
  },

  cardButtons: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },

  cardButtonsDisable: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.info.grey,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },

  editButton: {
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    width: "50%",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  switchText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },

  switchNotPublished: {
    color: theme.palette.error.badge,
  },

  switchPublished: {
    color: theme.palette.success.snackbar,
  },

  alignEnd: {
    textAlign: "end",
  },

  cardImgTop: {
    borderTopLeftRadius: "calc(.375rem - 1px)",
    borderTopRightRadius: "calc(.375rem - 1px)",
    flexShrink: 0,
    width: "100%",
    verticalAlign: "middle",
    borderStyle: "none",
    height: "175px",
    objectFit: "contain",
    backgroundColor: theme.palette.white.smoke,
  },
  cardImgOverlay: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: "1.25rem",
    borderRadius: "calc(.375rem - 1px)",
  },
  cardLabelTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
  },
  doubleLineTitleEllipsis: { //Já no generic.js com o nome doubleLineEllipsis
    display: "-webkit-box",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": "2",
    lineHeight: "1.5rem",
    height: "3rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardHeaderBorderless: {
    borderBottom: "0px!important"
  },
  cardActionBorderless: {
    borderTop: "0px!important",
    display: "block!important"
  },
  cardContentTitle: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px"
  },
  cardContentSubTitle: {
    fontSize: "14px",
    lineHeight: "27px"
  },
  cardHeaderTitleAlignedEnd: {
    textAlign: "end",
    alignSelf: "normal"
  },
  descriptionSize: {
    height: "6.5rem",
    marginBottom: "1rem",
  },
  tooltip: {
    maxWidth: "280px",
    marginTop: "1px",
    marginBottom: "1px",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.black.light,
    textAlign: "justify",
  },
  tooltipArrow: {
    color: theme.palette.grey[300],
  },
  avatarRoot: {
    width: "66px",
    height: "66px",
    marginRight: "0.5rem",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "21px",
    lineHeight: "29px",
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
    },
  },
  containerFullWidth: {
    width: "100%"
  },
  cardButton: {
    background:
      "linear-gradient(90deg, " +
      theme.palette.info.main +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.white.main,
    width: "100px",
    "&:hover": {
      color: theme.palette.gray[200],
    },
  },
  cardButtonFormGroup: {
    marginBottom: "0px",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  quillOverflowHidden: {
    "& .ql-container .ql-editor": {
      overflowY: "hidden",
    },
  },

  cardChipRoot: {
    display: "table-cell",
    color: theme.palette.white.main,
    padding: "5px",
    borderRadius: "18px",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "900",
    lineHeight: "17px",
  },

  cardChipBox: {
    width: "50%"
  },
  cardChipContainer: {
    display: "flex",
    marginBottom: "10px"
  },

  cardTextAlignedOnEnd: {
    width: "50%",
    textAlign: "end",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.gray[700],

  },
});

export default componentStyles;
