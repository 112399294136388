import React from "react";
import { connect } from "react-redux";

import MainProjectsPreviewCaroussel from "../Components/MainProjectsPreviewCaroussel";

function MainProjects(props) {
  const getHighlightedProjects = () => {
    let highlightedProjects = [];
    if (props.structuringProjects) {
      highlightedProjects = props.structuringProjects.filter(
        (project) => project.isHighlighted
      );
    }
    return highlightedProjects;
  };

  return (
    <div style={{marginBottom: "-33px",}}>
      <MainProjectsPreviewCaroussel
        mediaList={getHighlightedProjects()}
        mediaDetailsPath={(props.authState?.loggedIn ? "/admin" : "/auth") + "/informationDissemination-details/"} 
      />
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(MainProjects);
