//#region Imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { FetchDatasetsOnMount } from "../../../../utils/datasets";
import { dataSetsNeededApplications } from "constants/componentsDatasets";
import {
  getDataSetByNameAction,
  getUserApplicationsAwaitingTrainingAction,
} from "redux/actions/datasetsAction";
import { createApplicationAction } from "redux/actions/applicationsAction";
import { createApplicationMap } from "mappers/CreateApplicationMap";
import { titles } from "resources/resources";
//consts
import { APPLICATIONS_ACTIONS } from "utils/actionsConsts";
import { DATASET, FORM_FIELDS_PREFIX } from "utils/const";
// @material-ui/core components
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// core components
import componentStyles from "assets/theme/views/admin/applications.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
//custom components
import GenericButton from "components/Buttons/GenericButton";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
//Steps
import Programs from "./Sections/Programs";
import Confirmation from "./Sections/Confirmation";
import ComplementaryData from "./Sections/ComplementaryData";
import LocationHeader from "components/Headers/LocationHeader";
//#endregion

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function MainForm(props) {
  FetchDatasetsOnMount(
    props.getDataSetByName,
    props.datasetsState,
    dataSetsNeededApplications
  );
  const classes = {
    ...useStylesGeneric(),
    ...useStyles(),
  };
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const intl = useIntl();
  const [pageState, setPageState] = useState({
    generatedWorkPostsName: "generatedWorkPosts",
    parentApplciationFieldName: "parentApplicationId",
    provinceFieldName: "province",
    programsFieldName: "programId",
    subprogramsFieldName: "subProgramId",
    microcreditBenefitTypeName: "microCreditBenefit",
    bankPreferenceFieldName: "bankPreference",
    professionalKitBenefitTypeName: "professionalKitBenefit",
    docFields: [],
    shapeProgramsFieldName: "shapeProgram",
    shapeSubsiteFieldName: "shapeSubsite",
    internshipFieldName: "internshipArea",
    microcreditValue: 0,
    uploadDocNames: [],
    info: undefined,
    complementaryData: [],
    governmentProgramId: parseInt(
      props.applicationsState.dataForApplicationCreation.governmentProgramId
    ),
    programId: props.applicationsState.dataForApplicationCreation.programId
      ? parseInt(props.applicationsState.dataForApplicationCreation.programId)
      : undefined,
  });

  useEffect(() => {
    props.getUserApplicationsAwaitingTraining(
      DATASET.USER_APPLICATIONS_AWAITING_TRAINING
    );

    props.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageState({
      ...pageState,
      governmentProgramId: parseInt(
        props.applicationsState.dataForApplicationCreation.governmentProgramId
      ),
      programId: props.applicationsState.dataForApplicationCreation.programId
        ? parseInt(props.applicationsState.dataForApplicationCreation.programId)
        : undefined,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applicationsState.dataForApplicationCreation.governmentProgramId]);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    clearErrors,
    unregister,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const showHideFieldsOnChange = (childField, toShow) => {
    setPageState((prevState) => ({
      ...prevState,
      complementaryData: prevState.complementaryData.map((c) =>
        c.id === childField ? { ...c, show: toShow } : c
      ),
    }));
  };

  const setComplementaryData = (_complementaryData) => {
    setPageState({
      ...pageState,
      complementaryData: _complementaryData.map(
        (
          c
        ) => /*TO-DO colocar aqui a condição correta para mostrar ou não o campo no OnMount da página*/ {
          return {
            ...c,
            id: FORM_FIELDS_PREFIX.COMPLEMENTARY_DATA + c.id.toString(),
            show: !c.hasDependencies,
          };
        }
      ),
    });
  };

  const clearComplementaryData = () => {
    setPageState((prevState) => {
      prevState.complementaryData.length = 0;
      return prevState;
    });
  };

  const setMicrocreditValueInfo = (value) => {
    setPageState({ ...pageState, microcreditValue: value });
  };

  const clearSubProgramInfo = () => {
    setPageState({ ...pageState, info: undefined });
  };

  const setSubprogramInfo = (_info) => {
    setPageState({
      ...pageState,
      info: _info,
      docFields: _info?.subProgramDocument?.map((d) => {
        return {
          id: FORM_FIELDS_PREFIX.DOCUMENT + d.id.toString(),
          name: d.name,
        };
      }),
    });
  };

  const getIsLoading = () => {
    if (!props.datasetsState[DATASET.LOADING_DATASETS]) return false;

    return (
      props.datasetsState[DATASET.LOADING_DATASETS][DATASET.PROGRAMS] ||
      props.datasetsState[DATASET.LOADING_DATASETS][DATASET.SUBPROGRAMS] ||
      props.applicationsState?.subprogramsInfo_loading
    );
  };

  const errorsIsEmpty = () => {
    return Object.keys(errors).length === 0 && !isValid;
  };

  const validateActivePageData = (activePage) => {
    if (!errorsIsEmpty()) {
      switch (activePage) {
        case 0:
          return (
            !errors[pageState.generatedWorkPostsName] &&
            !errors[pageState.parentApplciationFieldName] &&
            !errors[pageState.provinceFieldName] &&
            !errors[pageState.programsFieldName] &&
            !errors[pageState.subprogramsFieldName] &&
            !errors[pageState.microcreditBenefitTypeName] &&
            !errors[pageState.bankPreferenceFieldName] &&
            !errors[pageState.professionalKitBenefitTypeName] &&
            validateDocumentFields() &&
            !errors[pageState.shapeProgramsFieldName] &&
            !errors[pageState.shapeSubsiteFieldName] &&
            !errors[pageState.internshipFieldName]
          );
        case 1:
        case 2:
        default:
          return isValid;
      }
    } else {
      return false;
    }
  };

  const validateDocumentFields = () => {
    return !pageState.docFields.some((d) => errors[d.id] !== undefined);
  };

  const onSubmit = (data) => {
    if (activeStep === steps.length - 1) {
      if (isSubmit) {
        const formData = createApplicationMap(data, pageState);
        console.log("formData:", formData);

        props.createApplication(formData);
      } else {
        setIsSubmit(true);
      }
    } else {
      setIsSubmit(false);
    }
  };

  function getSteps() {
    return [
      intl.formatMessage(
        titles.Title_Admin_Applications_MainForm_ProgramSubprograms
      ),
      intl.formatMessage(
        titles.Title_Admin_Applications_MainForm_ComplementaryData
      ),
      intl.formatMessage(titles.Title_Admin_Applications_MainForm_Confirmation),
    ];
  }

  const handleNext = () => {
    const pageIsValid = validateActivePageData(activeStep);
    if (activeStep !== steps.length - 1 && pageIsValid) {
      setTimeout(() => {
        clearErrors();
      }, 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handlePrevious = () => {
    trigger();
    setIsSubmit(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = getSteps();

  function getStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Programs
            isEditable={true}
            control={control}
            setValue={setValue}
            register={register}
            getValues={getValues}
            errors={errors}
            classes={classes}
            theme={theme}
            trigger={trigger}
            pageState={pageState}
            unregister={unregister}
            clearComplementaryData={clearComplementaryData}
            setSubprogramInfo={setSubprogramInfo}
            clearSubProgramInfo={clearSubProgramInfo}
            setMicrocreditValueInfo={setMicrocreditValueInfo}
          />
        );
      case 1:
        return (
          <ComplementaryData
            isEditable={true}
            control={control}
            getValues={getValues}
            errors={errors}
            classes={classes}
            setValue={setValue}
            theme={theme}
            pageState={pageState}
            showHideFieldsOnChange={showHideFieldsOnChange}
            unregister={unregister}
            setComplementaryData={setComplementaryData}
          />
        );
      case 2:
        return (
          <Confirmation
            isEditable={true}
            control={control}
            getValues={getValues}
            setValue={setValue}
            classes={classes}
            theme={theme}
            errors={errors}
            pageState={pageState}
          />
        );
      default:
        return (
          <Programs
            isEditable={true}
            control={control}
            setValue={setValue}
            register={register}
            getValues={getValues}
            errors={errors}
            classes={classes}
            theme={theme}
            trigger={trigger}
            pageState={pageState}
            unregister={unregister}
            clearComplementaryData={clearComplementaryData}
            setSubprogramInfo={setSubprogramInfo}
            clearSubProgramInfo={clearSubProgramInfo}
            setMicrocreditValueInfo={setMicrocreditValueInfo}
          />
        );
    }
  }

  return (
    <>
      <Container
        component={Box}
        className={classes.containerRoot}
        maxWidth="xl"
        paddingBottom="3rem"
        position="relative"
        zIndex="101"
      >
        <Box marginLeft="-30px">
          <LocationHeader
            section="Candidatura PAPE"
          />
        </Box>
        <Box
          className={classes.applicationHeader}
          component={Grid}
          container
          justifyContent="left"
        >
          {intl.formatMessage(titles.Title_Admin_Applications_MainForm)}{" "}
          {/* Goverment programs are always loaded on HomePage and specially on AdminNavbar, so this should always work fine */}
          {
            props.governmentProgramsState?.getGovernmentPrograms_result?.find(
              (gp) =>
                gp.id ===
                parseInt(
                  props.applicationsState.dataForApplicationCreation
                    .governmentProgramId
                )
            )?.value
          }
        </Box>
        <Stepper
          className={classes.stepperRoot}
          activeStep={activeStep}
          alternativeLabel
          data-testid="create-application-stepper"
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit(onSubmit)}>
          {getStepsContent(activeStep)}
          <Grid container>
            <Grid item xs={12} sm={4}>
              {activeStep > 0 && (
                <GenericButton
                  typeSubmit={false}
                  color="secondary"
                  onClick={handlePrevious}
                >
                  Voltar Atrás
                </GenericButton>
              )}
            </Grid>
            <Grid item xs={12} md={4}></Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <GenericButton
                typeSubmit={true}
                color="primary"
                loading={props.applicationsState.applicationGET_SUBMIT_LOADING}
                disabled={
                  pageState.info?.vacanciesIsFull ||
                  pageState.info?.userMustCompleteProfileData
                }
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Confirmar" : "Seguinte"}
              </GenericButton>
            </Grid>
          </Grid>
          <TableBackdrop open={getIsLoading()} />
        </form>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name) => dispatch(getDataSetByNameAction(name)),
  getUserApplicationsAwaitingTraining: (name) =>
    dispatch(getUserApplicationsAwaitingTrainingAction(name)),
  createApplication: (application) =>
    dispatch(createApplicationAction(application)),
  resetForm: () => dispatch({ type: APPLICATIONS_ACTIONS.RESET_FORM }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainForm);
