import { COMPLEMENTARY_DATA_ANSWER_TYPE, BENEFIT, FORM_FIELDS_PREFIX } from "utils/const";

export function ApplicationMap(formData) {
  if (formData !== null && formData !== undefined) {
    return formData;
  }
}

export function getApplicationsMapper(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      userId: "",
      subprogramId: undefined,
      ApplicationState: undefined,
      ApplicationStateGroup: [],
      pageIndex: 1,
      pageSize: 15,
    };
  }
  return formData;
}

export function updateGeneralDataMap(formData, pageInfo) {
  const data = new FormData();
  if (formData !== null && formData !== undefined) {
    const answersData = pageInfo.complementaryData.map((c) => ({
      complementaryDataId: c.complementaryDataId,
      answer:
        c.answerTypeId === COMPLEMENTARY_DATA_ANSWER_TYPE.DATASET
          ? formData[c.id]
            ? formData[c.id].code + ""
            : ""
          : formData[c.id]
          ? formData[c.id]
          : "",
    }));
    const application = {
      provinceId: formData[pageInfo.provinceFieldName] ? formData[pageInfo.provinceFieldName].code : "",
      applicationCode: formData.applicationNumber ? formData.applicationNumber : "",
      applicationStateId: formData.applicationStateId !== undefined ? formData.applicationStateId : "",
      parentApplicationId: formData[pageInfo.parentApplciationFieldName]
        ? formData[pageInfo.parentApplciationFieldName].code
        : "",
      internshipAreaId:
        pageInfo.info.benefitKeyValue === BENEFIT.PROFESSIONAL_INTERNSHIP && formData[pageInfo.internshipFieldName]
          ? formData[pageInfo.internshipFieldName].code
          : "",
      concededMicrocreditAmount:
        formData.concededMicrocreditAmount !== undefined ? formData.concededMicrocreditAmount : "",
      concededProfessionalKitsNumber: formData.concededProfessionalKitsNumber
        ? formData.concededProfessionalKitsNumber
        : "",
      trainingCenterId:
        formData.shapeSubsite !== undefined && formData.shapeSubsite !== null ? formData.shapeSubsite.code : "",
      intendedCourseCode:
        formData.shapeProgram !== undefined && formData.shapeProgram !== null ? formData.shapeProgram.code : "",
      numBI: formData.numBI !== undefined ? formData.numBI : "",
      generatedWorkPosts: formData.generatedWorkPosts !== undefined ? formData.generatedWorkPosts : 0,
      microcreditAmount: formData.microcreditAmount !== undefined ? formData.microcreditAmount : "",
      professionalKitsNumber: formData.professionalKitBenefit !== undefined ? formData.professionalKitBenefit : "",
      bankPreferenceId: formData.bankPreference !== undefined ? formData.bankPreference?.code : "",
    };

    data.append("isBackOffice", false);
    data.append("rejectMotiveId", "");
    data.append("rejectMotiveObservations", "");

    for (const key in application) {
      data.append("application." + key, application[key]);
    }

    let countAnswers = 0;
    answersData?.forEach((element) => {
      data.append(`application.answers[${countAnswers}].complementaryDataId`, element.complementaryDataId);
      data.append(`application.answers[${countAnswers}].answer`, element.answer);
      countAnswers++;
    });

    let countDoc = 0;
    pageInfo?.docFields?.forEach((dt) => {
      if (formData[dt.id] && formData[dt.id][0]) {
        data.append(
          `application.applicationDocuments[${countDoc}].documentTypeId`,
          dt.id?.replace(FORM_FIELDS_PREFIX.DOCUMENT, "")
        );
        data.append(`application.applicationDocuments[${countDoc}].documentId`, countDoc);
        data.append("ApplicationDocuments", formData[dt.id][0]);
        countDoc++;
      }
    });

    return data;
  }

  return formData;
}

export function updateGeneralDataMapCards(formData) {
  const data = new FormData();

  if (formData !== null && formData !== undefined) {
    const application = {
      applicationCode: formData.applicationNumber ? formData.applicationNumber : "",
      applicationStateId: formData.applicationStateId !== undefined ? formData.applicationStateId : "",
    };

    for (const key in application) {
      data.append("application." + key, application[key]);
    }

    data.append("isBackOffice", false);
    data.append(
      "cancelingMotiveObservations",
      formData.cancelingMotiveObservations !== undefined ? formData.cancelingMotiveObservations : ""
    );
    return data;
  }

  return formData;
}

export default ApplicationMap;
