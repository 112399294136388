import React from "react";
import { useIntl } from "react-intl";

import InformationPage from "components/GenericPage/InformationPage";
import { titles, labels } from "resources/resources";

function RatifyAgreement() {
  const intl = useIntl();

  const ratifyAgreementData = [
    {
      title: intl.formatMessage(
        labels.Label_Menu_CompanyServices_RatifyAgreementTitle
      ),
      content: [
        text(
          intl.formatMessage(labels.Label_Menu_CompanyServices_RatifyAgreement1)
        ),
        text(
          intl.formatMessage(labels.Label_Menu_CompanyServices_RatifyAgreement2)
        ),
        text(
          intl.formatMessage(labels.Label_Menu_CompanyServices_RatifyAgreement3)
        ),
        text(
          intl.formatMessage(labels.Label_Menu_CompanyServices_RatifyAgreement4)
        ),
      ],
    },
  ];

  return (
    <InformationPage
      title={intl.formatMessage(
        titles.Title_Menu_Information_CompanyServices_RatifyAgreement
      )}
      data={ratifyAgreementData}
    />
  );
}

const text = (label) => {
  return label + "\n\n";
};

export default RatifyAgreement;
