import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.white.main,
    backgroundColor: "#00000030",
    position: "absolute",
  },
  circularProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "5%",
  }
}));

export default function CardsListBackdrop(props) {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      open={props.open ?? false}
    >
    <CircularProgress className={classes.circularProgress} />
    </Backdrop>
  );
}
