import React from "react";

const ProfessionalTrainingRedirect = () => {
  setTimeout(() => {
    window.location.href = "/formacao";
  }, 1500);

  return <div></div>;
};

export default ProfessionalTrainingRedirect;
