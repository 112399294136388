import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
//MaterialUI
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import limitaString from "utils/string_utils";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";

import TableStateChip from "components/Cards/Tables/TableStateChip";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import {
  OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY,
  FOLLOWUP_STATE_KEY_QUERY,
  TABLE_STATE_CHIP_COLOR,
  PAGINATION,
} from "utils/const";

import { getMyOpportunityApplicationsFollowUpAction } from "redux/actions/opportunitiesAction";
import { titles } from "resources/resources"

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function MyOpportunityApplicationsFollowUpsTab(props) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStylesGeneric();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const tableHead = ["Nº Candidatura", "Empresa", "Tipo de Oferta", "Estado"];

  const getFollowUpStateDescription = (followUpState) => {
    switch (followUpState) {
      case FOLLOWUP_STATE_KEY_QUERY.ONGOING:
        return "em curso";
      case FOLLOWUP_STATE_KEY_QUERY.FINISHED:
        return "concluídos";
      default:
        return "";
    }
  }

  const mapOpportunityFollowUpStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.PORINICIAR:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.ADECORRER:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.TERMINADO:
        return TABLE_STATE_CHIP_COLOR.RED;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    let filterData = {
      followUpStateKey: props.followUpState,
      pageIndex: page <= 0 ? 1 : page,
      pageSize: PAGINATION.PAGE_SIZE,
    };

    props.getMyOpportunityApplicationsFollowUp(filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, props.followUpState]);

  useEffect(() => {
    handleChangePage(undefined, PAGINATION.FIRST_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.followUpState]);

  return (
    <CardTableContainer
      title={intl.formatMessage(titles.Title_Table_UserSingular_OpportunityApplicationsFollowup, { opportunityApplicationsFollowupState: getFollowUpStateDescription(props.followUpState) })}
      count={props.opportunitiesState?.myOpportunityApplicationsFollowUp_total_records}
    >

      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  classes={{
                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  {prop}
                </TableCell>
              ))}
              <TableCell
                key="detailsCol"
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                }}
              >
                Detalhes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.opportunitiesState.myOpportunityApplicationsFollowUp_result?.data?.map((prop, key) => (
              <TableRow key={key}>
                <TableCell classes={{ root: classes.tableCellRoot }} title={prop.opportunityTitle ?? ""}>
                  <Box>{prop.candidateCode}</Box>
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  <Box fontWeight={"bold"}>NIF: {prop.companyTaxIdNumber}</Box>
                  <Box>{limitaString(prop.companyName, 60)}</Box>
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  <Box fontWeight={"bold"}>{prop.opportunityTypeDescription}</Box>
                  <Box>{limitaString(prop.opportunityTitle, 60)}</Box>
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  <TableStateChip
                    state={mapOpportunityFollowUpStateToTableChipColors(prop.candidateFollowUpStateKey)}
                    message={prop.candidateFollowUpStateDescription}
                  />
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                  <Tooltip title="Detalhes">
                    <IconButton
                      aria-label="details"
                      component={Link}
                      to={`/admin/opportunity-application-followup-details/${prop.candidateCode}`}
                    >
                      <Box
                        component={ArrowForwardIosIcon}
                        width="12px!important"
                        height="12px!important"
                        color={theme.palette.primary.main}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Box>
        <TableBackdrop open={props.opportunitiesState?.getMyOpportunityApplicationsFollowUp_loading} />
      </TableContainer>

      <Pagination
        className={classes.alignPagination}
        count={props.opportunitiesState?.myOpportunityApplicationsFollowUp_total_pages}
        page={page}
        onChange={handleChangePage}
        color="primary"
      >
      </Pagination>
    </CardTableContainer>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getMyOpportunityApplicationsFollowUp: (data) => dispatch(getMyOpportunityApplicationsFollowUpAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyOpportunityApplicationsFollowUpsTab);
