import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminNavbarAlternative from "components/Navbars/AdminNavbarAlternative.js";
import AdminFooter from "components/Footers/AdminFooter.js";

import routes from "routes.js";

import componentStyles from "assets/theme/layouts/admin.js";
import NeedHelp from "components/LandingPage/Sections/NeedHelp";

const useStyles = makeStyles(componentStyles);

const Admin = (props) => {
  const classes = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (props.authState.loggedIn === undefined || !props.authState.loggedIn) {
    return <Redirect to="/auth/home" />;
  } else {
    return (
      <>
        <Box>
          <Box position="relative" flex="1" className={classes.mainContent}>
            {location.pathname === "/admin/alternative-dashboard" ? (
              <AdminNavbarAlternative />
            ) : (
              <AdminNavbar />
            )}
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/admin/home" />
            </Switch>
          </Box>
          <NeedHelp />
          <AdminFooter />
        </Box>
      </>
    );
  }
};

// export default Auth;
const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Admin);
