import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import ApplicationData from "./Sections/ApplicationData";
import ApplicationStepper from "./Sections/ApplicationStepper";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import { getApplicationDetailsGeneralDataAction } from "redux/actions/applicationsAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ApplicationDetails(props) {
  const classes = useStylesGeneric();
  const [applicationState, setApplicationState] = useState(0);
  const [applicationStateHistory, setApplicationStateHistory] = useState([]);

  const [applicationNumber, setApplicationNumber] = useState("");

  const { getValues } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (props.applicationsState.applicationDetailsGeneralData !== undefined) {
      setApplicationState(props.applicationsState.applicationDetailsGeneralData?.applicationStateId);
      setApplicationStateHistory(props.applicationsState.applicationDetailsGeneralData?.applicationStateHistory);
    }
  }, [props.applicationsState.applicationDetailsGeneralData]);

  useEffect(() => {
    props.getApplicationDetailsGeneralData(props.match.params.applicationNumber);
    setApplicationNumber(props.match.params.applicationNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.applicationNumber]);

  return (
    <>
      <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
        <GenericBackdrop
          open={props.applicationsState.applicationDetailsGeneralData_loading}
        />

        <LocationHeader
          section={"Detalhes do Processo"}
          subsection={"Candidaturas"}
          subsectionLink={"/admin/my-applications/government-program-application"}
        />

        <PageTitleHeader
          title={applicationNumber}
          goBackLink={`/admin/my-applications/government-program-application`}
        />
        
        <form>
          <ApplicationData
            applicationNumber={applicationNumber}
            generalData={props.applicationsState.applicationDetailsGeneralData}
            getValues={getValues}
          />
        </form>
      </Container>

      <Grid>
        <ApplicationStepper
          applicationState={applicationState}
          history={applicationStateHistory}
        />
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getApplicationDetailsGeneralData: (applicationNum) =>
    dispatch(getApplicationDetailsGeneralDataAction(applicationNum)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails);
