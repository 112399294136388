import { React, useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Card, CardHeader, CardContent } from "@material-ui/core";
// components
import FormSelect from "components/FormFields/FormSelect";
import GenericButton from "components/Buttons/GenericButton";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { getFilteredInformationAction } from "redux/actions/informationDisseminationAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { PAGINATION, DATASET, INFORMATION_DISSEMINATION_TYPE_KEYVALUE } from "utils/const";
import { getSelectOptionsElementByKeyValue } from "utils/formSelect";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSection(props) {
  const classes = useStylesGeneric();
  const [filter, setFilter] = useState({
    searchText: "",
    searchPublishPeriodKeyValue: undefined,
    informationDisseminationTypeKeyValueList:
      props.informationDisseminationType
        ? [props.informationDisseminationType]
        : [
          INFORMATION_DISSEMINATION_TYPE_KEYVALUE.NEWS,
          INFORMATION_DISSEMINATION_TYPE_KEYVALUE.LEGISLATION,
          INFORMATION_DISSEMINATION_TYPE_KEYVALUE.SCHEDULE
        ],
    isPublic: props.authState.loggedIn !== undefined && props.authState.loggedIn ? null : true, // if user logged in show all, otherwise show only public ones
    published: true,
    isHighlighted: null,
    pageIndex: props.page,
    pageSize: PAGINATION.PAGE_SIZE_DISSEMINATION,
  });
  const [informationDisseminationTypes, setInformationDisseminationTypes] = useState([]);

  const postInformationTypeKeyValuesList = [
    INFORMATION_DISSEMINATION_TYPE_KEYVALUE.NEWS,
    INFORMATION_DISSEMINATION_TYPE_KEYVALUE.LEGISLATION,
    INFORMATION_DISSEMINATION_TYPE_KEYVALUE.SCHEDULE
  ]

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const cleanFilter = () => {
    setValue("searchText", "");
    setValue("informationDisseminationTypeKeyValue", getSelectOptionsElementByKeyValue(informationDisseminationTypes, props.informationDisseminationType))
    setValue("searchPublishPeriodKeyValue", undefined)


    let temp_filter = {
      ...filter,
      searchText: "",
      searchPublishPeriodKeyValue: undefined,
      informationDisseminationTypeKeyValueList:
        getSelectOptionsElementByKeyValue(informationDisseminationTypes, props.informationDisseminationType)
          ? [getSelectOptionsElementByKeyValue(informationDisseminationTypes, props.informationDisseminationType)]
          : postInformationTypeKeyValuesList,
    };
    setFilter(temp_filter);
  };

  //Refresh Results
  const refreshResults = (temp_filter) => {
    setFilter(temp_filter);
    props.getFilterInformation(temp_filter);
  };

  const onSubmit = (data) => {
    if (isValid) {
      let temp_filter = {
        ...filter,
        searchText: data.searchText,
        searchPublishPeriodKeyValue: data.searchPublishPeriodKeyValue?.keyValue,
        informationDisseminationTypeKeyValueList:
          data.informationDisseminationTypeKeyValue?.keyValue
            ? [data.informationDisseminationTypeKeyValue?.keyValue]
            : postInformationTypeKeyValuesList,
      };

      setFilter(temp_filter);
      if (props.page === 1) {
        props.getFilterInformation(temp_filter);
      } else {
        props.handleChangePage(1);
      }
    }
  };

  useEffect(() => {
    setValue("searchText", "");
    setValue("informationDisseminationTypeKeyValue", getSelectOptionsElementByKeyValue(informationDisseminationTypes, props.informationDisseminationType))
    setValue("searchPublishPeriodKeyValue", undefined)

    let temp_filter = {
      ...filter,
      searchText: "",
      searchPublishPeriodKeyValue: undefined,
      informationDisseminationTypeKeyValueList:
        props.informationDisseminationType
          ? [props.informationDisseminationType]
          : postInformationTypeKeyValuesList,
      pageIndex: PAGINATION.FIRST_PAGE,
    };

    refreshResults(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDisseminationType, informationDisseminationTypes]);

  useEffect(() => {
    let temp_filter = {
      ...filter,
      pageIndex: props.page,
    };

    refreshResults(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.INFORMATION_DISSEMINATION_TYPE] === undefined ||
      props.datasetsState[DATASET.INFORMATION_DISSEMINATION_TYPE] === []
    ) {
      props.getDataSetByName(DATASET.INFORMATION_DISSEMINATION_TYPE);
    }

    setInformationDisseminationTypes(
      filterListByExcludingKeyValue(
        props.datasetsState[DATASET.INFORMATION_DISSEMINATION_TYPE],
        INFORMATION_DISSEMINATION_TYPE_KEYVALUE.STRUCTURING_PROJECT
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterListByExcludingKeyValue = (list, keyValue) => {
    return list?.filter((item) => item.keyValue !== keyValue);
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={props.title}
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={12} md={4}>
                <FormGroup>
                  <FormLabel>Pesquisa (título/descrição)</FormLabel>
                  <Controller
                    name="searchText"
                    control={control}
                    defaultValue=""
                    rules={{ required: { value: false } }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("searchText", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["searchText"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormGroup>
                  <FormLabel>Categoria</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="informationDisseminationTypeKeyValue"
                    selectOptions={informationDisseminationTypes}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"informationDisseminationTypeKeyValueList"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormGroup>
                  <FormLabel>Data Publicação</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="searchPublishPeriodKeyValue"
                    selectOptions={DATASET.infomrationDisseminationPublishPeriodDropDown}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"searchPublishPeriodKeyValue"}
                  />
                </FormGroup>
              </Grid>

              {/* Botões de Pesquisa */}

              <Grid item xs={12} md={12} classes={{ root: classes.textAlignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  Limpar Filtros
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.informationDisseminationState.filteredInformation_loading}
                >
                  Pesquisar
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getFilterInformation: (data) => dispatch(getFilteredInformationAction(data)),
  getDataSetByName: (name) => dispatch(getDataSetByNameAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
