const componentStyles = (theme) => ({
  appBarRoot: {
    height: "110px",
    borderBottom: "3px solid " + theme.palette.orange[200],
    backgroundColor: theme.palette.white.main,
    zIndex: "1299",
    boxShadow: "0px -5px 15px black",
    position: "fixed"
  },
  containerRoot: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  logo: {
    width: "150px",
    height: "auto",
    marginRight: "1rem",
    background: "url(Logo.png)",
    filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.12))",
    verticalAlign: "middle",
    borderStyle: "none",
    [theme.breakpoints.up("lg")]:{
      marginLeft: "39px",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "11px",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "-3px",
    },
  },
  listItemRoot: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    textTransform: "uppercase",
    lineHeight: "19.07px",
    paddingLeft: "0.5rem",
    paddingRight: "0.25rem",
    paddingTop: ".625rem",
    paddingBottom: ".625rem",
    transition: "all .15s linear",
    color: theme.palette.gray[900],
    margin: "0",
    "& i": {
      marginRight: "0.25rem",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "1.5rem",
      paddingLeft: "0",
      paddingRight: "0.5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      justifyContent: "center",
      "&:hover": {
        fontWeight: "900",
        color: theme.palette.primary.greyBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.greyBlue,
        },
      },
      "&:active": {
        color: theme.palette.primary.darkBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.darkBlue,
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      justifyContent: "center",
      "&:hover": {
        fontWeight: "900",
        color: theme.palette.primary.greyBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.greyBlue,
        },
      },
      "&:active": {
        color: theme.palette.primary.darkBlue,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.darkBlue,
        },
      },
    },
  },
  dropIcon: {
    minWidth: "0px",
  },
  dropItems: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "220%",
    color: theme.palette.gray[900],
    "&:hover": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.greyBlue,
      fontWeight: "900",
      "& .MuiSvgIcon-root": {
        color: theme.palette.white.main
      }
    },
    "&:active": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.darkBlue,
      fontWeight: "900"
    },
  },
  expandIcon: {
    marginLeft: "0.5rem",
    width: "20px",
    height: "20px",
  },
  submenuGroup: {
    position: "absolute",
    top: "0px",
    left: "233px"
  },
  navbarDiv: {
    marginLeft: "20px", 
    marginTop: "-4px"
  }
});

export default componentStyles;
