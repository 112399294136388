import boxShadows from "assets/theme/box-shadow";

const componentStyles = (theme) => ({
  cardRoot: {
    backgroundColor: theme.palette.white,
    boxShadow: boxShadows.boxShadow + "!important",
    height: "100% !important",
  },
  CardContent: {
    padding: "0px 16px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  holderName: {
    lineHeight: "1.2",
    fontSize: "18px",
    fontWeight: "700",
    color: theme.palette.primary.main,
    marginBottom: "5px",
  },
  holderPosition: {
    lineHeight: "1.5",
    fontSize: "12px",
    fontWeight: "400",
    color: theme.palette.gray[600],
    marginBottom: "0px !important",
  },
});

export default componentStyles;
