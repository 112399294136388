import { React, useState } from "react";
import { connect } from "react-redux";
import "moment/locale/pt";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";

//import custom components
import TableStateChip from "components/Cards/Tables/TableStateChip";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FeedbackReportModal from "./Modals/FeedbackReportModal"; 
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";

import { 
    OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY,
    TABLE_STATE_CHIP_COLOR } 
from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const tableHead = ["Período", "Tipo de Formulário", "Data de Entrega", "", "Estado"];

function FollowUpFeedbackReports(props) {
    const classes = useStylesGeneric();
    const [showFeedbackReportModal, setShowFeedbackReportModal] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [feedbackReportId, setFeedbackReportId] = useState(false);

    const handleOpenFeedbackReportModal = (feedbackReportId, stateKey) => {
        setIsEditable(stateKey === OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.PORSUBMETER || stateKey === OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.REJEITADO)
        setFeedbackReportId(feedbackReportId)
        setShowFeedbackReportModal(true);
    };
    
    const handleCloseFeedbackReportModal = () => {
        setShowFeedbackReportModal(false);
    };

    const mapOpportunityFollowUpFeedbackReportStateToTableChipColors = (stateKey) => {
        switch (stateKey) {
            case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.PORSUBMETER:
                return TABLE_STATE_CHIP_COLOR.BLUE
            case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.SUBMETIDO:
                return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE
            case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.VALIDADO:
            case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.PAGO:
                return TABLE_STATE_CHIP_COLOR.GREEN
            case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.REJEITADO:
                return TABLE_STATE_CHIP_COLOR.RED
            default:
                return TABLE_STATE_CHIP_COLOR.GREEN;
        }
    };

    return (
        <CardTableContainer
            title="Formulários"
            count={props.opportunityApplicationFollowUpDetails?.followUpForms?.length}
        >
            {props.opportunityApplicationFollowUpDetails?.followUpForms?.length > 0 ? ( 
                <TableContainer>
                    <Box component={Table} alignItems="center" marginBottom="0!important">
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        classes={{
                                            root:
                                                classes.tableCellRoot + " " + classes.tableCellRootHead,
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.opportunityApplicationFollowUpDetails?.followUpForms?.map((prop, key) => (
                                <TableRow key={key}>
                                <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {prop.evaluationPeriodStartDate ? moment(prop.evaluationPeriodStartDate).format("DD/MM/YYYY") : ""}  
                                        {" - "} 
                                        {prop.evaluationPeriodEndDate ? moment(prop.evaluationPeriodEndDate).format("DD/MM/YYYY") : ""}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {prop.isAdHoc ? <b>{prop.title} Extra</b>: prop.title} 
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {prop.submissionDate ? moment(prop.submissionDate).format("DD/MM/YYYY") : ""}
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box alignItems="center" display="flex" style={{justifyContent: "center"}}>
                                            <Box alignItems="center">
                                                <GenericButton
                                                    typeSubmit={false}
                                                    color="tertiary"
                                                    size="small"
                                                    onClick={() => { handleOpenFeedbackReportModal(prop.feedbackReportId, prop.stateKey) }}
                                                >
                                                    {(prop.stateKey === OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.PORSUBMETER || prop.stateKey === OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.REJEITADO) 
                                                    ? "PREENCHER FORMULÁRIO" 
                                                    : "VER FORMULÁRIO"} 
                                                </GenericButton>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <TableStateChip
                                            state={mapOpportunityFollowUpFeedbackReportStateToTableChipColors(prop.stateKey)}
                                            message={prop.stateDescription}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                </TableContainer>
            ):
            <CardContent>
                <Grid container>
                    <Grid item xs={props.xs} sm={props.sm} md={props.md}>
                        <FormGroup>
                            <FormLabel className={classes.detailFormLabels}>
                                Não existem dados para apresentar.
                            </FormLabel>
                        </FormGroup>
                    </Grid>
                </Grid>
            </CardContent>
            }

            {/* FollowUpFeedbackReport Modal */}
            <GenericDialog maxWidth={"lg"} open={showFeedbackReportModal} onClose={handleCloseFeedbackReportModal}>
                <FeedbackReportModal
                    feedbackReportId={feedbackReportId}
                    isUserColective={props.isUserColective}
                    isEditable={isEditable}
                    handleClose={handleCloseFeedbackReportModal}
                    handleRefreshPage={props.handleRefreshPage}
                />
            </GenericDialog>
        </CardTableContainer>
    );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(FollowUpFeedbackReports);
