import {
    getFilteredInformation,
    getInformationDetails,
    getInformationDetailsPublic,
  } from "crud/informationdissemination.crud";
  import { INFORMATION_DISSEMINATION } from "utils/actionsConsts";
  import moment from "moment";
  
  export const getFilteredInformationAction = (formData) => async (dispatch) => {
    dispatch({
      type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION_LOADING,
      payload: true,
    });
    dispatch({
      type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
      payload: false,
    });
    getFilteredInformation(formData)
      .then((res) => {
        const mappedList = res.data?.data.map((record) => {
          return {
            alternateKey: record.alternateKey,
            informationDisseminationTypeId: record.informationDisseminationTypeId,
            informationDisseminationTypeKeyValue: record.informationDisseminationTypeKeyValue,
            informationDisseminationTypeDescription: record.informationDisseminationTypeDescription,
            title: record.title,
            description: record.description,
            isPublic: record.isPublic,
            published: record.published,
            publishedOn: record.publishedOn !== null ? moment(record.publishedOn).format("DD/MM/YYYY") : null,
            isHighlighted: record.isHighlighted,
            photo: record.thumbnail,
          };
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION,
          result: true,
          payload: {
            data: mappedList,
            total_records: res.data.totalCount,
            total_pages: res.data.totalPages,
          },
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION,
          result: false,
          payload: [],
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION_LOADING,
          payload: false,
        });
        console.log("error", error);
      });
  };

  export const getHighligtedInformationAction = (formData) => async (dispatch) => {
    dispatch({
      type: INFORMATION_DISSEMINATION.GET_HIGHLIGHTED_INFORMATION_DISSEMINATION_LOADING,
      payload: true,
    });
    dispatch({
      type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
      payload: false,
    });
    getFilteredInformation(formData)
      .then((res) => {
        const mappedList = res.data?.data.map((record) => {
          return {
            alternateKey: record.alternateKey,
            informationDisseminationTypeId: record.informationDisseminationTypeId,
            informationDisseminationTypeKeyValue: record.informationDisseminationTypeKeyValue,
            informationDisseminationTypeDescription: record.informationDisseminationTypeDescription,
            title: record.title,
            description: record.description,
            isPublic: record.isPublic,
            published: record.published,
            publishedOn: record.publishedOn !== null ? moment(record.publishedOn).format("DD/MM/YYYY") : null,
            isHighlighted: record.isHighlighted,
            photo: record.thumbnail,
          };
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_HIGHLIGHTED_INFORMATION_DISSEMINATION,
          result: true,
          payload: {
            data: mappedList,
            total_records: res.data.totalCount,
            total_pages: res.data.totalPages,
          },
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_HIGHLIGHTED_INFORMATION_DISSEMINATION_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_HIGHLIGHTED_INFORMATION_DISSEMINATION,
          result: false,
          payload: [],
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_HIGHLIGHTED_INFORMATION_DISSEMINATION_LOADING,
          payload: false,
        });
        console.log("error", error);
      });
  };

  export const getStructuringProjectsAction = (formData) => async (dispatch) => {
    dispatch({
      type: INFORMATION_DISSEMINATION.GET_STRUCTURING_PROJECTS_LOADING,
      payload: true,
    });
    getFilteredInformation(formData)
      .then((res) => {
        const mappedList = res.data?.data.map((record) => {
          return {
            alternateKey: record.alternateKey,
            informationDisseminationTypeId: record.informationDisseminationTypeId,
            informationDisseminationTypeKeyValue: record.informationDisseminationTypeKeyValue,
            informationDisseminationTypeDescription: record.informationDisseminationTypeDescription,
            title: record.title,
            description: record.description,
            isPublic: record.isPublic,
            published: record.published,
            publishedOn: record.publishedOn !== null ? moment(record.publishedOn).format("DD/MM/YYYY") : null,
            isHighlighted: record.isHighlighted,
            photo: record.thumbnail,
            socialMediaList: record.socialMediaList,
          };
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_STRUCTURING_PROJECTS,
          result: true,
          payload: {
            data: mappedList,
            total_records: res.data.totalCount,
            total_pages: res.data.totalPages,
          },
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_STRUCTURING_PROJECTS_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_STRUCTURING_PROJECTS,
          result: false,
          payload: [],
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_STRUCTURING_PROJECTS_LOADING,
          payload: false,
        });
        console.log("error", error);
      });
  };

  export const getFilteredStructuringProjectsAction = (formData) => async (dispatch) => {
    dispatch({
      type: INFORMATION_DISSEMINATION.GET_FILTERED_STRUCTURING_PROJECTS_LOADING,
      payload: true,
    });
    dispatch({
      type: INFORMATION_DISSEMINATION.REFRESH_RESULTS,
      payload: false,
    });
    getFilteredInformation(formData)
      .then((res) => {
        const mappedList = res.data?.data.map((record) => {
          return {
            alternateKey: record.alternateKey,
            informationDisseminationTypeId: record.informationDisseminationTypeId,
            informationDisseminationTypeKeyValue: record.informationDisseminationTypeKeyValue,
            informationDisseminationTypeDescription: record.informationDisseminationTypeDescription,
            title: record.title,
            description: record.description,
            isPublic: record.isPublic,
            published: record.published,
            publishedOn: record.publishedOn !== null ? moment(record.publishedOn).format("DD/MM/YYYY") : null,
            isHighlighted: record.isHighlighted,
            photo: record.thumbnail,
            socialMediaList: record.socialMediaList,
          };
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_FILTERED_STRUCTURING_PROJECTS,
          result: true,
          payload: {
            data: mappedList,
            total_records: res.data.totalCount,
            total_pages: res.data.totalPages,
          },
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_FILTERED_STRUCTURING_PROJECTS_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_FILTERED_STRUCTURING_PROJECTS,
          result: false,
          payload: [],
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_FILTERED_STRUCTURING_PROJECTS_LOADING,
          payload: false,
        });
        console.log("error", error);
      });
  };

  export const getSearchedInformationAction = (formData) => async (dispatch) => {
    dispatch({
      type: INFORMATION_DISSEMINATION.GET_SEARCHED_INFORMATION_DISSEMINATION_LOADING,
      payload: true,
    });
    getFilteredInformation(formData)
      .then((res) => {
        const mappedList = res.data?.data.map((record) => {
          return {
            alternateKey: record.alternateKey,
            informationDisseminationTypeId: record.informationDisseminationTypeId,
            informationDisseminationTypeKeyValue: record.informationDisseminationTypeKeyValue,
            informationDisseminationTypeDescription: record.informationDisseminationTypeDescription,
            title: record.title,
            description: record.description,
            isPublic: record.isPublic,
            published: record.published,
            publishedOn: record.publishedOn !== null ? moment(record.publishedOn).format("DD/MM/YYYY") : null,
            isHighlighted: record.isHighlighted,
            photo: record.thumbnail,
          };
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_SEARCHED_INFORMATION_DISSEMINATION,
          result: true,
          payload: {
            data: mappedList,
            total_records: res.data.totalCount,
            total_pages: res.data.totalPages,
          },
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_SEARCHED_INFORMATION_DISSEMINATION_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_SEARCHED_INFORMATION_DISSEMINATION,
          result: false,
          payload: [],
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_SEARCHED_INFORMATION_DISSEMINATION_LOADING,
          payload: false,
        });
        console.log("error", error);
      });
  };
  
  export const getInformationDetailsAction = (formData, history) => async (dispatch) => {
    dispatch({
      type: INFORMATION_DISSEMINATION.CLEAR_DETAIL,
    });
    dispatch({
      type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING,
      payload: true,
    });
    getInformationDetails(formData)
      .then((res) => {
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING,
          payload: false,
        });

        if(!res.data.success){
          return history.push('/admin/home');
        }
  
        let infoData = res.data.infoData;
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS,
          result: true,
          payload: {
            alternateKey: infoData.alternateKey,
            informationDisseminationTypeId: infoData.informationDisseminationTypeId,
            informationDisseminationTypeDescription: infoData.informationDisseminationTypeDescription,
            title: infoData.title,
            description: infoData.description,
            isPublic: infoData.isPublic,
            published: infoData.published,
            publishedOn: infoData.publishedOn !== null ? moment(infoData.publishedOn).format("DD/MM/YYYY") : null,
            mediaList: infoData.mediaList,
            socialMediaList: infoData.socialMediaList,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS,
          result: false,
          payload: [],
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING,
          payload: false,
        });
        console.log("error", error);
      });
  };

  export const getInformationDetailsPublicAction = (formData, history) => async (dispatch) => {
    dispatch({
      type: INFORMATION_DISSEMINATION.CLEAR_DETAIL,
    });
    dispatch({
      type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING,
      payload: true,
    });
    getInformationDetailsPublic(formData)
      .then((res) => {
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING,
          payload: false,
        });

        if(!res.data.success){
          return history.push('/auth/home');
        }
  
        let infoData = res.data.infoData;
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS,
          result: true,
          payload: {
            alternateKey: infoData.alternateKey,
            informationDisseminationTypeId: infoData.informationDisseminationTypeId,
            informationDisseminationTypeDescription: infoData.informationDisseminationTypeDescription,
            title: infoData.title,
            description: infoData.description,
            isPublic: infoData.isPublic,
            published: infoData.published,
            publishedOn: infoData.publishedOn !== null ? moment(infoData.publishedOn).format("DD/MM/YYYY") : null,
            mediaList: infoData.mediaList,
            socialMediaList: infoData.socialMediaList,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS,
          result: false,
          payload: [],
        });
        dispatch({
          type: INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING,
          payload: false,
        });
        console.log("error", error);
      });
  };