import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import { Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import componentStyles from "assets/theme/components/landingPage/landing-page.js";
import ServiceButton from "../Components/ServiceButton";
import inefopServices from "assets/img/backgroundImages/inefop_services.png";
import inefopServicesSmall from "assets/img/backgroundImages/inefop_services_small.png";
import useWindowSize from "hooks/useWindowSize";
import { labels } from "resources/resources";

function ServicesAfterLogin(props) {
  const useStyles = makeStyles(componentStyles);

  const classes = useStyles();
  const history = useHistory();
  const windowSize = useWindowSize();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <div
      style={
        windowSize.width >= theme.breakpoints.values.xl
          ? {
              backgroundImage: `url(${inefopServices})`,
              backgroundSize: "cover",
              backgroundPosition: "left center",
              height: "300px",
            }
          : {}
      }
    >
      {windowSize.width < theme.breakpoints.values.xl && (
        <div
          style={{
            backgroundImage:
              windowSize.width >= theme.breakpoints.values.sm
                ? `url(${inefopServices})`
                : `url(${inefopServicesSmall})`,
            backgroundSize: "cover",
            backgroundPosition: "left center",
            height: "300px",
          }}
        ></div>
      )}

      <Grid
        container
        className={classes.homepageSectionContainer}
        style={{
          background: "none",
          width: "100%",
          marginBottom:
            windowSize.width < theme.breakpoints.values.xl ? "-50px" : "0",
        }}
      >
        <Grid item xs={12} xl={6}></Grid>
        {props.servicesList?.map(
          (area, areaKey) =>
            area.userType === props.userType && (
              <Grid key={areaKey} item xs={12} xl={6}>
                <Grid container style={{ marginBottom: "20px" }}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <div className={classes.servicesWelcome}>
                      <b>{intl.formatMessage(labels.Label_Welcome)}</b>{" "}
                      {props.authState?.name} {props.authState?.lastName}
                    </div>
                    <div className={classes.servicesDescription}>
                      {intl.formatMessage(
                        labels.Label_HomePage_Services_Description
                      )}
                    </div>
                  </Grid>

                  {area.services?.map((service, serviceKey) => (
                    <Grid
                      key={serviceKey}
                      item
                      xs={12}
                      sm={4}
                      style={{ textAlign: "center" }}
                    >
                      <div id={service.id}>
                        <ServiceButton
                          id={service.id}
                          title={service.title}
                          icon={service.icon}
                          handleOnClick={() => {
                            if (service.externalLink) {
                              window.open(service.path, "_blank", "noreferrer");
                            } else {
                              if (service.id === "professionalFormation") {
                                window.location.href = "/formacao";
                              } else {
                                let path = "";
                                path = path.concat(
                                  props.authState?.loggedIn ? "/admin" : "/auth"
                                );
                                path = path.concat(service.path);
                                history.push(path);
                              }
                            }
                          }}
                          isLoggedIn={true}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )
        )}
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ServicesAfterLogin);
