import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";

// @material-ui/core components
import { makeStyles, styled } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

import Collapse from "@material-ui/core/Collapse";

import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormInput from "components/FormFields/FormInput";

//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const ExpandMore = styled((props) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidateRejectedMotive(props) {
  const classes = useStylesGeneric();
  const methods = useFormContext();
  
  const [expanded, setExpanded] = useState(false);  

  useEffect(() => {
    if (props.opportunitiesState?.candidateInfo) {
      methods.setValue(
        "stateMotive",
        props.opportunitiesState?.candidateInfo.opportunityCandidateHistory
          ?.rejectionMotive
      );
      methods.setValue(
        "observations",
        props.opportunitiesState?.candidateInfo.opportunityCandidateHistory
          ?.observations
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunitiesState?.candidateInfo]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot}>
          <CardHeader
            classes={{
              title: classes.cardHeader,
            }}
            title={"Motivo de Rejeição"}
            action={
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon className={classes.expandMoreIcon}/>
              </ExpandMore>
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <FormInput
                    name="stateMotive"
                    label="Motivo"
                    disabled={true}
                    control={methods.control}
                    errors={methods.formState.errors}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormInput
                    name="observations"
                    label="Comentários"
                    disabled={true}
                    multiline={true}
                    rows={4}
                    control={methods.control}
                    errors={methods.formState.errors}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(CandidateRejectedMotive);
