import { NEWSLETTER_ACTIONS } from "utils/actionsConsts";

const newsletterReducer = (
  state = {
    postNewsletterAddContact_loading: false,
  },
  action
) => {
  switch (action.type) {
    case NEWSLETTER_ACTIONS.POST_NEWSLETTER_ADD_CONTACT_LOADING:
      return { ...state, postNewsletterAddContact_loading: action.payload };
    default:
      return state;
  }
};

export default newsletterReducer;
