import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/icons components
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";

//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import CarouselMediaPreviewSection from "components/GenericPage/CarouselMediaPreviewSection";
import "react-quill/dist/quill.snow.css";
import { labels } from "resources/resources";
import { SOCIAL_MEDIA_TYPES_KEYVALUES } from "utils/const";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function DetailsSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [stateDescription, setStateDescription] = useState(null);

  useEffect(() => {
    // Update of the Open Graph meta tags (eith dynamic data) to be applied when sharing the INEFOP information-details page on social media
    const ogTitle = document.querySelector('meta[property="og:title"]');
    ogTitle.setAttribute("content", props.informationDetailsData?.title);
    const ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    ogDescription.setAttribute(
      "content",
      props.informationDetailsData?.informationDisseminationTypeDescription
    );
    const ogImage = document.querySelector('meta[property="og:image"]');
    ogImage.setAttribute(
      "content",
      props.informationDetailsData?.mediaList 
        ? (
          props.informationDetailsData?.mediaList[0]?.photo
          ? props.informationDetailsData?.mediaList[0]?.photo
        : "/images/group-afro-americans-working-together.png"
        )
        : "/images/group-afro-americans-working-together.png"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStateDescription(
      props.informationDetailsData !== undefined &&
        props.informationDetailsData?.description !== undefined
        ? props.informationDetailsData.description
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDetailsData?.description]);

  const redirectLink = (link) => {
    let correctLink = "";

    if (link.includes("http")) {
      correctLink = link;
    } else {
      correctLink = "http://" + link;
    }

    window.open(correctLink, "_blank");
  };

  const checkIfSocialMediaListHasShareLinks = (list) => {
    if (list !== undefined && list !== null && list.length > 0) {
      return list.some((item) => item.hasOwnProperty("shareLink"));
    }
    return false;
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot + classes.noPadding}>
          <CardContent className={classes.noPadding}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <CarouselMediaPreviewSection
                  mediaList={props.informationDetailsData?.mediaList}
                  mediaItemPhotoName="photo"
                  mediaItemVideoName="url"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container style={{ padding: "1.25rem 2rem" }}>
              <Grid
                item
                xs={12}
                className={classes.textAlignEnd}
                style={{ marginBottom: "20px" }}
              >
                {checkIfSocialMediaListHasShareLinks(
                  props?.socialMediaState?.socialMediaLinks_result
                ) && (
                  <div>
                    <div style={{ marginRight: "10px" }}>
                      {intl.formatMessage(labels.Label_Share)}
                    </div>
                    <div>
                      {props?.socialMediaState?.socialMediaLinks_result?.map(
                        (socialMedia, key) => (
                          <Fragment key={key}>
                            {socialMedia.keyValue ===
                              SOCIAL_MEDIA_TYPES_KEYVALUES.FACEBOOK &&
                              socialMedia.shareLink && (
                                <FacebookIcon
                                  className={
                                    classes.iconButtonOutlined +
                                    " " +
                                    classes.socialMediaIcon
                                  }
                                  onClick={() =>
                                    window.open(
                                      socialMedia.shareLink +
                                        window.location.origin +
                                        `/auth/informationDissemination-details/` +
                                        props.alternateKey,
                                      "_blank"
                                    )
                                  }
                                />
                              )}
                            {socialMedia.keyValue ===
                              SOCIAL_MEDIA_TYPES_KEYVALUES.TWITTER &&
                              socialMedia.shareLink && (
                                <TwitterIcon
                                  className={
                                    classes.iconButtonOutlined +
                                    " " +
                                    classes.socialMediaIcon
                                  }
                                  onClick={() =>
                                    window.open(
                                      socialMedia.shareLink +
                                        window.location.origin +
                                        `/auth/informationDissemination-details/` +
                                        props.informationDetailsData
                                          ?.alternateKey,
                                      "_blank"
                                    )
                                  }
                                />
                              )}
                          </Fragment>
                        )
                      )}
                    </div>
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                <Box style={{ paddingBottom: "40px" }}>
                  <Typography variant="h3">
                    {props.informationDetailsData?.title}
                  </Typography>
                  <Box>
                    <ReactQuill
                      readOnly={true}
                      style={{ minHeight: "6rem" }}
                      theme="bubble"
                      value={stateDescription}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={3} xl={2}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(
                      labels.Label_Admin_InformationDissemination_PublishedDate
                    )}
                  </FormLabel>
                  <Box className={classes.detailLabels}>
                    {props.informationDetailsData?.publishedOn ?? "N/A"}
                  </Box>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(
                      labels.Label_Admin_InformationDissemination_InformationCategory
                    )}
                  </FormLabel>
                  <Box className={classes.detailLabels}>
                    {
                      props.informationDetailsData
                        ?.informationDisseminationTypeDescription
                    }
                  </Box>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={3} xl={2}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(
                      labels.Label_Admin_InformationDissemination_Authentication
                    )}
                  </FormLabel>
                  <Box className={classes.detailLabels}>
                    {props.informationDetailsData?.isPublic ? "Não" : "Sim"}
                  </Box>
                </FormGroup>
              </Grid>

              {props.informationDetailsData?.socialMediaList?.length > 0 && (
                <Grid item xs={12} sm={7} md={7} style={{ marginTop: "20px" }}>
                  {props.informationDetailsData?.socialMediaList?.map(
                    (socialMedia, key) => (
                      <Fragment key={key}>
                        {socialMedia.sociaMediaTypeKeyValue ===
                          SOCIAL_MEDIA_TYPES_KEYVALUES.FACEBOOK && (
                          <FacebookIcon
                            className={
                              classes.iconButtonOutlined +
                              " " +
                              classes.socialMediaIcon
                            }
                            onClick={() => redirectLink(socialMedia.url)}
                          />
                        )}
                        {socialMedia.sociaMediaTypeKeyValue ===
                          SOCIAL_MEDIA_TYPES_KEYVALUES.TWITTER && (
                          <TwitterIcon
                            className={
                              classes.iconButtonOutlined +
                              " " +
                              classes.socialMediaIcon
                            }
                            onClick={() => redirectLink(socialMedia.url)}
                          />
                        )}
                        {socialMedia.sociaMediaTypeKeyValue ===
                          SOCIAL_MEDIA_TYPES_KEYVALUES.YOUTUBE && (
                          <YouTubeIcon
                            className={
                              classes.iconButtonOutlined +
                              " " +
                              classes.socialMediaIcon
                            }
                            onClick={() => redirectLink(socialMedia.url)}
                          />
                        )}
                        {socialMedia.sociaMediaTypeKeyValue ===
                          SOCIAL_MEDIA_TYPES_KEYVALUES.INSTAGRAM && (
                          <InstagramIcon
                            className={
                              classes.iconButtonOutlined +
                              " " +
                              classes.socialMediaIcon
                            }
                            onClick={() => redirectLink(socialMedia.url)}
                          />
                        )}
                      </Fragment>
                    )
                  )}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(DetailsSection);
