import { getGovernmentPrograms, getGovernmentProgramDetails } from "crud/governmentPrograms.crud";
import { GOVERNMENT_PROGRAMS_ACTIONS } from "utils/actionsConsts";

export const getGovernmentProgramsAction = () => async (dispatch) => {
  dispatch({
    type: GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAMS_LOADING,
    payload: true,
  });
  getGovernmentPrograms()
    .then((res) => {
      dispatch({
        type: GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAMS,
        payload: res.data,
      });
      dispatch({
        type: GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAMS_LOADING,
        payload: false,
      });
    })
    .catch(() => {
      dispatch({
        type: GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAMS_LOADING,
        payload: false,
      });
    });
};

export const getGovernmentProgramDetailsAction = (programId) => async (dispatch) => {
  dispatch({
    type: GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAM_DETAILS_LOADING,
    payload: true,
  });
  getGovernmentProgramDetails(programId)
    .then((res) => {
      dispatch({
        type: GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAM_DETAILS,
        payload: res.data,
      });
      dispatch({
        type: GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAM_DETAILS_LOADING,
        payload: false,
      });
    })
    .catch(() => {
      dispatch({
        type: GOVERNMENT_PROGRAMS_ACTIONS.GET_GOVERNMENT_PROGRAM_DETAILS_LOADING,
        payload: false,
      });
    });
};

export default getGovernmentProgramsAction;
