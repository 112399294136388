// @material-ui/core components
import React from "react";

import { Grid, Link } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";

import componentStyles from "assets/theme/components/cards/cards/card-institute-holders.js";

const useStyles = makeStyles(componentStyles);

function CardInstituteHolder(props) {
  const classes = { ...useStyles() };

  return (
    <>
      <Link href={"/auth/institute-holders" + props.holderLink} underline="none">
        <Card className={classes.cardRoot}>
          <CardContent className={classes.CardContent}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <img src={props.holderPhoto} height="72px" width="72px" alt={"Foto - " + props.holderName} />
              </Grid>
              <Grid item xs={8}>
                <h3 className={classes.holderName}>{props.holderName}</h3>
                <p className={classes.holderPosition}>{props.holderPosition}</p>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Link>
    </>
  );
}

export default CardInstituteHolder;
