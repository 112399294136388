import React from "react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import componentStyles from "assets/theme/components/landingPage/landing-page.js";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import Carousel from "react-material-ui-carousel";
import PartnershipsCard from "../Components/PartnershipsCard";
import { titles } from "resources/resources";

function Partnerships() {
  const useStyles = makeStyles(componentStyles);
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // This component is now dynamic! Just place your new brand in this list
  // and the algorithm will do all calculations for you on how best it should
  // fit the logos on the screen. It also works if you need to remove a brand.
  const partnershipsList = [
    {
      path: "/images/partnerships/focusEducationLogo.png",
      description: "Focus Education Logo",
    },
    {
      path: "/images/partnerships/toyotaLogo.png",
      description: "Toyota Logo",
    },
    {
      path: "/images/partnerships/senaiLogo.png",
      description: "Senai Logo",
    },
    {
      path: "/images/partnerships/jicaLogo.png",
      description: "JICA Logo",
    },
    {
      path: "/images/partnerships/retfopLogo.png",
      description: "RETFOP Logo",
    },
    {
      path: "/images/partnerships/pnudLogo.png",
      description: "PNUD Logo",
    },
    {
      path: "/images/partnerships/newCognitoLogo.png",
      description: "NewCognito Logo",
    },
  ];

  const grids = getGrids(screenSize, partnershipsList, theme);

  return (
    <>
      <Container
        maxWidth={false}
        className={
          classes.homepageSectionContainer +
          " " +
          classes.homepageSectionContainerGrey
        }
      >
        <Typography
          component="h5"
          variant="h5"
          className={classes.homepageSectionMainHeader}
        >
          {intl.formatMessage(titles.Title_HomePage_Parthnerships)}
        </Typography>
        <Carousel
          navButtonsAlwaysInvisible={true}
          className={classes.indicatorIconButton}
          IndicatorIcon={
            <FiberManualRecordIcon className={classes.indicatorIcon} />
          }
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: theme.palette.gray[200],
            },
          }}
        >
          {grids}
        </Carousel>
      </Container>
    </>
  );
}

const getGrids = (screenSize, partnershipsList, theme) => {
  // Max number for numOfElemsPerGrid should be 4 because 4 and
  // every positive integer below it can divide 12, which is the
  // max width of material UI's grid system.

  // Computing numOfElemsPerGrid based on screen width.
  // It should be noted that the values used in the comparison
  // correspond to the values for xs, sm, md and lg screen sizes
  let numOfElemsPerGrid = 0;
  if (screenSize >= theme.breakpoints.values.lg) numOfElemsPerGrid = 4;
  else if (screenSize >= theme.breakpoints.values.md) numOfElemsPerGrid = 3;
  else if (screenSize >= theme.breakpoints.values.sm) numOfElemsPerGrid = 2;
  else numOfElemsPerGrid = 1;

  let numOfGrids =
    partnershipsList.length === 0
      ? 0
      : Math.floor((partnershipsList.length - 1) / numOfElemsPerGrid) + 1;

  const elemsPerGrid = [];
  for (let i = 0; i < numOfGrids; i++) {
    elemsPerGrid.push([]);
    for (let j = 0; j < numOfElemsPerGrid; j++) {
      let idx = i * numOfElemsPerGrid + j;
      elemsPerGrid[i].push(
        <Grid item xs={12 / numOfElemsPerGrid} key={idx}>
          <PartnershipsCard
            item={partnershipsList[idx]}
            style={{ width: "233px", height: "93px", margin: "auto" }}
          />
        </Grid>
      );
      if (idx === partnershipsList.length - 1) break;
    }
  }

  const grids = [];
  for (let i = 0; i < numOfGrids; i++) {
    grids.push(
      <Grid container key={i}>
        {elemsPerGrid[i]}
      </Grid>
    );
  }
  return grids;
};

export default Partnerships;
