export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

export const ArrayUtils = {
    NullOrEmpty: function (array) {
      return array === undefined || array.length === 0;
    },
  };
  


