import React from "react";
//Redux
import { connect } from "react-redux";
//lodash
import _ from "lodash";

// core components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import InfoContent from "views/auth/Dialogs/InfoContent";
import LandingPage from "components/LandingPage/LandingPage";

import { resetResetPasswordStateAction } from "redux/actions/auth/resetPasswordAction";
import { loginAction } from "redux/actions/auth/loginAction";
import resetSessionExpiredAction from "redux/actions/auth/resetSessionExpiredAction";

function AuthHomepage(props) {
  const DIALOG = { SHOW: true, HIDE: false };
  const [dialogs, setDialogs] = React.useState({
    loginState: true,
    sessionExpiredModal: false,
  });
  const showHideDialog = (dialog, op) => {
    let dialogs_temp = _.mapValues(dialogs, () => false);

    dialogs_temp[dialog] = op;

    if (op === DIALOG.HIDE) dialogs_temp["loginState"] = DIALOG.SHOW;

    setDialogs(dialogs_temp);
  };

  React.useEffect(() => {
    if (props.authState.sent) {
      props.resetResetPasswordState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authState.sent]);

  React.useEffect(() => {
    if (props.authState.session_expired) {
      showHideDialog("sessionExpiredModal", DIALOG.SHOW);
    }
    props.resetResetPasswordState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authState.session_expired]);

  return (
    <>
      <LandingPage />

      <GenericDialog
        name="session-expired-dialog"
        open={dialogs.sessionExpiredModal}
        onClose={() => {
          props.resetSessionExpiredState();
          showHideDialog("sessionExpiredModal", DIALOG.HIDE);
        }}
      >
        <InfoContent
          message={"Sessão Expirada. Por favor entre novamente."}
          onClick={() => {
            props.resetSessionExpiredState();
            showHideDialog("sessionExpiredModal", DIALOG.HIDE);
          }}
        />
      </GenericDialog> 
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  loginAction: (email, password) => dispatch(loginAction(email, password)),
  resetSessionExpiredState: () => dispatch(resetSessionExpiredAction()),
  resetResetPasswordState: () => dispatch(resetResetPasswordStateAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthHomepage);
