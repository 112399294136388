//#region Imports
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { FetchDatasetsOnMount } from "../../../../utils/datasets";
import { dataSetsNeededApplications } from "constants/componentsDatasets";
import { getDatasetStateByName } from "../../../../utils/datasets";

import { getDataSetByNameAction, getUserApplicationsAwaitingTrainingAction } from "redux/actions/datasetsAction";
import {
  updateApplicationDetailGeneralDataAction,
  getSubprogramInformationAction,
  getApplicationDetailsGeneralDataAction,
} from "redux/actions/applicationsAction";
import { updateGeneralDataMap } from "mappers/ApplicationMap";
import { titles } from "resources/resources";

//consts
import { APPLICATIONS_ACTIONS } from "utils/actionsConsts";
import { DATASET, FORM_FIELDS_PREFIX } from "utils/const";
// @material-ui/core components
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

// core components
import componentStyles from "assets/theme/views/admin/applications.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
//custom components
import GenericButton from "components/Buttons/GenericButton";
//Steps
import Programs from "./Sections/Programs";
import ComplementaryData from "./Sections/ComplementaryData";
import Confirmation from "./Sections/Confirmation";
//#endregion
import { getSelectOptionsElementByCode } from "utils/formSelect";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function MainFormEdit(props) {
  FetchDatasetsOnMount(props.getDataSetByName, props.datasetsState, dataSetsNeededApplications);
  const classes = {
    ...useStylesGeneric(),
    ...useStyles()
  };
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isEditablePrograms, setEditablePrograms] = useState(false);
  const [isEditableComplementaryData, setEditableComplementaryData] = useState(false);
  const [filter] = useState({
    userId: "",
    subprogramId: undefined,
    ApplicationState: undefined,
    ApplicationStateGroup: [],
    pageIndex: 1,
    pageSize: 15,
  });
  const [applicationNumber, setApplicationNumber] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const intl = useIntl();
  const [pageState, setPageState] = useState({
    generatedWorkPostsName: "generatedWorkPosts",
    parentApplciationFieldName: "parentApplicationId",
    provinceFieldName: "province",
    programsFieldName: "programId",
    subprogramsFieldName: "subProgramId",
    microcreditBenefitTypeName: "microCreditBenefit",
    bankPreferenceFieldName: "bankPreference",
    professionalKitBenefitTypeName: "professionalKitBenefit",
    docFieldName: "applicationDocs",
    shapeProgramsFieldName: "shapeProgram",
    shapeSubsiteFieldName: "shapeSubsite",
    internshipFieldName: "internshipArea",
    microcreditValue: props.applicationsState?.applicationDetailsGeneralData?.microcreditAmount,
    uploadDocNames: [],
    info: undefined,
    complementaryData: [],
    docFields: [],
  });
  const [isLoadedProgramsPage, setIsLoadedProgramsPage] = useState(false);

  useEffect(() => {
    props.getUserApplicationsAwaitingTraining(DATASET.USER_APPLICATIONS_AWAITING_TRAINING);

    props.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.getApplicationDetailsGeneralData(props.match.params.applicationNumber);
    setApplicationNumber(props.match.params.applicationNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.applicationNumber]);

  //#region setup Datasets
  useEffect(() => {
    if (
      props.datasetsState[DATASET.USER_APPLICATIONS_AWAITING_TRAINING] === undefined ||
      props.datasetsState[DATASET.USER_APPLICATIONS_AWAITING_TRAINING] === []
    ) {
      props.getDataSetByName(DATASET.USER_APPLICATIONS_AWAITING_TRAINING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.USER_APPLICATIONS_AWAITING_TRAINING]]);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY] === undefined ||
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY] === []
    ) {
      props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]]);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY] === undefined ||
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY] === []
    ) {
      props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]]);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.SHAPE_PROGRAMS] === undefined ||
      props.datasetsState[DATASET.SHAPE_PROGRAMS] === []
    ) {
      props.getDataSetByName(DATASET.SHAPE_PROGRAMS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.SHAPE_PROGRAMS]]);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.SHAPE_SUBSITES] === undefined ||
      props.datasetsState[DATASET.SHAPE_SUBSITES] === []
    ) {
      props.getDataSetByName(DATASET.SHAPE_SUBSITES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.SHAPE_SUBSITES]]);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY] === undefined ||
      props.datasetsState[DATASET.PROFESSIONAL_CATEGORY] === []
    ) {
      props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.PROFESSIONAL_CATEGORY]]);

  useEffect(() => {
    if (
      props.datasetsState[DATASET.USER_APPLICATIONS_AWAITING_TRAINING] === undefined ||
      props.datasetsState[DATASET.USER_APPLICATIONS_AWAITING_TRAINING] === []
    ) {
      props.getDataSetByName(DATASET.USER_APPLICATIONS_AWAITING_TRAINING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.USER_APPLICATIONS_AWAITING_TRAINING]]);
  //#endregion

  useEffect(() => {
    if (
      props.applicationsState?.applicationDetailsGeneralData !== undefined &&
      props.applicationsState.applicationDetailsGeneralData !== null
    ) {
      var subprogramsList = getDatasetStateByName(props, DATASET.SUBPROGRAMS);

      var subprogramId =
        props.applicationsState?.applicationDetailsGeneralData !== undefined
          ? props.applicationsState?.applicationDetailsGeneralData.subprogramId
          : null;

      setValue(
        pageState.subprogramsFieldName,
        getSelectOptionsElementByCode(subprogramsList, Number.parseInt(subprogramId))
      );
      if (subprogramId != null) {
        props.getSubprogramInformation(subprogramId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState?.[DATASET.SUBPROGRAMS]]);

  useEffect(() => {
    if (props.applicationsState.subprogramsInfo?.info !== undefined) {
      setSubprogramInfo(props.applicationsState.subprogramsInfo?.info);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applicationsState.subprogramsInfo?.info]);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    clearErrors,
    unregister,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const showHideFieldsOnChange = (childField, toShow) => {
    setPageState((prevState) => ({
      ...prevState,
      complementaryData: prevState.complementaryData.map((c) => (c.id === childField ? { ...c, show: toShow } : c)),
    }));
  };

  const setComplementaryData = (_complementaryData) => {
    setPageState({
      ...pageState,
      complementaryData: _complementaryData.map((c) => {
        return {
          ...c,
          id: FORM_FIELDS_PREFIX.COMPLEMENTARY_DATA + c.complementaryDataId,
          show: !c.hasDependencies || (c.hasDependencies && c.answer),
        };
      }),
    });
  };

  const setMicrocreditValueInfo = (value) => {
    setPageState({ ...pageState, microcreditValue: value });
  };

  const clearComplementaryData = () => {
    setPageState((prevState) => {
      prevState.complementaryData.length = 0;
      return prevState;
    });
  };

  const clearSubProgramInfo = () => {
    setPageState({ ...pageState, info: undefined });
  };

  const setSubprogramInfo = (_info) => {
    setPageState({
      ...pageState,
      info: _info,
      docFields: _info?.subProgramDocument?.map((d) => {
        return {
          id: FORM_FIELDS_PREFIX.DOCUMENT + d.id.toString(),
          name: d.name,
        };
      }),
    });
  };

  const errorsIsEmpty = () => {
    return Object.keys(errors).length === 0;
  };

  const validateActivePageData = (activePage) => {
    if (!errorsIsEmpty()) {
      switch (activePage) {
        case 0:
          return (
            !errors[pageState.generatedWorkPostsName] &&
            !errors[pageState.parentApplciationFieldName] &&
            !errors[pageState.provinceFieldName] &&
            !errors[pageState.programsFieldName] &&
            !errors[pageState.subprogramsFieldName] &&
            !errors[pageState.microcreditBenefitTypeName] &&
            !errors[pageState.bankPreferenceFieldName] &&
            !errors[pageState.professionalKitBenefitTypeName] &&
            !errors[pageState.docFieldName] &&
            !errors[pageState.shapeProgramsFieldName] &&
            !errors[pageState.shapeSubsiteFieldName] &&
            !errors[pageState.internshipFieldName]
          );
        case 1:
          if (!errors[pageState.complementaryData] !== null) {
            return false;
          }
          break;
        default:
          return true;
      }
    } else {
      return true;
    }
  };

  const onSubmit = (data) => {
    if (activeStep === steps.length - 1) {
      if (isSubmit) {
        data["applicationNumber"] = props.match.params.applicationNumber;
        data["applicationStateId"] = 1;
        data["concededMicrocreditAmount"] =
          props.applicationsState.applicationDetailsGeneralData.concededMicrocreditAmount;
        data["concededProfessionalKitsNumber"] =
          props.applicationsState.applicationDetailsGeneralData.concededProfessionalKitsNumber;
        data["trainingCenterId"] = props.applicationsState.applicationDetailsGeneralData.trainingCenterId;
        const formData = updateGeneralDataMap(data, pageState);
        props.updateApplicationDetails(formData, filter);
      } else {
        setIsSubmit(true);
      }
    } else {
      setIsSubmit(false);
    }
  };

  function getSteps() {
    return [
      intl.formatMessage(titles.Title_Admin_Applications_MainForm_ProgramSubprograms),
      intl.formatMessage(titles.Title_Admin_Applications_MainForm_ComplementaryData),
      intl.formatMessage(titles.Title_Admin_Applications_MainForm_Confirmation),
    ];
  }

  const handleNext = () => {
    const pageIsValid = validateActivePageData(activeStep);
    if (activeStep !== steps.length - 1 && pageIsValid) {
      setTimeout(() => {
        clearErrors();
      }, 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handlePrevious = () => {
    trigger();
    setIsSubmit(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = getSteps();

  function getStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Programs
            applicationData={props.applicationsState.applicationDetailsGeneralData}
            applicationNumber={applicationNumber}
            pageState={pageState}
            isEditPage={true}
            isEditable={isEditablePrograms}
            setEditable={setEditablePrograms}
            isLoaded={isLoadedProgramsPage}
            setIsLoaded={setIsLoadedProgramsPage}
            control={control}
            setValue={setValue}
            register={register}
            getValues={getValues}
            errors={errors}
            classes={classes}
            theme={theme}
            trigger={trigger}
            unregister={unregister}
            clearComplementaryData={clearComplementaryData}
            setSubprogramInfo={setSubprogramInfo}
            clearSubProgramInfo={clearSubProgramInfo}
            setMicrocreditValueInfo={setMicrocreditValueInfo}
          />
        );
      case 1:
        return (
          <ComplementaryData
            isEditPage={true}
            isEditable={isEditableComplementaryData}
            setEditable={setEditableComplementaryData}
            control={control}
            getValues={getValues}
            errors={errors}
            classes={classes}
            setValue={setValue}
            theme={theme}
            pageState={pageState}
            showHideFieldsOnChange={showHideFieldsOnChange}
            complementaryDataAnswers={props.applicationsState.applicationDetailsGeneralData.complementaryData}
            unregister={unregister}
            setComplementaryData={setComplementaryData}
          />
        );
      case 2:
        return (
          <Confirmation
            isEditPage={true}
            isEditable={false}
            applicationData={props.applicationsState.applicationDetailsGeneralData}
            control={control}
            getValues={getValues}
            setValue={setValue}
            classes={classes}
            theme={theme}
            errors={errors}
            pageState={pageState}
          />
        );
      default:
        return (
          <Programs
            applicationData={props.applicationsState.applicationDetailsGeneralData}
            applicationNumber={applicationNumber}
            pageState={pageState}
            isEditPage={true}
            isEditable={isEditablePrograms}
            setEditable={setEditablePrograms}
            isLoaded={isLoadedProgramsPage}
            setIsLoaded={setIsLoadedProgramsPage}
            control={control}
            setValue={setValue}
            register={register}
            getValues={getValues}
            errors={errors}
            classes={classes}
            theme={theme}
            trigger={trigger}
            unregister={unregister}
            clearComplementaryData={clearComplementaryData}
            setSubprogramInfo={setSubprogramInfo}
            clearSubProgramInfo={clearSubProgramInfo}
            setMicrocreditValueInfo={setMicrocreditValueInfo}
          />
        );
    }
  }

  return (
    <>
      <Container component={Box} className={classes.containerRoot} maxWidth="xl" paddingBottom="3rem" position="relative" zIndex="101">
        <Box className={classes.applicationHeader} component={Grid} container justifyContent="left">
          {intl.formatMessage(titles.Title_Admin_Applications_MainForm)}{" "}{props.applicationsState?.applicationDetailsGeneralData?.governmentProgramName}
        </Box>

        <Stepper className={classes.stepperRoot} activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit(onSubmit)}>
          {getStepsContent(activeStep)}
          <Grid container>
            <Grid item xs={12} sm={4}>
              {activeStep > 0 && (
                <GenericButton 
                  typeSubmit={false}
                  color="secondary"
                  onClick={handlePrevious} 
                >
                  Voltar Atrás
                </GenericButton>
              )}
            </Grid>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4} style={{ display: "flex", justifyContent: "flex-end" }}>
              <GenericButton 
                typeSubmit={true}
                color="primary"
                loading={props.applicationsState.loading} 
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Actualizar" : "Seguinte"}
              </GenericButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name) => dispatch(getDataSetByNameAction(name)),
  getUserApplicationsAwaitingTraining: (name) => dispatch(getUserApplicationsAwaitingTrainingAction(name)),
  updateApplicationDetails: (data, filter) => dispatch(updateApplicationDetailGeneralDataAction(data, filter)),
  resetForm: () => dispatch({ type: APPLICATIONS_ACTIONS.RESET_FORM }),
  getApplicationDetailsGeneralData: (applicationNum) =>
    dispatch(getApplicationDetailsGeneralDataAction(applicationNum)),
  getSubprogramInformation: (subprogramId) => dispatch(getSubprogramInformationAction(subprogramId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainFormEdit);
