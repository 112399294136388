import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import { useIntl } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import LinkMaterialUi from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";

// @material-ui/icons components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStyles from "assets/theme/views/admin/generic-card.js";
import componentStylesSearchBar from "assets/theme/components/search-bar";
import { labels } from "resources/resources";
import { getInformationTypeChipColor } from "utils/getChipColors";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStyles = makeStyles(componentStyles);
const useStylesSearchBar = makeStyles(componentStylesSearchBar);

function InformationSearchCard(props) {
  const classes = { ...useStylesGeneric(), ...useStyles(), ...useStylesSearchBar() }; 
  const intl = useIntl();

  return (
    <>
      <Card className={classes.cardRoot}>
        <img
          src={props.photo !== null ? props.photo : "/images/group-afro-americans-working-together.png"} 
          className={classes.cardImgTop} 
          alt={""}  
          style={{objectFit: "cover"}} 
          data-testid={"search-results-image-" + props.itemKey} 
        />

        <List disablePadding>
          <ListItem className={classes.listItemRoot}>
            <Chip
                data-testid={"search-results-type-chip-" + props.itemKey} 
                classes={{
                    root: classes.informationTypeChipRoot,
                }}
                style={{ 
                  backgroundColor: getInformationTypeChipColor(props.informationDisseminationTypeKeyValue), 
                  borderColor: getInformationTypeChipColor(props.informationDisseminationTypeKeyValue),
                }}
                label={props?.informationDisseminationTypeDescription}
                variant="outlined"
            />

          </ListItem>
        </List>

        <CardContent>
          <Tooltip
            title={props.title}
            placement="bottom"
            arrow
            interactive
            enterDelay={300}
            enterNextDelay={300}
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <Typography variant="h3" className={classes.doubleLineTitleEllipsis}>
              {props.title}
            </Typography>
          </Tooltip>

          <ReactQuill
            readOnly={true}
            className={classes.descriptionSize + " " + classes.quillOverflowHidden}
            theme="bubble"
            value={props.description}
          />

          <Grid container>
            <Grid item xs={12} sm={7} md={7}>
              {props.publishedOn}
            </Grid>
            <Grid item xs={12} sm={5} md={5} className={classes.alignEnd}>
              <LinkMaterialUi
                component={Link}
                variant="h5"
                to={props.searchResultPath}
                className={classes.containedGhostButton}
                data-testid={"search-results-see-more-button-" + props.itemKey} 
              >
                {intl.formatMessage(labels.Label_See_More)}
              </LinkMaterialUi>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(InformationSearchCard);
