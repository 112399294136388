import { React } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons components
import HelpIcon from "@material-ui/icons/Help";
//import custom components

import componentStyles from "assets/theme/views/admin/static-page.js";
import { labels } from "resources/resources";
import LocationHeader from "components/Headers/LocationHeader";

const useStyles = makeStyles(componentStyles);

function FAQs() {
  const classes = useStyles();
  const intl = useIntl();

  const quickGuides = [
    {
      title: intl.formatMessage(labels.Label_Menu_Help_FAQs_HowToRegisterInInefop),
      path: "Register_FO_user.pdf",
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Help_FAQs_HowToRecoverPassword),
      path: "Password_Recovery.pdf",
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Help_FAQs_HowToUpdatePersonalData),
      path: "Update_Personal_Information.pdf",
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Help_FAQs_HowToApplyToAJob),
      path: "Employment_And_Internship_Application.pdf",
    },
    {
      title: intl.formatMessage(labels.Label_Menu_Help_FAQs_HowToApplyToGovernmentSupports),
      path: "Government_Supports_Application.pdf",
    },
  ];

  return (
    <Container maxWidth={false} classes={{ root: classes.containerRoot }}>
      <Box marginLeft="-30px">
        <LocationHeader section={intl.formatMessage(labels.Label_Menu_Help_FAQs)} />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Card className={classes.cardRoot}>
            <CardContent>
              <Grid item xs={12} md={12}>
                <Box classes={{ root: classes.pageTitle }}>{intl.formatMessage(labels.Label_Menu_Help_FAQs)}</Box>
              </Grid>

              <Grid item xs={12} md={12}>
                <List dense={true}>
                  {quickGuides.map((item, key) => (
                    <ListItem key={key}>
                      <Grid container>
                        <HelpIcon className={classes.helpIcon}> </HelpIcon>
                        <Grid item xs={11} md={11} style={{ alignSelf: "center" }}>
                          <Link to={"/quick-guides/" + item.path} target="_blank" className={classes.linkStandard}>
                            {item.title}
                          </Link>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(FAQs);
