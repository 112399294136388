import {
  createUserUnemploymentHistory,
  updateUserUnemploymentHistory,
  updateUserEmploymentHistory,
  createUserEmploymentHistory,
} from "crud/auth.crud";
import { DIALOG } from "utils/actionsConsts";

export const createUserUnemploymentHistoryAction = (formData, onCloseAction) => async (dispatch) => {
  return createUserUnemploymentHistory(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data.succeeded ? onCloseAction : undefined,
            okAction: res.data.succeeded ? onCloseAction : undefined,
            isSuccess: res.data.succeeded,
            message: res.data.succeeded
              ? "Registo de desemprego criado com sucesso"
              : "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const updateUserUnemploymentHistoryAction = (formData, onCloseAction) => async (dispatch) => {
  return updateUserUnemploymentHistory(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data.succeeded ? onCloseAction : undefined,
            okAction: res.data.succeeded ? onCloseAction : undefined,
            isSuccess: res.data.succeeded,
            message: res.data.succeeded
              ? "Registo de desemprego actualizado com sucesso"
              : "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const updateUserEmploymentHistoryAction = (data, onCloseAction) => async (dispatch) => {
  return updateUserEmploymentHistory(data)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? onCloseAction : undefined,
            okAction: res.data ? onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data
              ? "Registo de emprego actualizado com sucesso"
              : "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const createUserEmploymentHistoryAction = (data, onCloseAction) => async (dispatch) => {
  return createUserEmploymentHistory(data)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? onCloseAction : undefined,
            okAction: res.data ? onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data
              ? "Registo de emprego criado com sucesso"
              : "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};
