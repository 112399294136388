export function validateIfArrayHasCommonObjects(arrayA, arrayB) {
  for (const objA of arrayA) {
    for (const objB of arrayB) {
      if (JSON.stringify(objA) === JSON.stringify(objB)) {
        return true;
      }
    }
  }
  return false;
}
