export const direcaoGeralInfo = {
  title: "Direcção",
  holders: [
    {
      holderName: "Manuel Mbangui",
      holderPosition: "Director Geral",
      holderPhoto: "/images/institute-holders/rounded-photos/Manuel_Mbangui_Rounded.png",
      holderLink: "/manuel-mbangui",
    },
    {
      holderName: "Agostinho Pereira",
      holderPosition: "Director Geral Adjunto Para o Emprego",
      holderPhoto: "/images/institute-holders/rounded-photos/Agostinho_Pereira_Rounded.png",
      holderLink: "/agostinho-pereira",
    },
    {
      holderName: "Eduarda Cassange",
      holderPosition: "Director Geral Adjunto Para a Formação",
      holderPhoto: "/images/institute-holders/rounded-photos/Eduarda_Cassange_Rounded.png",
      holderLink: "/eduarda-cassange",
    },
  ],
};

export const servicosExecutivosInfo = {
  title: "Serviços Executivos",
  holders: [
    {
      holderName: "Belchior de Almeida",
      holderPosition: "Departamento de Formação e Certificação Profissional",
      holderPhoto: "/images/institute-holders/rounded-photos/Belchior_de_Almeida_Rounded.png",
      holderLink: "/belchior-de-almeida",
    },
    {
      holderName: "Felisbino Narciso",
      holderPosition: "Departamento Fomento ao Empreendedorismo e Inovação",
      holderPhoto: "/images/institute-holders/rounded-photos/Felisbino_Narciso_Rounded.png",
      holderLink: "/felisbino-narciso",
    },
    {
      holderName: "Fragoso Carlos",
      holderPosition: "Departamento de Gestão e Serviços de Emprego",
      holderPhoto: "/images/institute-holders/rounded-photos/Fragoso_Carlos_Rounded.png",
      holderLink: "/fragoso-carlos",
    },
    {
      holderName: "Tito de Jesus",
      holderPosition: "Departamento de Supervisão e Qualidade da Formação Profissional",
      holderPhoto: "/images/institute-holders/rounded-photos/Tito_de_Jesus_Rounded.png",
      holderLink: "/tito-de-jesus",
    },
  ],
};

export const servicosDeApoioInfo = {
  title: "Serviços de Apoio",
  holders: [
    {
      holderName: "Áurea Janeth Gaspar Marreta",
      holderPosition: "Departamento de Apoio e Serviços Gerais",
      holderPhoto: "/images/institute-holders/rounded-photos/Aurea_Janeth_Gaspar_Rounded.png",
      holderLink: "/aurea-janeth-gaspar-marreta",
    },
    {
      holderName: "Henriqueta de Assis",
      holderPosition: "Departamento de Apoio ao Director Geral",
      holderPhoto: "/images/institute-holders/rounded-photos/Dra_Henriqueta_de_Assis_Rounded.png",
      holderLink: "/henriqueta-de-assis",
    },
    {
      holderName: "Américo Souto",
      holderPosition: "Departamento de Comunicação, Inovação Tecnológica e Modernização dos Serviços",
      holderPhoto: "/images/institute-holders/rounded-photos/Americo_Souto_Rounded.png",
      holderLink: "/americo-souto",
    },
  ],
};
