import React, { useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import ResultSection from "./Sections/StructuringProjects_ResultSection";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import { useIntl } from "react-intl";
import { titles, labels } from "resources/resources";
import LocationHeader from "components/Headers/LocationHeader";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function StructuringProjects() {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Container
      maxWidth={false}
      component={Box}
      classes={{ root: classes.containerRoot }}
    >
      <LocationHeader
        section={intl.formatMessage(titles.Title_StructuringProjects)}
      />
      <PageTitleHeader
        titleId="structuring-projects"
        title={intl.formatMessage(titles.Title_StructuringProjects)}
      />
      <Box component={Grid} container justifyContent="center">
        <Grid item xs={12} md={12}>
          <Box className={classes.pageLabels}>
            {intl.formatMessage(
              labels.Label_HomePage_StructuringProjects_Description
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <ResultSection page={page} handleChangePage={handleChangePage} />
        </Grid>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(StructuringProjects);
