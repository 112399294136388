import { React, useEffect } from "react";
//redux
import { connect } from "react-redux";
//redux-actions
import { DATASET } from "utils/const";

import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import FormLabel from "@material-ui/core/FormLabel";
import FormSelect from "components/FormFields/FormSelect";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Controller, useFormContext } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function RejectCandidateSection(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const { control } = useFormContext();

  useEffect(() => {
    if (
      props.datasetsState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES] === undefined ||
      props.datasetsState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Box className={classes.modalHeader} marginBottom="30px!important">
          Pretende rejeitar o candidato?
        </Box>

        <Box marginTop="30px">
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>
                  Motivo
                </FormLabel>
                <FormSelect
                  control={control}
                  fieldName="motive"
                  selectOptions={
                    props.datasetsState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES] !==
                    undefined
                      ? props.datasetsState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES]
                      : []
                  }
                  setValue={props.setValue}
                  getValues={props.getValues}
                  required={true}
                  requiredMessage={"O campo é obrigatório"}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["motive"] !== undefined && (
                  <FormHelperText
                    component={Box}
                    color={theme.palette.warning.main + "!important"}
                  >
                    {props.errors["motive"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>
                  Comentários
                </FormLabel>
                <Controller
                  name="notes"
                  control={control}
                  component={Box}
                  defaultValue=""
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      placeholder={"Escreva aqui os seus comentários"}
                      multiline={true}
                      rows={3}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectCandidateSection);
