import { React, useEffect } from "react";
//redux
import { connect } from "react-redux";
//redux-actions
import { DATASET } from "utils/const";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";

//@material-ui/core
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
//@material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import UpdateStateSection from "../Sections/UpdateOpportunityStateSection";
import { updateOpportunityStateMap } from "mappers/OpportunityMap";
import {
  updateOpportunityStateAction,
  getOpportunitiesDetailsAction,
} from "redux/actions/opportunitiesAction";
//styles
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function UpdateOpportunityStateDialog(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = methods;

  //GET MOTIVES
  useEffect(() => {
    if (
      props.datasetsState[DATASET.OPPORTUNITY_STATE_MOTIVES] === undefined ||
      props.datasetsState[DATASET.OPPORTUNITY_STATE_MOTIVES] === []
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_STATE_MOTIVES);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasetsState[DATASET.OPPORTUNITY_STATE_MOTIVES]]);

  useEffect(() => {
    props.getDataSetByName(
      DATASET.OPPORTUNITY_STATE_MOTIVES,
      props.data?.motivesType
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const onSubmitReject = (data) => {
    props.data["opportunityStateMotiveKey"] = data.motive?.keyValue;
    props.data["observations"] = data.notes;

    props.updateOpportunityState(updateOpportunityStateMap(props.data), () => {
      setValue("motive", undefined);
      setValue("notes", "");
      props.onClose();
      props.getOpportunityDetails(props.data.id);
    });
  };
  
  return (
    <GenericDialog name={props.name} open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitReject)}>
          <Box className={classes.helpIconBox}>
            <HelpOutlineIcon className={classes.helpIconRoot} />
          </Box>
          <UpdateStateSection
            getValues={getValues}
            isEditable={true}
            errors={errors}
            classes={classes}
            setValue={setValue}
            theme={theme}
            trigger={trigger}
            actionType={props.data?.actionType}
          />
          <Box textAlign="center" marginBottom="50px" marginTop="50px">
            <GenericButton
              typeSubmit={true}
              color="primary"
              loading={
                props.opportunitiesState?.update_opportunity_state_loading
              }
            >
              Submeter
            </GenericButton>
          </Box>
        </form>
      </FormProvider>
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) =>
    dispatch(getDataSetByNameAction(name, parentKeyValue)),

  updateOpportunityState: (data, onCloseMethod) =>
    dispatch(updateOpportunityStateAction(data, onCloseMethod)),

  getOpportunityDetails: (opportunityId) =>
    dispatch(getOpportunitiesDetailsAction(opportunityId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateOpportunityStateDialog);
