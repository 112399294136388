import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
//import custom components
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getOpportunitiesDetailsAction } from "redux/actions/opportunitiesAction";
import { OPPORTUNITY_STATE_KEY } from "utils/const";

//Header
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import DetailDataTab from "./Tabs/OpportunityDetailsTab";
import CandidatesTab from "../OpportunityCandidates/Tabs/OpportunityCandidatesTab";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityDetails(props) {
  const classes = useStylesGeneric();
  const [tabStep, setTabStep] = useState("detailDataTab");

  useEffect(() => {
    if (
      props.opportunitiesState.opportunityDetailsData === undefined ||
      props.opportunitiesState.opportunityDetailsData.opportunityId !== props.match.params.opportunityId
    ) {
      props.getOpportunityDetails(props.match.params.opportunityId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.opportunityId]);

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);
    }
  };

  function loadTabContent() {
    switch (tabStep) {
      case "detailDataTab":
        return (
          <DetailDataTab
            opportunityId={props.match.params.opportunityId}
            opportunityDetailsData={props.opportunitiesState.opportunityDetailsData}
          />
        );
      case "candidatesTab":
        return (
          <CandidatesTab
            opportunityDetailsData={props.opportunitiesState.opportunityDetailsData}
            opportunityId={props.match.params.opportunityId}
          />
        );
      default:
        return (
          <DetailDataTab
            opportunityId={props.match.params.opportunityId}
            opportunityDetailsData={props.opportunitiesState.opportunityDetailsData}
          />
        );
    }
  }

  return (
    <>
      <GenericBackdrop open={props.opportunitiesState.get_opportunity_details_loading} />
      
      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
        <LocationHeader
          subsection={"Emprego e Estágio Profissional"}
          section={"Detalhes de Oferta"}
          subsectionLink={"/admin/opportunities"}
        />

        <PageTitleHeader title={props.opportunitiesState.opportunityDetailsData?.title} />

        <Box component={Grid} container justifyContent="center">
          <ToggleButtonGroup
            value={tabStep}
            exclusive
            onChange={handleTabStep}
            aria-label="tab Step"
            className={classes.changeTabsGroup}
            style={{ marginBottom: "25px" }}
          >
            <ToggleButton value="detailDataTab" className={classes.changeTabs}>
              Dados Gerais
            </ToggleButton>

            {(props.opportunitiesState.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.APROVADA ||
              (props.opportunitiesState.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.FECHADA &&
                props.opportunitiesState.opportunityDetailsData?.opportunityCandidates.length > 0)) && (
              <ToggleButton value="candidatesTab" className={classes.changeTabs}>
                Candidatos
              </ToggleButton>
            )}
          </ToggleButtonGroup>

          <Grid item xs={12} lg={12} md={12}>
            {loadTabContent()}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getOpportunityDetails: (opportunityId) => dispatch(getOpportunitiesDetailsAction(opportunityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetails);
