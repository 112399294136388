import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const LOGIN_URL = "account/login";
export const REGISTER_URL = "account/register";
export const REFRESH_TOKEN_URL = "account/refresh-token";
export const LOGOUT_URL = "account/logout";
export const RECOVER_PASSWORD_URL = "User/recover-password";
export const GET_USER_PERMISSIONS = "account/get_user_permissions";
export const GET_GENERATE_CONFIRMATION_TOKEN_URL = "account/generate-confirmation-token";
export const POST_CONFIRM_AUTHENTICATION_TOKEN_URL = "User/confirm-phone";
export const CHANGE_PASSWORD_URL = "User/change-password";
export const RESET_PASSWORD_URL = "User/reset-password";

export const GET_USER_INFO_URL = "User/get-user-info";
export const GET_USER_DOCUMENT_URL = "User/get-user-document-by-id";
export const GET_FOUS_EMPLOYMENT_HISTORY_URL = "User/get-fous-employment-history";
export const GET_FOUS_UNEMPLOYMENT_HISTORY_URL = "User/get-fous-unemployment-history";
export const CREATE_FOUS_UNEMPLOYMENT_HISTORY_URL = "User/create-fous-unemployment-history";
export const UPDATE_FOUS_UNEMPLOYMENT_HISTORY_URL = "User/update-fous-unemployment-history";

export const CREATE_EMPLOYMENT_HISTORY = "User/create-fous-employment-history";
export const UPDATE_EMPLOYMENT_HISTORY = "User/update-fous-employment-history";

export const GET_FOUS_INTERVENTION_OTHER_AREA_URL = "User/get-fous-intervention-other-area";

export function getPersonalInformation() {
  return axios.get(EXTERNAL_API_URL + GET_USER_INFO_URL);
}

export function getUserDocument(data) {
  return axios.get(EXTERNAL_API_URL + GET_USER_DOCUMENT_URL, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      docId: data,
    },
    responseType: "blob",
  });
}

export function login(_username, _password) {
  return axios.post(EXTERNAL_API_URL + LOGIN_URL, {
    username: _username,
    password: _password,
  });
}

export function register(user) {
  return axios.post(EXTERNAL_API_URL + REGISTER_URL, user);
}

export function logout(username) {
  return axios.post(EXTERNAL_API_URL + LOGOUT_URL, {
    email: username,
  });
}

export function refresh_token(refreshToken) {
  return axios.post(EXTERNAL_API_URL + REFRESH_TOKEN_URL, {
    refreshToken: refreshToken,
  });
}

export function recover_password(phone) {
  return axios.post(EXTERNAL_API_URL + RECOVER_PASSWORD_URL, {
    username: phone,
  });
}

export function generate_confirmation_token(username, isEmail) {
  return axios.get(EXTERNAL_API_URL + GET_GENERATE_CONFIRMATION_TOKEN_URL, {
    params: {
      userId: username,
      isEmailToken: isEmail,
    },
  });
}
export function confirm_authentication_token(username, _token) {
  return axios.post(EXTERNAL_API_URL + POST_CONFIRM_AUTHENTICATION_TOKEN_URL, {
    phone: username,
    token: _token,
  });
}

export function changePassword(formData) {
  return axios.post(EXTERNAL_API_URL + CHANGE_PASSWORD_URL, formData);
}

export function reset_password(username, password, token) {
  return axios.post(EXTERNAL_API_URL + RESET_PASSWORD_URL, {
    token: token,
    username: username,
    newPassword: password,
  });
}

export function getUserEmploymentHistory(data) {
  return axios.get(EXTERNAL_API_URL + GET_FOUS_EMPLOYMENT_HISTORY_URL, {
    params: data,
  });
}

export function getUserUnemploymentHistory(data) {
  return axios.get(EXTERNAL_API_URL + GET_FOUS_UNEMPLOYMENT_HISTORY_URL, {
    params: data,
  });
}

export function createUserUnemploymentHistory(formData) {
  return axios.post(EXTERNAL_API_URL + CREATE_FOUS_UNEMPLOYMENT_HISTORY_URL, formData);
}

export function updateUserUnemploymentHistory(formData) {
  return axios.post(EXTERNAL_API_URL + UPDATE_FOUS_UNEMPLOYMENT_HISTORY_URL, formData);
}

export function createUserEmploymentHistory(data) {
  return axios.post(EXTERNAL_API_URL + CREATE_EMPLOYMENT_HISTORY, data);
}

export function updateUserEmploymentHistory(data) {
  return axios.put(EXTERNAL_API_URL + UPDATE_EMPLOYMENT_HISTORY, data);
}

export function getFoUSInterventionOtherArea(formData) {
  return axios.get(EXTERNAL_API_URL + GET_FOUS_INTERVENTION_OTHER_AREA_URL, {
    params: formData,
  });
}
